import React      from 'react'
import Style   from './style.sass'

import { USER_TYPES } from './properties.es6'

import SuggestSelect from '../suggest_select'

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class CompanyUsers extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      users: (props.users && props.users.length > 0) ? props.users : [
        {user_id: ''},
      ],
    }
  }

  // 担当者の追加
  addUser = e => {

    let users = (this.state.users || []).slice();

    users.push({
      user_id: '',
    });

    this.setState({ users: users });
  }

  // 担当者の削除
  removeUser = e => {

    let users = (this.state.users || []).slice();

    let user = users[e.target.dataset.index];

    if (window.confirm('総研接点を削除しますか?')) {
      
      user._destroy = true;

      this.setState({ users: users });
    }
  }

  // 主担当変更時
  changeUserType = e => {

    if (e.target.value == 'main_buyer') {

      this.state.users.forEach( r => {
        if (e.target.value == r.user_type) {
          window.alertable({ type: 'error', message: 'すでに【買】主担当が存在しています' });
          e.target.value = 'unset';
          return false;
        }
      });
    }
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        { this.state.users.map((user, index) => {

          // 引継ぎ担当者は表示しない
          if (user.user_type == 'sharer') return null;
          
          this.props.company_users.slice().forEach( cu => {
            if (cu.user_id == user.id) {
              user.company_user_id = cu.id;
              user.user_type = cu.user_type;
            }
          });

          const key = `user_${index}`;
          return (
            <div {...{key}} className={`${Style.CompanyUsers} ${index > 0 ? 'u-mt-15' : ''} c-attention`} style={{display: user._destroy ? 'none' : 'block'}}>
              
              { this.props.editable ?
                <div data-index={index} onClick={e => { this.removeUser(e) }} className={Style.CompanyUsers__close}>
                  削除
                </div>
                : null
              }
              <input type='hidden' value={user.company_user_id || ''} name={`company[company_users_attributes][][id]`} />
              <input type='hidden' value={this.props.company_id} name={`company[company_users_attributes][][company_id]`} />
              <input type='hidden' value={user._destroy || false} name={`company[company_users_attributes][][_destroy]`} />

              <div className='u-display-flex'>
                <div style={{width: '30%'}}>
                  <div className='u-fs-small u-fw-bold'>役割</div>
                  <div className='c-form-selectWrap'>
                    <select className='c-form-select' onChange={this.changeUserType} defaultValue={user.user_type || ''} name={`company[company_users_attributes][][user_type]`} required={this.props.required}>
                      { USER_TYPES.map(ut => {
                        const user_key = `uset_type-${index}-${ut.value}`;
                        return <option key={user_key} value={ut.value}>{ut.name}</option>;
                      })}
                    </select>
                  </div>
                </div>

                <div className='u-ml-15' style={{flex: 1}}>
                  <div className='u-fs-small u-fw-bold'>総研担当者{index + 1}</div>
                  <SuggestSelect options={this.props.all_users} name={`company[company_users_attributes][][user_id]`} readonly={!this.props.editable} selected={user.id || ''} />
                </div>
              </div>
            </div>
          )
        })}

        { this.props.editable ?
          <div className='c-btnMain-standard u-mt-15 c-btnSmall' onClick={this.addUser}>＋ 総研担当者を追加する</div>
          : null
        }
      </div>
    );
  }
}
