/**
 * @version 2018/06/10
 */

export const HEADER_TYPE_UPPER = 'upper';

export const HEADER_TYPE_LEFT = 'left';

// ヘッダの位置
export const HEADER_TYPES = {
  'ヘッダーなし': '',
  'ヘッダー上': HEADER_TYPE_UPPER,
  'ヘッダー左': HEADER_TYPE_LEFT,
};
