//##############################################################################
// Utilities
//##############################################################################

/**
 *  汎用クラス
 *  @version 2018/06/10
 */
export default new class Utilities {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor() {

    // DOMの機能を拡張
    Element.prototype.appendAfter = function (element) {
      element.parentNode.insertBefore(this, element.nextSibling);
    }, false;
    Element.prototype.appendBefore = function (element) {
      element.parentNode.insertBefore(this, element);
    }, false;

    window.copyToClipboard          = this.copyToClipboard;
    window.editCell                 = this.editCell;
    window.dependentCharacters      = this.dependentCharacters;
    window.resizeTextarea           = this.resizeTextarea;
    window.disableRightClick        = this.disableRightClick;
    window.numberWithDelimiter      = this.numberWithDelimiter;
    window.checkDependentCharacters = this.checkDependentCharacters;
    window.autoSave                 = this.autoSave;

    // HTMLCollectionの機能を拡張
    if (typeof HTMLCollection.prototype.forEach === 'undefined') HTMLCollection.prototype.forEach = Array.prototype.forEach;

    // 文字列の機能を拡張
    String.prototype.before = function(word) {
      var idx = this.indexOf(word);
      return idx >= 0 ? this.substring(0, idx) : this;
    }
    String.prototype.after = function(word) {
      var idx = this.indexOf(word);
      return idx >= 0 ? this.substring(idx) : this;
    }
  }

  /**
   *  日付のフォーマット
   *  @version 2018/06/10
   */
  dateFormat = str => {

    const date = new Date(str.replace(/-/g, '/'));
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
  }

  /**
   *  数値のカンマ区切り
   *  @version 2018/06/10
   */
  numberWithDelimiter = num => {

    return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }

  /**
   *  startからendまでのrangeを取得
   *  @version 2018/06/10
   */
  range = (start, end) => {

    return Array.from({length: (end + 1 - start)}, (v, k) => k + start );
  };

  /**
   *  htmlタグを除去
   *  @version 2018/06/10
   */
  trimHtmlTags = text => {

    if (!text || text == '') return '';
    return text.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'');
  };

  /**
   *  文字列をクリップボードにコピーする
   *  @version 2018/06/10
   */
  copyToClipboard = text => {

    navigator.clipboard.writeText(text);
    return true;
  }

  /**
   *  セル(td要素)内の文字列を編集する
   *  @version 2018/06/10
   */
  editCell = element => {
    const _element = element;
    const td = _element.closest('td');

    if (td.dataset.status == 'editting') {
      td.dataset.status = '';
      const _text = td.children[0].value;

      const _httpRequest = new XMLHttpRequest();
      const params = encodeURIComponent(_element.dataset.name) + '=' + encodeURIComponent(_text);
      _httpRequest.open('put', _element.dataset.url);
      _httpRequest.setRequestHeader('X-CSRF-Token', token);
      _httpRequest.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      _httpRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      _httpRequest.send(params);

      td.textContent = _text;
      _element.children[0].children[0].setAttribute('xlink:href', '#application-pen');
      td.appendChild(_element);
      if (_text != '') td.style.backgroundColor = '#90ee90';
    } else {
      td.dataset.status = 'editting';

      const _text = td.textContent
      td.textContent = '';

      // テキストボックスを作成
      var input_data = document.createElement('input');
      input_data.type = 'text';
      input_data.name = 'editting_text';
      input_data.classList.add('c-form-text');
      input_data.classList.add('u-va-middle');
      input_data.value = _text;
      input_data.style.border = 'solid 1px gray';
      input_data.style.width = 'calc(100% - 40px)';

      _element.children[0].children[0].setAttribute('xlink:href', '#application-done');

      td.appendChild(input_data);
      td.appendChild(_element);
    }
  }

  /**
   *  テキストエリアへの機種依存文字の入力禁止
   *  @version 2018/06/10
   */
  checkDependentCharacters = () => {

    let dependent_characters = null;
    document.querySelectorAll('.js-dependent-check').forEach(r => {
      if (dependent_characters) return;
      dependent_characters = this.dependentCharacters(r.value);
    });

    // 機種依存文字が存在する場合
    if (dependent_characters) {
      window.alertable({ type: 'error', message: `機種依存文字が含まれています。<br />${dependent_characters[0]}`});
      return false;
    }
    return true;
  }

  /**
   *  右クリックの禁止
   *  @version 2018/06/10
   */
  disableRightClick = () => {

    document.oncontextmenu = () => {
      event.preventDefault();
      window.alertable({ type: 'warning', message: '右クリックは禁止されています。コピーアンドペーストなどはショートカットキーをご利用ください。例: Ctrl + C' });
    }

    document.addEventListener('keydown', e => {

      if (e.ctrlKey) {
        if (['s', 'u'].includes(e.key)) {
          e.stopPropagation();
          window.alertable({ type: 'warning', message: 'この操作は許可されていません。' });
        }
        if (e.key == 'I') {
          e.stopPropagation();
          window.alertable({ type: 'error', message: 'この操作は禁止されています。この操作は不正です。' });
        }
      }

      if (['F11', 'F12'].includes(e.key)) {
        e.stopPropagation();
        window.alertable({ type: 'warning', message: '無効なキーです。' });
      }
    });
  }

  /**
   *  機種依存文字の存在確認
   *  @version 2018/06/10
   */
  dependentCharacters = text => {

    // 機種依存文字のUnicode範囲を定義
    var characterRanges = [
      "[\u2700-\u27BF]",
      "[\uE000-\uF8FF]",
      "\uD83C[\uDC00-\uDFFF]",
      "\uD83D[\uDC00-\uDFFF]",
      "\uD83E[\uDD10-\uDDFF]",
    ].join("|");

    characterRanges = "[" + characterRanges + "]|[①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑯⑰⑱⑲⑳ⅠⅡⅢⅣⅤⅥⅦⅧⅨⅩ㍉㌔㌢㍍㌘㌧㌃㌶㍑㍗㌍㌦㌣㌫㍊㌻㎜㎝㎞㎎㎏㏄㎡㍻〝〟№㏍℡㊤㊥㊦㊧㊨㈱㈲㈹㍾㍽㍼∮∟⊿ⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹ￢￤]";

    var dependentCharactersOrEmoji = new RegExp(characterRanges);

    // 機種依存文字や絵文字が含まれているかチェック
    //return dependentCharactersOrEmoji.test(text);
    return text.match(dependentCharactersOrEmoji);
  }

  resizeTextarea = () => {

    // textareaタグを全て取得
    const textareaEls = document.querySelectorAll('textarea');

    textareaEls.forEach(function(textareaEl) {

      var _scroll_height = textareaEl.scrollHeight;
      var _parent_height = textareaEl.parentNode.clientHeight;
      textareaEl.style.height = (_scroll_height > _parent_height ? _scroll_height : _parent_height) + 'px';
    });
  }

  /**
   *  フォーム入力内容の自動保存
   *  @version 2024/06/07
   */
  autoSave = (name_prefix, model, action_path, token, value_names) => {

    const _httpRequest = new XMLHttpRequest();
    
    _httpRequest.onreadystatechange = function() {
      if(_httpRequest.readyState === XMLHttpRequest.DONE) {
        if (!window.navigator.onLine) {
          window.alertable({ type: 'error', message: `${name_prefix}: インターネット接続に問題が生じています。` });
        } else if (_httpRequest.status >= 400) {
          window.alertable({ type: 'error', message: `${name_prefix}: 変更に失敗しました。管理者にご確認ください。` });
        }
      }
    };

    value_names.forEach(function(val, index){
  
      document.getElementsByName(`${name_prefix}_` + val.name).forEach(function(dom){
  
        if (dom.type == 'select-one') {
  
          dom.addEventListener('change', function(e) {

            const id = e.target.getAttribute('id').split('_');
  
            const params = encodeURIComponent(`${model}[` + id[0].replaceAll('-', '_') + ']') + '=' + encodeURIComponent(e.target.value);
            _httpRequest.open('put', action_path + id[1]);
            _httpRequest.setRequestHeader('X-CSRF-Token', token);
            _httpRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            _httpRequest.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            _httpRequest.send(params);
          });
  
        } else {
          
          let timer;
          
          dom.addEventListener('input', function(e) {
            
            clearTimeout(timer);
            
            const id = e.target.getAttribute('id').split('_');
  
            if (e.target.type == 'number' && (!e.target.value || e.target.value == '')) e.target.value = 0;
  
            //入力後0.5秒後に値を更新
            timer = setTimeout(function(record_id) {
              
              const params = encodeURIComponent(`${model}[` + id[0].replaceAll('-', '_') + ']') + '=' + encodeURIComponent(e.target.value);
              _httpRequest.open('put', action_path + record_id);
              _httpRequest.setRequestHeader('X-CSRF-Token', token);
              _httpRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
              _httpRequest.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
              _httpRequest.send(params);
            }, 500, id[1]);
          });
        }
      });
    });
  
  }
}
