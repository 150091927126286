import React      from 'react'
import Icon    from '../../icon'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class RuleTree extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor (props) {

    super(props);

    this.state = {
      rule_directories: this.props.rule_directories || [],
      current_rule_directory_id: null,
      parent_rule_directory_id: null,
      current_rule_directory_name: '',
    }
  }

  /**
   * マウント直後
   */
  componentDidMount() {}

  /**
   *  ディレクトリを開く
   *  @version 2018/06/10
   */
  open = e => {

    let rule_directories = JSON.parse(JSON.stringify(this.state.rule_directories));
    let target = this.search(rule_directories, e.target.dataset.id);
    target.open = !target.open;
    this.setState({rule_directories: rule_directories})
  }

  /**
   *  ディレクトリ走査
   *  @version 2018/06/10
   */
  search = (rule_directories, id) => {

    for (let rule_directory of rule_directories) {

      if (rule_directory.id == id) {
        return rule_directory;
      } else {
        if ((rule_directory.rule_directories || []).length > 0) {
          let result = this.search(rule_directory.rule_directories, id);
          if (result) return result;
        }
      }
    }

    return false;
  }

  /**
   *  ディレクトリ走査
   *  @version 2018/06/10
   */
   search_file = (rule_directories, rule_id) => {

    for (let rule_directory of rule_directories) {
      for (let rule of rule_directory.rules) {
        if (rule && rule.id == rule_id) {
          return rule;
        }
      }

      for (let rule_directory2 of rule_directory.rule_directories) {

        for (let rule of rule_directory2.rules) {
          if (rule && rule.id == rule_id) {
            return rule;
          }
        }

        if ((rule_directory2.rule_directories || []).length > 0) {
          let result = this.search_file(rule_directory2.rule_directories, rule_id);
          if (result) return result;
        }
      }
    }

    return false;
  }

  /**
   *  編集
   *  @version 2018/06/10
   */
  edit = e => {

    if (!this.refs.folder_name.value || this.refs.folder_name.value == '') {
      window.alertable({ type: 'warning', message: 'フォルダ名を入力してください' });
      return false;
    }
    
    let field = { 'rule_directory[name]': this.refs.folder_name.value };
    if (this.state.parent_rule_directory_id) field['rule_directory[parent_id]'] = this.state.parent_rule_directory_id;
    if (this.state.current_rule_directory_id) field['id'] = this.state.current_rule_directory_id;

    // 編集
    Request.post('/users/rule_directories')
      .field(field)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {

          let rule_directories = JSON.parse(JSON.stringify(this.state.rule_directories));

          // 名前の更新のみの場合
          if (this.state.current_rule_directory_id) {

            let target = this.search(rule_directories, this.state.current_rule_directory_id);
            target.name = response.body.rule_directory.name;

          } else {

            let target = this.search(rule_directories, this.state.parent_rule_directory_id);
            let rule_directory = response.body.rule_directory;
            rule_directory.open = true;

            if (target) {
              if (!target.rule_directories) {
                target.rule_directories = [];
              }
              target.rule_directories.push(rule_directory);
            } else {
              rule_directories.push(rule_directory);
            }
          }

          this.setState({edit_show: false, rule_directories: rule_directories})

          window.closeLoading();
        } else {

          window.alertable({ type: 'error', message: response.body.message });
          window.closeLoading();
        }
      });
  }

  /**
   *  フォルダー追加
   *  @version 2018/06/10
   */
  addFolder = e => {

    this.setState({
      edit_show: !this.state.edit_show,
      parent_rule_directory_id: e.target.dataset.parent,
      current_rule_directory_id: e.target.dataset.id,
      current_rule_directory_name: e.target.dataset.name,
    });
  }

  /**
   *  削除
   *  @version 2018/06/10
   */
  deleteRule = e => {

    if (!window.confirm('対象の社内規定を削除しますか? ※戻せません!')) {
      return false;
    }

    let id = e.target.dataset.id;

    Request.delete(`/users/rules/${id}`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {

          let rule_directories = JSON.parse(JSON.stringify(this.state.rule_directories));
          let target = this.search_file(rule_directories, id);
          if (target) {
            target.deleted = true;
            this.setState({rule_directories: rule_directories});
          }
          window.closeLoading();
        } else {

          window.alertable({ type: 'error', message: response.body.message });
          window.closeLoading();
        }
      });
  }

  /**
   *  削除
   *  @version 2018/06/10
   */
  deleteFolder = e => {

    if (!window.confirm('対象のフォルダを削除しますか?')) {
      return false;
    }

    let id = e.target.dataset.id;

    Request.delete(`/users/rule_directories/${id}`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {

          let rule_directories = JSON.parse(JSON.stringify(this.state.rule_directories));
          let target = this.search(rule_directories, id);
          target.deleted = true;
          this.setState({rule_directories: rule_directories})

          window.closeLoading();
        } else {

          window.alertable({ type: 'error', message: response.body.message });
          window.closeLoading();
        }
      });
  }

  /**
   *  ディレクトリ
   *  @version 2018/06/10
   */
   content = rule_directory => {

    if (rule_directory.deleted) return null;

    return (
      <li className={Style.RuleTree__item} key={`rule-${rule_directory.id}`}>
        <div className={Style.RuleTree__wrap}>
          <div className={Style.RuleTree__folder} data-id={rule_directory.id} onClick={this.open}>
            { rule_directory.open ?
              <Icon name='folder-open' size='m' color='blue' />
              :
              <span className={Style.RuleTree__folderIcon}>
                <Icon name='folder' size='m' color='blue' />
              </span>
            }
            <span className='u-ml-5 u-va-middle u-fw-bold'>{rule_directory.name}</span>
          </div>
          { this.props.rule_manage ?
            <div className='u-ml-5' data-id={rule_directory.id} data-name={rule_directory.name} onClick={this.addFolder}>
              <Icon name='pen' size='s' color='gray' className='u-pointer-events-none' />
            </div>
            : null
          }
          { this.props.rule_manage ?
            <div className='u-ml-5' data-id={rule_directory.id} onClick={this.deleteFolder}>
              <Icon name='close' size='xxs' color='gray' className='u-pointer-events-none' />
            </div>
            : null
          }
        </div>
        { rule_directory.open ?
          <ul className={Style.RuleTree__list}>
            { (rule_directory.rule_directories || []).map( rule_directory => {
              return this.content(rule_directory);
            })}
            { this.props.rule_manage ?
              <li className={Style.RuleTree__item}>
                <div className={`u-ml-5 ${Style.RuleTree__folder}`} data-parent={rule_directory.id} onClick={this.addFolder}>
                  <Icon name='folder-add' size='s' color='gray' />
                  <span className='u-fc-darkGray u-ml-5 u-fs-small'>フォルダ作成</span>
                </div>
              </li>
              : null
            }
            { (rule_directory.rules || []).map( rule => {
              return this.rule_file(rule);
            })}
            { this.props.rule_manage ?
              <li className={Style.RuleTree__item}>
                <span data-id={rule_directory.id} onClick={() => window.modalPage(`new?rule_directory_id=${rule_directory.id}`)}>
                  <div className={`u-ml-5 ${Style.RuleTree__folder}`} data-id={rule_directory.id} onClick={this.addRule}>
                    <Icon name='add' size='s' color='gray' />
                    <span className='u-fc-darkGray u-ml-5 u-fs-small'>社内規定追加</span>
                  </div>
                </span>
              </li>
              : null
            }
          </ul>
          : null
        }
      </li>
    );
  }

  /**
   *  社内規定
   *  @version 2018/06/10
   */
  rule_file = rule => {

    if (rule.deleted) return null;

    return (
      <li className={Style.RuleTree__item} key={`rule-${rule.id}`}>
        <div className={Style.RuleTree__wrap}>
          <span className={Style.RuleTree__folder} data-id={rule.id} onClick={() => window.modalPage(rule.id)}>
            <Icon name='document' size='m' color='black' class='u-va-middle' />
            <span className='u-ml-5 u-va-middle'>{rule.name}</span>
          </span>
          { this.props.rule_manage ?
            <div className='u-ml-5' data-id={rule.id} onClick={this.deleteRule}>
              <Icon name='close' size='xxs' color='gray' className='u-pointer-events-none' />
            </div>
            : null
          }
        </div>
      </li>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    const {rule_directories, rules} = this.state;

    return (
      <div className={Style.RuleTree}>
        <ul>

          { (rule_directories || []).map( rule_directory => {
            return this.content(rule_directory);
          })}

          <li className={Style.RuleTree__item}>
            <div className={`u-ml-5 ${Style.RuleTree__folder}`} onClick={this.addFolder}>
              <Icon name='folder-add' size='s' color='gray' />
              <span className='u-fc-darkGray u-ml-5 u-fs-small'>フォルダ作成</span>
            </div>
          </li>

          { (rules || []).map( rule => {
            return this.rule_file(rule);
          })}
        </ul>

        { this.state.edit_show ?
          <div className={Style.RuleTree__editOverlay} onMouseDown={() => { this.setState({edit_show: false, parent_rule_directory_id: null, current_rule_directory_id: null, current_rule_directory_name: ''}); }}>
            <div className={Style.RuleTree__editInner} onMouseDown={ e => e.stopPropagation() }>
              <input type='text' defaultValue={this.state.current_rule_directory_name} className='c-form-text' style={{width: '80%'}} placeholder='フォルダ名を入力してください' ref='folder_name'/>
              <div className='c-btnMain-primary u-ml-10' style={{width: '15%'}} onClick={this.edit}>送信</div>
            </div>
          </div>
          : null
        }
      </div>
    );
  }
}
