import React, {useState, useEffect} from 'react';
import Style from './style';
// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

export const Matrix = (
  {
    type, 
    category, 
    matrix_ids, 
    less_than_thirty_days, 
    less_than_sixty_days, 
    less_than_half_year, 
    over_half_year, 
    no_contact, 
  }) => {

  const [matrices, setMatrices] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const onClickCategory = () => {
    if (!matrices.length) {
      Request.get(`/users/matrices/spread?type=${type}&parent_category_id=${category.id}`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .withCredentials()
      .then(response => {
        setMatrices(response.body.matrices);
      }).catch(error => console.log(error));
    } else {
      setIsVisible((prev) => !prev)
    } 
  }

  useEffect(() => {
    if (matrices.length) {
      setIsVisible((prev) => !prev)
    }
  }, [matrices])

  return (
    <>
      <tr className={Style.MatrixSwitch}>
        <td rowSpan={matrices.length + 1} className={Style.MatrixLarge}></td>
        <td rowSpan={matrices.length + 1} className={Style.MatrixMedium}></td>
        <td rowSpan={matrices.length + 1} className={Style.MatrixSmall}>{category.name}</td>
        <td onClick={onClickCategory} className={Style.MatrixSmallData}>
          { isVisible ?
            <span className={Style.MatrixSwitchClose}></span> : <span className={Style.MatrixSwitchOpen}></span>
          }
        </td>
        <td className={Style.MatrixSmallData}>
          { less_than_thirty_days ?
            <a href={`/users/companies?matrix_type=${type}&matrix_id=${matrix_ids}&matrix_column=less_than_thirty_days`} className='u-td-underline' target='_blank'>
              {Number(less_than_thirty_days).toLocaleString()}
            </a> :
            <span>0</span>
          }
        </td>
        <td className={Style.MatrixSmallData}>
          { less_than_sixty_days ?
            <a href={`/users/companies?matrix_type=${type}&matrix_id=${matrix_ids}&matrix_column=less_than_sixty_days`} className='u-td-underline' target='_blank'>
              {Number(less_than_sixty_days).toLocaleString()}
            </a> :
            <span>0</span>
          }
        </td>
        <td className={Style.MatrixSmallData}>
          { less_than_half_year ?
            <a href={`/users/companies?matrix_type=${type}&matrix_id=${matrix_ids}&matrix_column=less_than_half_year`} className='u-td-underline' target='_blank'>
              {Number(less_than_half_year).toLocaleString()}
            </a> :
            <span>0</span>
          }
        </td>
        <td className={Style.MatrixSmallData}>
          { over_half_year ?
            <a href={`/users/companies?matrix_type=${type}&matrix_id=${matrix_ids}&matrix_column=over_half_year`} className='u-td-underline' target='_blank'>
              {Number(over_half_year).toLocaleString()}
            </a> :
            <span>0</span>
          }
        </td>
        <td className={Style.MatrixSmallData}>
          { no_contact ?
            <a href={`/users/companies?matrix_type=${type}&matrix_id=${matrix_ids}&matrix_column=no_contact`} className='u-td-underline' target='_blank'>
              {Number(no_contact).toLocaleString()}
            </a> :
            <span>0</span>
          }
        </td>
      </tr>
      { matrices.map((matrix) => {
          return (
            <tr className={ Style.MatrixRow} key={matrix.id}>
              <td className={isVisible ? Style.MatrixDetail : null}>
                <div className={isVisible ? Style.MatrixOpen : Style.MatrixClose}>
                  {matrix.category_name}
                </div>
              </td>
              <td className={isVisible ? Style.MatrixDetail : null}>
                <div className={isVisible ? `${Style.MatrixOpen} u-ta-right` : Style.MatrixClose}>
                  { matrix.less_than_thirty_days ?
                    <a href={`/users/companies?matrix_type=${type}&matrix_id=${matrix.id}&matrix_column=less_than_thirty_days`} className='u-td-underline' target='_blank'>
                      {Number(matrix.less_than_thirty_days).toLocaleString()}
                    </a> :
                    <span>0</span>
                  }
                </div>
              </td>
              <td className={isVisible ? Style.MatrixDetail : null}>
                <div className={isVisible ? `${Style.MatrixOpen} u-ta-right` : Style.MatrixClose}>
                  { matrix.less_than_sixty_days ?
                    <a href={`/users/companies?matrix_type=${type}&matrix_id=${matrix.id}&matrix_column=less_than_sixty_days`} className='u-td-underline' target='_blank'>
                      {Number(matrix.less_than_sixty_days).toLocaleString()}
                    </a> :
                    <span>0</span>
                  }
                </div>
              </td>
              <td className={isVisible ? Style.MatrixDetail : null}>
                <div className={isVisible ? `${Style.MatrixOpen} u-ta-right` : Style.MatrixClose}>
                  { matrix.less_than_half_year ?
                    <a href={`/users/companies?matrix_type=${type}&matrix_id=${matrix.id}&matrix_column=less_than_half_year`} className='u-td-underline' target='_blank'>
                      {Number(matrix.less_than_half_year).toLocaleString()}
                    </a> :
                    <span>0</span>
                  }
                </div>
              </td>
              <td className={isVisible ? Style.MatrixDetail : null}>
                <div className={isVisible ? `${Style.MatrixOpen} u-ta-right` : Style.MatrixClose}>
                  { matrix.over_half_year ?
                    <a href={`/users/companies?matrix_type=${type}&matrix_id=${matrix.id}&matrix_column=over_half_year`} className='u-td-underline' target='_blank'>
                      {Number(matrix.over_half_year).toLocaleString()}
                    </a> :
                    <span>0</span>
                  }
                </div>
              </td>
              <td className={isVisible ? Style.MatrixDetail : null}>
                <div className={isVisible ? `${Style.MatrixOpen} u-ta-right` : Style.MatrixClose}>
                  { matrix.no_contact ?
                    <a href={`/users/companies?matrix_type=${type}&matrix_id=${matrix.id}&matrix_column=no_contact`} className='u-td-underline' target='_blank'>
                      {Number(matrix.no_contact).toLocaleString()}
                    </a> :
                    <span>0</span>
                  }
                </div>
              </td>
            </tr>
          )
        })
      }
    </>
  )
}