import React from 'react'
import Style from './style.sass'
import Icon  from '../icon'

import { WHITE, BLACK, COLORS, DEFAULT_COLOR, DEFAULT_FORGROUND } from './properties.es6'

/**
 *  @version 2018/06/10
 */
export default class ColorPicker extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    const default_color = props.color || props.default_color || DEFAULT_COLOR;

    this.state = {
      current_color: default_color,
      input_color:   default_color,
      forground:     this.forground(default_color),
      error: false,
    };
  }

  /**
   *  カラーを取得する
   *  @version 2018/06/10
   */
  color = () => {

    return {
      background: this.state.current_color,
      forground: this.state.forground,
    };
  }

  /**
   *  フォームモーダルを表示する
   *  @version 2018/06/10
   */
  open = () => {

    this.setState({ show: true }, () => document.body.style.overflow = 'hidden');
  }

  /**
   *  フォームモーダルを閉じる
   *  @version 2018/06/10
   */
  close = () => {

    this.setState({ show: false }, () => document.body.style.overflow = 'auto');
  }

  /**
   *  カラーを適用する
   *  @version 2018/06/10
   */
  changeColor = () => {

    this.setState({ show: false }, () => window.location = `/users/users/color_change?color=${encodeURIComponent(this.state.current_color)}`);
  }

  /**
   *  カラーを選択する
   *  @version 2018/06/10
   */
  _onClickColor = e => {

    const color = e.target.dataset.color;
    this.setState({current_color: color, input_color: color, error: false, forground: this.forground(color)});
  }

  /**
   *  カラーを記入する
   *  @version 2018/06/10
   */
  _onChangeColor = e => {

    const color = e.target.value;
    if (color.match(/^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/)) {
      this.setState({input_color: color, current_color: color, error: false, forground: this.forground(color)});
    } else {
      this.setState({input_color: color, error: true});
    }
  }

  /**
   *  輝度の変換
   *  @version 2018/06/10
   */
  _getRGBForCalculateLuminance = _color => {

    const color = _color / 255
    if (color <= 0.03928) {
      return color / 12.92;
    } else {
      return Math.pow(((color + 0.055) / 1.055), 2.4);
    }
  }
  
  /**
   *  相対輝度に変換する
   *  @version 2018/06/10
   */
  _getRelativeLuminance = color => {

    const {red, green, blue} = color
    const r = this._getRGBForCalculateLuminance(red);
    const g = this._getRGBForCalculateLuminance(green);
    const b = this._getRGBForCalculateLuminance(blue);
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }

  /**
   *  コントラスト比を算出する
   *  @version 2018/06/10
   */
  _getContrastRatio = (color1, color2) => {

    const luminance1 = this._getRelativeLuminance(color1);
    const luminance2 = this._getRelativeLuminance(color2);
    const bright = Math.max(luminance1, luminance2);
    const dark = Math.min(luminance1, luminance2);
    return (bright + 0.05) / (dark + 0.05);
  }

  /**
   *  hexをrgbに変換する
   *  @version 2018/06/10
   */
   _hexToRGB = hex => {

    if (hex.length == 4) {

      return {
        red: parseInt(`${hex[1]}${hex[1]}`, 16),
        green: parseInt(`${hex[2]}${hex[2]}`, 16),
        blue: parseInt(`${hex[3]}${hex[3]}`, 16),
      };
    } else {

      return {
        red: parseInt(hex.slice(1, 3), 16),
        green: parseInt(hex.slice(3, 5), 16),
        blue: parseInt(hex.slice(5, 7), 16),
      };
    }
  }

  /**
   *  hexに対してのforgroundカラーを取得する
   *  @version 2018/06/10
   */
   forground = hex => {

    if (!hex.match(/^#([0-9a-f]{3}|[0-9a-f]{6})$/)) return WHITE;

    const color = this._hexToRGB(hex);
    const darkRatio = this._getContrastRatio(color, {red: 0, green: 0, blue: 0})
    const lightRatio = this._getContrastRatio(color, {red: 255, green: 255, blue: 255})

    return lightRatio < darkRatio ? BLACK : WHITE;
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <React.Fragment>
        { this.state.show ?
          <div className={Style.ColorPicker} onMouseDown={this.close}>
            <div className={Style.ColorPicker__body} onMouseDown={ e => e.stopPropagation() }>

              <div className={Style.ColorPicker__header} style={{backgroundColor: this.state.current_color}}>
                
                <div onClick={this.close} className={Style.ColorPicker__closeIcon}>
                  <Icon name='close' size='m' color='white'/>
                </div>
                <p style={{color: this.state.forground}}>{this.state.current_color}</p>
              </div>
              <div className={Style.ColorPicker__main}>

                <ul className={Style.ColorPicker__colors}>
                  { COLORS.map((color, index) => {
                    const key = `color-${index}`;
                    return (
                      <li {...{key}} data-color={color} className={`${Style.ColorPicker__color} ${color == this.state.current_color ? Style.ColorPicker__active : null}`} style={{backgroundColor: color}} onClick={this._onClickColor}></li>
                    );
                  })}
                </ul>
                
                <div className='u-mt-15'>
                  <input type='text' className='c-form-text' ref={ node => this.colorRef = node} value={this.state.input_color} onChange={this._onChangeColor} autoComplete='off' />
                  { this.state.error ? <p className='u-fs-s u-fc-red'>正しいカラーコードではありません。</p> : null }
                </div>

                <div onClick={this.changeColor} className='u-mt-30 c-btnMain-primary u-display-block'>適用する</div>
              </div>
            </div>
          </div>
          :
          null
        }
        
        <div className={Style.ColorPicker__input}>
          <input type='hidden' name={this.props.name} ref={ node => this.inputRef = node} value={this.state.current_color} readOnly={true} placeholder={DEFAULT_COLOR} />
          <div className={Style.ColorPicker__label} style={{backgroundColor: this.state.current_color}} onClick={this.open}></div>
        </div>
      </React.Fragment>
    );
  }
}
