import React      from 'react'
import Dayjs   from 'dayjs'

import DatePicker    from '../date_picker'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

import {
  RESULT_TYPES,
  NO_RESULT,
  TEL_APPOINTMENT,
  DM_APPOINTMENT,
  KEY_PERSON_ABSENCE,
  BLOCKED,
  KEY_PERSON_REJECT,
  KEY_PERSON_CONTACT,
  APPOINTMENT_TYPES,
  EMOTIONS,
  NORMAL_EMOTION,
  ANGER_EMOTION,
} from './properties'

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class TelDetail extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      retry_at: null,
      appointment_at: null,
      appointment_type: 'web',
      show_check: false,
      show_report: false,
      show_appointment: false,
      added_comment_template: false,
      under_transmission: false,
      result_type: KEY_PERSON_ABSENCE,
      emotion: NORMAL_EMOTION,
    }
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {}
  
  // 次回連絡日
  setRetryAt = datetime => {
    this.setState({ retry_at: datetime.datetime })
  }
  
  // アポ日
  setAppointmentAt = datetime => {
    this.setState({ appointment_at: datetime.datetime })
  }

  // アポ種別
  changeAppointmentType = e => {

    this.setState({ appointment_type: e.target.value })
  }

  // 温度感
  changeEmotion = e => {
    this.setState({ emotion: e.target.value })
  }

  // TEL結果
  changeResult = e => {

    this.setState({
      result_type: e.target.value,
      show_check: [TEL_APPOINTMENT].includes(e.target.value),
      show_report: [KEY_PERSON_CONTACT, TEL_APPOINTMENT, DM_APPOINTMENT].includes(e.target.value),
      show_appointment: [TEL_APPOINTMENT, DM_APPOINTMENT].includes(e.target.value),
    })
  }

  // 候補先募集
  changeCheck = e => {
    if (e.target.checked && !this.state.added_comment_template) {
      if (this.refs.comment.value != '') this.refs.comment.value += "\n";
      this.refs.comment.value = this.refs.comment.value + [
        '【候補先を募集する際は下記事項の記載が必須】',
        '面談日: ',
        '目的: ',
        '業種・事業内容:（特にDX情報不足の場合）',
        'TEL内容: ',
        '留意事項等: ',
      ].join("\n");
      this.setState({added_comment_template: true})
    }
  }

  /**
   *  登録
   *  @version 2018/06/10
   */
  _onSubmit = () => {

    let body = '';
    let retry_at = this.state.retry_at;
    let appointment_at = this.state.appointment_at;
    let appointment_type = this.state.appointment_type;
    let result_type = this.state.result_type;
    //body += `TEL結果: ${result_type}\n`;
    body += appointment_at ? `アポ日: ${Dayjs(appointment_at).format('YYYY年MM月DD日')}\n` : '';
    body += retry_at ? `次回電話予定: ${Dayjs(retry_at).format('YYYY年MM月DD日')}\n` : '';
    body += this.refs.comment.value;

    if (this.state.show_appointment && (!appointment_at)) {

      window.alertable({ type: 'warning', message: 'アポ日は入力必須です。' });
      return false;
    }

    let field = {
      'company_tel[company_id]': this.props.company_id,
      'company_tel[body]': body,
      'company_tel[retry_at]': retry_at || '',
      'company_tel[result_type]': result_type || NO_RESULT,
      'company_tel[emotion]': this.state.emotion,
      'appointment_at': appointment_at || '',
      'appointment_type': appointment_type,
    };

    // IDが指定されている場合
    if (this.props.id) field['id'] = this.props.id;

    // 売手開拓リストIDが指定されている場合
    if (this.props.group_company_id) field['group_company_id'] = this.props.group_company_id;
    // 買手開拓リストIDが指定されている場合
    if (this.props.company_company_id) field['company_company_id'] = this.props.company_company_id;
    // 候補先を募集する場合
    if (this.state.show_check) field['company_tel[is_candidates]'] = this.refs.check.checked;
    // 日報に追記する場合
    if (this.state.show_report) field['report'] = this.refs.report.checked;

    this.setState({under_transmission: true});
    // 電話内容登録
    Request.post(`/users/company_tels`)
      .field(field)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        this.props.callback();

        if (response.body.status != 'success') {
          window.alertable({ type: 'error', message: response.body.message });
          return;
        }

        window.alertable({ type: 'success', message: '電話内容を登録しました。', close_callback: () => {
          this.setState({under_transmission: false});
          if (this.props.group_company_id) {
            const group_company_status = document.getElementById(`status_${this.props.group_company_id}`) || (window.parent && window.parent.document.getElementById(`status_${this.props.group_company_id}`));
            if (group_company_status != null) {
              group_company_status.value = [TEL_APPOINTMENT, DM_APPOINTMENT].includes(result_type) ? 'appointment' : 'called';
            }
          }
          if (this.props.company_company_id) {
            const company_company_status = document.getElementById(`status_${this.props.company_company_id}`) || (window.parent && window.parent.document.getElementById(`status_${this.props.company_company_id}`));
            if (company_company_status != null && company_company_status.value == 'yet') {
              company_company_status.value = 'contacted';
            }
          }
          if (this.props.redirect_to) {
            if (window.location.pathname == this.props.redirect_to.replace(/#.*/, '')) {
              window.location.reload();
            } else {
              window.location.href = this.props.redirect_to;
            }
          }
        }});
      });
  }

  /**
   *  モーダルに表示するコンテンツ
   *  @version 2018/06/10
   */
  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>

        <div className='c-form-label u-mt-15'>
          <label>TEL結果</label>
          <span className='c-form__required u-ml-10'>必須</span>
        </div>
        <div className='c-form-selectWrap'>
          <select className='c-form-select' onChange={this.changeResult}>
            { RESULT_TYPES.map((result_type, index) => {
              const key = 'result_type-'+index;
              return (
                <option {...{key}} value={result_type.value}>{result_type.name}</option>
              );
            })}
          </select>
        </div>

        { [KEY_PERSON_ABSENCE, BLOCKED].includes(this.state.result_type) ?
          <div>
            <div className='c-form-label u-mt-15'>
              <label>受電者の温度感</label>
              <span className='c-form__required u-ml-10'>必須</span>
            </div>
            <div className='c-form-selectWrap'>
              <select className='c-form-select' onChange={this.changeEmotion}>
                { EMOTIONS.map((emotion, index) => {
                  const key = 'emotion-'+index;
                  return (
                    <option {...{key}} value={emotion.value}>{emotion.name}</option>
                  );
                })}
              </select>
            </div>
            <div className='u-mt-10 c-attention u-fs-small'>お怒りを選択した場合、この会社に対して<span className='u-fw-bold u-fc-red'>3ヶ月の架電制限</span>がかかります。</div>
          </div>
          : null
        }

        { !this.props.company_company_id && [TEL_APPOINTMENT, DM_APPOINTMENT].includes(this.state.result_type) ?
          <p className='u-mt-5 u-fc-red u-fs-small'>アポ取得を選択した場合アポ情報が自動で登録されます。</p>
          : null
        }

        { !this.props.company_company_id && [KEY_PERSON_REJECT, KEY_PERSON_CONTACT].includes(this.state.result_type) ?
          <div className='u-mt-10 c-attention u-fs-small'>キーマンと接触(拒否含む)した場合、<br />この会社に対して<span className='u-fw-bold u-fc-red'>2ヶ月の架電制限</span>がかかります。</div>
          : null
        }

        { this.state.show_check ?
          <div className='u-mt-15'>
            <label className='c-form-checkboxLabel'>
              <span className='c-form-label'>候補先を募集する</span>
              <input className='c-form-checkbox u-ml-10' type='checkbox' defaultChecked={false} ref='check' onChange={e => this.changeCheck(e)} />
              <i className='c-form-checkboxIcon'></i>
            </label>
          </div>
          : null
        }

        { this.state.show_appointment ?
          <div>
            <div className='c-form-label u-mt-10'>
              <label>アポ日</label>
              <span className='c-form__required u-ml-10'>必須</span>
            </div>
            <DatePicker type='text' apply={this.setAppointmentAt} required={true} />

            <div className='c-form-label u-mt-10'>
              <label>アポ種別</label>
              <span className='c-form__required u-ml-10'>必須</span>
            </div>
            <div className='c-form-selectWrap'>
              <select className='c-form-select' onChange={this.changeAppointmentType}>
                { APPOINTMENT_TYPES.map((appointment_type, index) => {
                  const key = 'appointment_type-'+index;
                  return (
                    <option {...{key}} value={appointment_type.value}>{appointment_type.name}</option>
                  );
                })}
              </select>
            </div>
          </div>
          :
          <div>
            <div className='c-form-label u-mt-10'>
              <label>次回TEL日</label>
            </div>
            <DatePicker type='text' apply={this.setRetryAt} />
            <p className='u-fs-small u-fc-darkGray'>次回TEL日を入力すると自動で「次回TELリスト」に追加されます。</p>
          </div>
        }

        <div className='c-form-label u-mt-10'>
          <label>コメント</label>
        </div>
        <textarea rows='6' className='c-form-textarea' ref='comment' placeholder='コメントを入力してください' autoComplete='off' spellCheck='false' ></textarea>

        { this.state.show_report ?
          <div>
            <div className='u-mt-15'>
              <label className='c-form-checkboxLabel'>
                <span className='c-form-label'>日報に追加</span>
                <input className='c-form-checkbox u-ml-10' type='checkbox' defaultChecked={false} ref='report' />
                <i className='c-form-checkboxIcon'></i>
              </label>
            </div>

            <div className='u-mt-5 u-fs-small'>※ 日報に追加のチェックを入れると日報に追加されます。<br />日報が未作成の場合は一時保存状態の日報が自動で作成されます。</div>
          </div>
          : null
        }
        
        <div className='u-mt-20'>
          {this.state.under_transmission ? 
            <div className='c-btnMain-negative u-display-block'>送信中</div> :
            <div className='c-btnMain-primary u-display-block' onClick={this._onSubmit}>登録する</div>
          }
        </div>
      </div>
    );
  }
}
