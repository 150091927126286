import React from 'react';
import style from './style';

export const Selectbox = ({label, parent_model, child_model, column, value, rowNum, onChange, options, required, include_blank}) => {
  return (
    <div>
      <div className={style.Selectbox__item} >
        <div>
          <label className={style.Selectbox__label} htmlFor='name'>{label}</label>
          { required &&
            <span className={style.Selectbox__required}>必須</span>
          }
        </div>
        <select className={style.Selectbox__input}  id={ `${parent_model}_${child_model}_attributes_${rowNum}_${column}`} name={`${parent_model}[${child_model}_attributes][${rowNum}][${column}]`} value={value} onChange={(e) => onChange(e ,rowNum, column)}>
          { include_blank &&
            <option value=''>-</option>
          }
          { options.map((option, index) => <option value={option.value} key={`${parent_model}_${child_model}_${index}`}>{option.name}</option>)}
        </select>
      </div>
    </div>
  )
}