import React from 'react'
import TagsInput from 'react-tagsinput'

/**
 *  @version 2018/06/10
 */
export default class Tags extends React.Component {

  constructor(props) {
    super(props)
    
    let tags = props.tags || [];
    let recommends = (props.recommends || []).filter(recommend => {
      return tags.indexOf(recommend.name) < 0;
    });

    this.state = {
      tags: tags,
      recommends: recommends,
    };
  }

  _onChange = tags => {
    this.setState({tags});
  }

  _onChangeRecommend = e => {

    const name = e.target.dataset.name;

    let tags = (this.state.tags || []).slice();
    const index = tags.indexOf(name);

    if (index < 0) {
      tags.push(name)
    } else {
      tags.splice(index, 1);
    }
    this.setState({tags});
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render () {

    return (
      <div>
        <TagsInput value={this.state.tags} onChange={this._onChange} inputProps={{placeholder: 'タグは複数入力することが可能です'}} addOnBlur={true} />
        
        { this.state.tags.map((tag, i) => {
          const key = 'tag-'+i;
          return <input {...{key}} type='hidden' value={tag} name='tags[]' />;
        })}
        
        { (this.props.tags || []).length > 0 ?
          <div className='c-attention u-mt-15 u-fs-small'>
            <div className='u-fw-bold'>類似タグ候補</div>
            { this.state.recommends.length > 0 ?
              <div>
                { this.state.recommends.map((recommend, i) => {
                  const key = 'recommend-'+i;
                  return <span {...{key}} data-name={recommend.name} className={`c-tag u-cursor-pointer ${this.state.tags.includes(recommend.name) ? 'active' : ''}`} onClick={this._onChangeRecommend}>{recommend.name} ({recommend.companies_count})</span>;
                })}
              </div>
              :
              <span>類似タグはありません。</span>
            }
          </div>
          : null
        }
      </div>
    )
  }
}
