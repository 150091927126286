import React from 'react'
import Icon  from 'users/icon'
import Style from './style.sass'

import { ITEMS } from './properties.es6'

/**
 *  @version 2018/06/10
 */
export default class Palette extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = {show: false};
  }

  /**
   *  アイテムを追加する
   *  @version 2018/06/10
   */
  _onClick = e => {

    let action = e.target.dataset.action;
    // iconクリック時対応
    if (!action) action = e.target.parentNode.dataset.action;
    if (!action) return;

    document.body.style.overflow = 'auto';
    this.close();

    this.props.addItem(action, this.props.index);
  }

  /**
   *  モーダルを開く
   *  @version 2018/06/10
   */
  open = () => {

    this.setState({ show: true }, () => document.body.style.overflow = 'hidden');
  }

  /**
   *  モーダルを閉じる
   *  @version 2018/06/10
   */
  close = () => {

    this.setState({ show: false }, () => document.body.style.overflow = 'auto');
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className={Style.Palette}>
        <ul className={`${this.props.position == 'horizontal' ? Style.Palette__horizontal : Style.Palette__vertical}`}>
          { Object.keys(ITEMS).map((item, i) => {

            let key = `paletteItem-${i}`;
            if (this.props.index) key += `--${this.props.index}`;

            return (
              <li className={Style.Item} {...{key}} data-action={item} onClick={this._onClick}>
                <Icon name={ITEMS[item].icon} size='s' />
                <div className='u-ml-5'>{ITEMS[item].title}</div>
              </li>
            );
          })}
        </ul>

        { this.state.show ?
          <div className={Style.Palette__overlay} onMouseDown={this.close}>
            <div className={Style.Palette__inner} onMouseDown={ e => e.stopPropagation() }>
              <div className={Style.Palette__others}>

                { Object.keys(ITEMS).map((item, i) => {

                  let key = `paletteItem-${i}`;
                  if (this.props.index) key += `--${this.props.index}`;

                  return (
                    <div className={Style.Palette__othersItem} {...{key}} data-action={item} onClick={this._onClick}>
                      <Icon name={ITEMS[item].icon} size='l' color='main' />
                      <div className='u-mt-10'>{ITEMS[item].title}</div>
                    </div>
                  );
                })}
              </div>
              <div onClick={this.close} className='c-btnMain-negative u-display-block u-mt-10'>閉じる</div>
              <div onClick={this.close} className={Style.Palette__closeIcon}>
                <Icon name='close' size='l' color='darkGray'/>
              </div>
            </div>
          </div>
          : null
        }
      </div>
    );
  }
}
