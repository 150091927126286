import React from 'react'
import Style from './style.sass'

import Tags   from './tags/index'

/**
 *  @version 2018/06/10
 */
export default class CompaniesTags extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = { 
      show: false,
      tags: this.props.tags || [],
    }
  }

  /**
   *  タグモーダルを表示する
   *  @version 2018/06/10
   */
  _open = () => {

    this.setState({ show: true }, () => document.body.style.overflow = 'hidden');
  }

  /**
   *  タグモーダルを閉じる
   *  @version 2018/06/10
   */
  _close = () => {

    this.setState({ show: false }, () => document.body.style.overflow = 'auto');
  }

  /**
   *  タグをセットする
   *  @version 2018/06/10
   */
  setTag = tags => {

    this.setState({tags: tags})
  }

  /**
   *  タグを除去する
   *  @version 2018/06/10
   */
  _removeTag = e => {

    let tags = this.state.tags.map(tag => {
      if (tag.id == e.target.dataset.id) tag._destroy = true;
      return tag;
    });
    this.setState({tags: tags})
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render () {

    return (
      <div>
        { this.state.show ?
          <div className='c-modal-window' onMouseDown={this._close}>
            <Tags setTag={this.setTag} tags={this.state.tags} close={this._close}/>
          </div>
          : null
        }
        <ul className={Style.CompaniesTags}>

          { this.state.tags.length > 0 ?
            this.state.tags.map((tag, i) => {
              const key = `tag_${i}`;
              if (tag._destroy) return;
              return (
                <li {...{key}} className={Style.CompaniesTags__tag}>
                  <a href={`/users/companies?tags[]=${tag.name}`} target='_top'>{tag.name} ({tag.companies_count})</a>
                  <span className='u-ml-5 u-fc-lightGray' onClick={this._removeTag} data-id={tag.id} style={{cursor: 'pointer'}}>
                    x
                  </span>
                </li>
              );
            })
            :
            <div className='u-fc-lightGray'>タグは紐づいていません</div>
          }
        </ul>
        <div className='u-td-underline u-mt-15 u-fs-small'><span onClick={this._open} style={{cursor: 'pointer'}}>＋関連タグを追加</span></div>

        { this.state.tags.map((tag, i) => {
          const key = `ta_${i}`;
          let companies_tags = this.props.companies_tags.filter(companies_tag => companies_tag.tag_id == tag.id);
          return (
            <div {...{key}}>
              { companies_tags.length >= 1 ?
                <input type='hidden' value={companies_tags[0].id} name={`company[companies_tags_attributes][${i}][id]`} />
                : null
              }
              <input type='hidden' value={tag.id} name={`company[companies_tags_attributes][${i}][tag_id]`} />
              <input type='hidden' value={tag._destroy || false} name={`company[companies_tags_attributes][${i}][_destroy]`} />
            </div>
          )
        })}
      </div>
    );
  }
}
