import React      from 'react'
import Template   from '../template'
import Style      from './style.sass'
import Icon       from 'users/icon'
import Utilities  from 'scripts/utilities'
import DataEditor from './data_editor'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

import { HEADER_TYPE_UPPER, HEADER_TYPE_LEFT, HEADER_TYPES } from './properties.es6'

/**
 *  @version 2018/06/10
 */
export default class Table extends Template {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  defaultState (props) {

    return {
      data: props.item.value.data || [[
        { type: 'text', text: 'テキスト1' },
        { type: 'text', text: 'テキスト2' }
      ], [
        { type: 'text', text: 'テキスト3' },
        { type: 'text', text: 'テキスト4' }
      ]],
      header_type: props.item.value.header_type,
    }
  }

  /**
   *  アイテム情報取得用
   *  @version 2018/06/10
   */
  reference = () => {

    // 行
    let text = '';
    if (this.state.item.value.data) {
      this.state.item.value.data.forEach((row, row_index) => {
        row.forEach((col, col_index) => {

          if (col.type == 'text') text += Utilities.trimHtmlTags(col.text);
        })
      });
    }

    return {
      text:   text,
      length: text.length,
    };
  }

  /**
   *  タイトル
   *  @version 2018/06/10
   */
  templateComponentTitle() { return 'テーブル' }

  /**
   *  アイテムの追加
   *  @version 2018/06/10
   */
   add = () => {

    this._updateItem({ data: this.state.data, header_type: this.state.header_type });
  }

  /**
   *  列の追加
   *  @version 2018/06/10
   */
  addCol = (index=null) => {

    const table = this.state.data.map((data, index) => {

      data.push({ type: 'text', text: 'テキスト' });
      return data;
    });

    this.setState({data: table});
  }

  /**
   *  列の削除
   *  @version 2018/06/10
   */
  removeCol = e => {

    const target = e.target.dataset.col;

    const table = this.state.data.map((data, index) => {

      // カラム数が1以下の場合は実施しない
      if (data.length > 1) data.splice(target, 1);
      return data;
    });

    this.setState({data: table});
  }

  /**
   *  行の追加
   *  @version 2018/06/10
   */
  addRow = () => {

    let table = this.state.data.slice();
    const cols = this.state.data[0].length;

    // 行を追加する(カラム数はすでに存在している数)
    table.push([...Array(cols)].map(() => { return { type: 'text', text: 'テキスト' } }));

    this.setState({data: table});
  }

  /**
   *  行の削除
   *  @version 2018/06/10
   */
  removeRow = e => {

    const target = e.target.dataset.row;

    let table = this.state.data.slice();

    // 行数が1以下の場合は実施しない
    if (table.length <= 1) return false;

    table.splice(target, 1);
    this.setState({data: table});
  }

  /**
   *  ヘッダ位置の変更
   *  @version 2018/06/10
   */
  _changeHeaderType = () => {

    this.setState({header_type: this.headerTypeRef.value});
  }

  /**
   *  データ内容の表示
   *  @version 2018/06/10
   */
  content(item) {

    // テキストアイテム
    if (item.type == 'text') {

      return <div dangerouslySetInnerHTML={{ __html: item.text }}></div>;

    // 画像アイテム
    } else if (item.type == 'image') {

      return <img className='p-article__imageImage' src={item.src} alt={item.title} />;

    // リンクアイテム
    } else if (item.type == 'link') {

      return <a className={`c-btnMain-standard u-fw-bold ${item.color ? 'c-btn-'+item.color : null }`} href={item.src} target='_blank'>{item.title}</a>;
    }
  }

  /**
   *  データ内容の編集
   *  @version 2018/06/10
   */
  editData(data, row_index, col_index) {

    this.dataEditorRef._open({...data, col: col_index, row: row_index});
  }

  /**
   *  編集したデータを適用する
   *  @version 2018/06/10
   */
  decisionData = td_data => {

    let data = this.state.data.slice();
    data[td_data.row][td_data.col] = td_data;

    this.setState({data: data});
  }

  /**
   *  編集時表示
   *  @version 2018/06/10
   */
  templateEdittingContent() {

    return (
      <div className={`${Style.Table} p-article__table u-mt-10`}>
        <div className='u-mt-10 c-form-selectWrap'>
          <select className='c-form-select' defaultValue={this.state.header_type} ref={ node => this.headerTypeRef = node} onChange={this._changeHeaderType}>
            {Object.keys(HEADER_TYPES).map((header_type) => {
              const key = `text-${header_type}`;
              return (
                <option {...{key}} value={HEADER_TYPES[header_type]}>{header_type}</option>
              );
            })}
          </select>
        </div>
        <div className='u-mt-15 u-display-flex'>

          { /* 列に対する設定 */ }
          <div className='c-btn-sub' onClick={this.addCol}>列追加</div>
          { /* 行に対する設定 */ }
          <div className='c-btn-sub u-ml-15' onClick={this.addRow}>行追加</div>
        </div>

        <div className='c-table u-mt-15' style={{border: 'none'}}>
          <table className='c-table__content'>
            <tbody>

              { /* 削除ボタンを生成する */ }
              <tr>
                { this.state.data[0].map((row, col_index) => {
                    return (
                      <td key={`delete-${col_index}`} className={Style.Table__deleteCol}>
                        <span data-col={col_index} onClick={this.removeCol}>
                          <Icon name='delete' size='s' color='gray'/>
                        </span>
                      </td>
                    );
                  })
                }
                <td className={Style.Table__deleteCol}></td>
              </tr>

              { /* テーブルを生成する */ }
              { this.state.data.map((row, row_index) => {

                  return (
                    <tr key={`row-${row_index}`}>
                      { row.map((col, col_index) => {

                          let is_header = false;

                          if (this.state.header_type == HEADER_TYPE_UPPER) {

                            is_header = row_index == 0;
                          } else if (this.state.header_type == HEADER_TYPE_LEFT) {

                            is_header = col_index == 0;
                          }

                          return (
                            is_header ?
                              <th className={Style.Table__data} onClick={ () => this.editData(col, row_index, col_index)} key={`td-${row_index}-${col_index}`}>
                                { this.content(col) }
                              </th>
                              :
                              <td className={Style.Table__data} onClick={ () => this.editData(col, row_index, col_index)} key={`td-${row_index}-${col_index}`}>
                                { this.content(col) }
                              </td>
                          )
                        })
                      }
                      <td className={Style.Table__deleteRow}>
                        <span data-row={row_index} onClick={this.removeRow}>
                          <Icon name='delete' size='s' color='gray'/>
                        </span>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>

        <div className='u-mt-10 u-fc-orange u-fs-s'>※スマホでの閲覧も考慮し、テーブル内の文字列は適宜改行を行なってください。</div>

        { /* td編集モーダル */ }
        <DataEditor ref={ node => this.dataEditorRef = node} decision={this.decisionData} />

        { /* 画像一時描画用 */ }
        <canvas ref={node => this.canvasNode = node} crossOrigin='anonymous' className='u-display-none'></canvas>
      </div>
    );
  }

  /**
   *  プレビュー表示
   *  @version 2018/06/10
   */
  templatePreviewContent() {

    return (
      <div className='p-article__table'>
        <div className='c-table'>
          <table>
            <tbody>
              { /* テーブルを生成する */ }
              { this.props.item.value.data.map((row, row_index) => {

                  return (
                    <tr key={`row-${row_index}`}>
                      { row.map((col, col_index) => {

                          let is_header = false;

                          if (this.props.item.value.header_type == HEADER_TYPE_UPPER) {

                            is_header = row_index == 0;
                          } else if (this.props.item.value.header_type == HEADER_TYPE_LEFT) {

                            is_header = col_index == 0;
                          }

                          return (
                            is_header ?
                              <th key={`col-${row_index}-${col_index}`}>{ this.content(col) }</th>
                              :
                              <td key={`col-${row_index}-${col_index}`}>{ this.content(col) }</td>
                          )
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
