import React       from 'react'
import Style       from './style.sass'
import Template    from '../template'

import Request from 'superagent'
require('superagent-rails-csrf')(Request);

// fileアップロード
import Dropzone from 'react-dropzone'

/**
 *  @version 2018/06/10
 */
export default class Img extends Template {

  /**
   *  初期化時のstate
   *  @version 2018/06/10
   */
  defaultState(props) {

    return {
      url: props.item.value.url || '',
    };
  }

  /**
   *  タイトル
   *  @version 2018/06/10
   */
  templateComponentTitle() { return '画像' }

  /**
   *  アイテム情報取得用
   *  @version 2018/06/10
   */
  reference = () => {

    const text = '';
    return {
      text:   text,
      length: text.length,
    };
  }

  /**
   *  ファイル変更時
   *  @version 2018/06/10
   */
  _onChangeFile = e => {

    this.setState({ url: (window.URL || window.webkitURL).createObjectURL(e.target.files[0]) });
  }

  /**
   *  ファイルドロップ時
   *  @version 2018/06/10
   */
  _onDrop = files => {

    this.setState({ url: (window.URL || window.webkitURL).createObjectURL(files[0]) });
  }

  /**
   *  アイテムの追加
   *  @version 2018/06/10
   */
  add = () => {

    // 画像の登録確認
    if (!this.state.url) {
      window.alertable({ type: 'warning', message: '画像を登録してください' });
      return false;
    }

    // 画像に変更がない場合は何もしない
    if (this.props.item.value.url == this.state.url) {

      let item = this.props.item;
      this._updateItem(item.value);

      return;
    }

    if (!this.imageRef) {
      window.alertable({ type: 'warning', message: '画像を登録してください' });
      return false;
    }

    window.startLoading();

    fetch(this.imageRef.src).then(r => r.blob()).then(file => {

      Request.post('/users/images')
      .attach('image[file]', file)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {

          window.closeLoading();
          this._updateItem({
            upload_id:    response.body.image.id,
            url:          response.body.url,
          });
        } else {

          window.alertable({ type: 'error', message: response.body.message });
          window.closeLoading();
        }
      });
    });
  }

  /**
   *  編集時表示
   *  @version 2018/06/10
   */
  templateEdittingContent() {

    return (
      <div className='u-mt-10'>

        { this.state.url ?
          <div className={Style.Image}>

            <img src={this.state.url} ref={ node => this.imageRef = node} className={Style.Image__image} />

          </div>
          :
          <div className={Style.Image__form}>
            <div className={Style.Image__drop}>
              <label className='u-fc-darkGray u-fs-s'>アップロード</label>
              <Dropzone onDrop={this._onDrop}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()} className={Style.Image__dropzone}>
                    <input {...getInputProps()} />
                    <p>この部分に画像をドラッグ&ドロップしてください</p>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        }
      </div>
    );
  }

  /**
   *  プレビュー表示
   *  @version 2018/06/10
   */
  templatePreviewContent() {

    const { url } = this.props.item.value;

    return (
      <figure className='p-article__image'>

        <span className='p-article__imageFrame'>
          <img className='p-article__imageImage' src={url} />
        </span>
      </figure>
    );
  }
}
