import React from 'react'
// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class CheckMail extends React.Component {

  constructor(props) {

    super(props)
  }

  /**
   *  submit処理
   *  @version 2018/06/10
   */
  _onSubmit = e => {

    window.startLoading();

    const field = { type: this.props.type };

    if (this.props.type == 'masouken') {
      field['sub_address'] = document.getElementById('user_sub_address').value;
      field['sub_address_password'] = document.getElementById('user_sub_address_password').value;
    } else if (this.props.type == 'kensetsu') {
      field['sub_address'] = document.getElementById('user_kensetsu_sub_address').value;
      field['sub_address_password'] = document.getElementById('user_kensetsu_sub_address_password').value;
    } else if (this.props.type == 'touhoku') {
      field['sub_address'] = document.getElementById('user_touhoku_sub_address').value;
      field['sub_address_password'] = document.getElementById('user_touhoku_sub_address_password').value;
    } else if (this.props.type == 'financial') {
      field['sub_address'] = document.getElementById('user_financial_sub_address').value;
      field['sub_address_password'] = document.getElementById('user_financial_sub_address_password').value;
    } else if (this.props.type == 'executive') {
      field['sub_address'] = document.getElementById('user_executive_sub_address').value;
      field['sub_address_password'] = document.getElementById('user_executive_sub_address_password').value;
    } else if (this.props.type == 'quants') {
      field['sub_address'] = document.getElementById('user_quants_sub_address').value;
      field['sub_address_password'] = document.getElementById('user_quants_sub_address_password').value;
    }

    if (!field['sub_address'] || !field['sub_address_password']) {
      window.closeLoading();
      window.alertable({ type: 'warning', message: 'メールアドレスとアプリパスワードを入力してください。' });
      return false;
    }

    Request.post(`/users/users/${this.props.id}/send_mail`)
      .field(field)
      .set('X-Requested-With', 'XMLHttpRequest')
      .withCredentials()
      .then(response => {

        window.alertable({ type: 'success', message: '【成功】有効なメールアドレスとアプリパスワードです' });
        window.closeLoading();
      }).catch(error => {
        window.alertable({ type: 'error', message: '【失敗】この送信用メールアドレスとアプリパスワードの組み合わせは無効です' });
        window.closeLoading();
      });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <span onClick={this._onSubmit} className='c-btnSub-standard' style={{lineHeight: '1.5', padding: '0 5px'}}>
        送信確認
      </span>
    );
  }
}
