import React     from 'react'
import './style.css'
import Style     from './style.sass'
import Utilities from '../../scripts/utilities'

import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  DiscreteColorLegend,
  LabelSeries,
} from 'react-vis';

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class BarCharts extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      values: [],
    }
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    const contracted_counts = Object.keys(this.props.contracted_counts).map(key => {
      return {x: key, y: this.props.contracted_counts[key] * 4};
    });

    const projected_counts = Object.keys(this.props.projected_counts).map(key => {
      return {x: key, y: this.props.projected_counts[key] * 5};
    });

    const staying_projected_counts = Object.keys(this.props.staying_projected_counts).map(key => {
      return {x: key, y: this.props.staying_projected_counts[key] * 5};
    });

    const buy_contracted_counts = Object.keys(this.props.buy_contracted_counts).map(key => {
      return {x: key, y: this.props.buy_contracted_counts[key] * 3};
    });

    let width = Object.keys(this.props.contracted_counts).length * 65;
    if (width < 300) width = 300;

    return (
      <div className={`${Style.BarCharts} c-attention`}>

        <p className='u-fw-bold'>■ <ruby><rb>HKグラフ</rb><rt>法人部稼働状況</rt></ruby></p>
        
        <div className={Style.BarCharts__content}>
          <XYPlot
            className='clustered-stacked-bar-chart-example'
            xType='ordinal'
            stackBy='y'
            yDomain={[0, 200]}
            width={width}
            height={300}
          >
            <DiscreteColorLegend
              style={{position: 'absolute', left: '50px', top: '10px'}}
              orientation="horizontal"
              items={[
                {
                  title: '買AD締結',
                  color: '#3A5998'
                },
                {
                  title: '案件化完了',
                  color: '#C85B8B'
                },
                {
                  title: '滞留',
                  color: '#8b4513'
                },
                {
                  title: '売AD締結',
                  color: '#12939A'
                },
              ]}
            />
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />

            <VerticalBarSeries
              cluster='project'
              color='#12939A'
              data={contracted_counts}
            />
            <VerticalBarSeries
              cluster='project'
              color='#8b4513'
              data={staying_projected_counts}
            />
            <VerticalBarSeries
              cluster='project'
              color='#C85B8B'
              data={projected_counts}
            />
            <VerticalBarSeries
              cluster='project'
              color='#3A5998'
              data={buy_contracted_counts}
            />

            <LabelSeries
              data={contracted_counts.map((obj) => {
                return { ...obj, label: (obj.y / 4).toString() };
              })}
              labelAnchorX='middle'
              labelAnchorY='text-before-edge'
              style={{fill: 'white', fontSize: '10px'}}
            />

            <LabelSeries
              data={staying_projected_counts.map((obj) => {
                return { ...obj, label: (obj.y / 5).toString() };
              })}
              labelAnchorX='middle'
              labelAnchorY='text-before-edge'
              style={{fill: 'white', fontSize: '10px'}}
            />

            <LabelSeries
              data={projected_counts.map((obj) => {
                return { ...obj, label: (obj.y / 5).toString() };
              })}
              labelAnchorX='middle'
              labelAnchorY='text-before-edge'
              style={{fill: 'white', fontSize: '10px'}}
            />

            <LabelSeries
              data={buy_contracted_counts.map((obj) => {
                return { ...obj, label: (obj.y / 3).toString() };
              })}
              labelAnchorX='middle'
              labelAnchorY='text-before-edge'
              style={{fill: 'white', fontSize: '10px'}}
            />
          </XYPlot>
        </div>

      </div>
    );
  }
}

