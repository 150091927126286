/**
 * @version 2018/06/10
 */

export const COLORS = [
  '#FCCB7E',
  '#A6E191',
  '#FDA7DC',
  '#E0E4E8',
  '#83D1FB',
];
