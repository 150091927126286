import React   from 'react'
import Style   from './style.sass'
import Palette from './palette.jsx'

import { STATE_EDDITING, STATE_PREVIEW, ITEMS } from './properties.es6'

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class Editor extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = {
      items: this.initialItems(props.elements),
      sub_palette_index: null,
      copy_item: null,
    }
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {

    this.props.setItems(this.state.items);
  }

  /**
   *  ユニークIDの生成
   *  @version 2018/06/10
   */
  generateId() {

    return '_' + Math.random().toString(36).substr(2, 9);
  }

  /**
   *  記事情報の初期化
   *  @version 2018/06/10
   */
  initialItems(elements) {

    return (elements || []).map(item => {

      item.status = STATE_PREVIEW;
      item.uniq = item.uniq || this.generateId();
      return item;
    });
  }

  /**
   *  アイテムを削除
   *  @version 2018/06/10
   */
  _removeItem = index => {

    let items = this.state.items.slice();
    items.splice(index, 1);

    this.setState({items: items}, () => this.props.setItems(items, index));
  }

  /**
   *  アイテムを更新(子コンポーネントからのcallback)
   *  @version 2018/06/10
   */
  _updateItem = (index, item) => {

    let items = this.state.items.slice();
    items[index] = item;

    this.setState({items: items}, () => {

      this.props.setItems(items, index + 1);
      window.location.hash = parseInt(index) > -1 ? index + 1 : items.length;
    });
  }

  /**
   *  各パレットのアイテムを追加する
   *  @version 2018/06/10
   */
  _addItem = (action, index=null) => {

    let item = {
      action: action,
      uniq: this.generateId(),
      status: STATE_EDDITING,
      value: {},
    };

    let items = this.state.items.slice();

    // 挿入位置の指定があれば
    if (index != null) {

      items.splice(index + 1, 0, item);
    } else {

      items = items.concat(item);
    }

    this.setState({items: items, sub_palette_index: null}, () => {

      // 挿入位置へスクロール
      window.location.hash = index != null ? index + 2 : items.length;
      this.props.setItems(items);
    });
  }

  /**
   *  アイテムをコピーする
   *  @version 2018/06/10
   */
  _copyItem = item => {

    this.setState({copy_item: item});
  }

  /**
   *  アイテムを貼り付ける
   *  @version 2018/06/10
   */
  _pasteItem = e => {

    const index = Number(e.target.dataset.number) + 1;

    let item = JSON.parse(JSON.stringify(this.state.copy_item));
    item.uniq = this.generateId();

    let items = this.state.items.slice();
    items.splice(index, 0, item);

    this.setState({items: items, sub_palette_index: null, copy_item: null}, () => {

      this.props.setItems(items, index);
    });
  }

  /**
   *  各パレットのアイテムを取得する
   *  @version 2018/06/10
   */
  _setComponent = (item, index) => {

    return React.createElement(ITEMS[item.action].class_name, {index: index, item: item, updateItem: this._updateItem, copyItem: this._copyItem, removeItem: this._removeItem});
  }

  /**
   *  パレットアイテムリストnode
   *  @version 2018/06/10
   */
  setItemNodes = items => {

    return (
      <ul className={Style.Editor__list}>

        <li className={Style.Editor__item} id={0}>
          <div className={Style.Editor__insert}>
            { this.state.sub_palette_index == -1 ?
              <a onClick={ e => this.setState({sub_palette_index: e.target.dataset.number}) } className={Style.Editor__insertClose}>閉じる</a>
              :
              <div className={Style.Editor__insertWrap}>
                <a onClick={ e => this.setState({sub_palette_index: e.target.dataset.number}) } data-number={-1} className={Style.Editor__insertOpen}>ここに挿入</a>
                { this.state.copy_item ? <a data-number={-1} className={Style.Editor__insertCopy} onClick={this._pasteItem} >貼り付け</a> : null }
              </div>
            }
          </div>
          { this.state.sub_palette_index == -1 ? <Palette addItem={this._addItem} index={-1} id={this.props.id} position='horizontal' /> : null }
        </li>

        {items.map((item, index) => (

          <li key={item.uniq} className={Style.Editor__item} id={index + 1}>
            <section className={Style.Editor__section}>
              { this._setComponent(item, index) }
              <div className={Style.Editor__id}>ID: {index + 1}</div>
            </section>
            <div className={Style.Editor__insert}>
              { this.state.sub_palette_index == index ?
                <a onClick={ e => this.setState({sub_palette_index: e.target.dataset.number}) } className={Style.Editor__insertClose}>閉じる</a>
                :
                <div className={Style.Editor__insertWrap}>
                  <a onClick={ e => this.setState({sub_palette_index: e.target.dataset.number}) } data-number={index} className={Style.Editor__insertOpen}>ここに挿入</a>
                  { this.state.copy_item ? <a data-number={index} className={Style.Editor__insertCopy} onClick={this._pasteItem} >貼り付け</a> : null }
                </div>
              }
            </div>
            { this.state.sub_palette_index == index ? <Palette addItem={this._addItem} index={index} id={this.props.id} position='horizontal' /> : null }
          </li>
        ))}
      </ul>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      this.state.items ?
        <div className={Style.Editor}>
          <div className={Style.Editor__main}>
            <Palette addItem={this._addItem} id={this.props.id} position='horizontal' />
            { this.state.items.length <= 0 ? <div className='c-info u-fc-darkGray u-mt-30 u-mb-15'>※ 見出しやテキストなどを選択し、本文を作成してみましょう。</div> : null }
            { this.setItemNodes(this.state.items) }
          </div>
        </div>
        : null
    );
  }
}
