import React      from 'react'
import Modal from '../utilities/modal'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class CompanyWebsite extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      id: null,
      name: null,
      company_id: null,
      url: null,
      contact_url: null,
      status: 'yet',
      no_hp: false,
    }
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {

    // グローバルからの呼び出し
    window.openWebsite = id => {

      Request.get(`/users/company_websites/${id}`)
        .set('X-Requested-With', 'XMLHttpRequest')
        .withCredentials()
        .then(response => {

          this.setState({
            id: id,
            name: response.body.company.name,
            company_id: response.body.company.id,
            url: response.body.company_website.url,
            contact_url: response.body.company_website.contact_url,
            status: response.body.company_website.status,
            no_hp: response.body.company_website.no_hp,
          }, () => {
            this.refs.website_modal.open();
          });
        })
    };

    // グローバルからの呼び出し
    window.closeWebsite = () => {

      this.refs.website_modal.close();
    };
  }

  /**
   *  別ウィンドウで開く
   *  @version 2018/06/10
   */
  _openWindow = () => {

    if (this.refs.url.value && this.refs.url.value != '') {
      window.open(this.refs.url.value);
    }
  }

  /**
   *  別ウィンドウで開く
   *  @version 2018/06/10
   */
  _openWindow2 = () => {

    if (this.refs.contact_url.value && this.refs.contact_url.value != '') {
      window.open(this.refs.contact_url.value);
    }
  }

  /**
   *  URL変更
   *  @version 2018/06/10
   */
  onChangeUrl = () => {

    this.setState({url: this.refs.url});
  }

  /**
   *  ステータス変更
   *  @version 2018/06/10
   */
  onChangeStatus = e => {

    if (e.target.checked) {
      this.setState({no_hp: true, url: null, contact_url: null}, () => {
        this.refs.url.value = '';
      })
    } else {
      this.setState({no_hp: false});
    }
  }

  /**
   *  登録
   *  @version 2018/06/10
   */
  _onSubmit = () => {

    if (!this.state.no_hp) {
      if (!this.refs.url.value || this.refs.url.value == '') {
        window.alertable({type: 'warning', message: 'URLの入力は必須です'});
        return false;
      }
  
      if (!this.refs.url.value.match(/^https?:\/\//)) {
        window.alertable({type: 'warning', message: 'URLが不正です'});
        return false;
      }
    }

    let field = {
      'company_website[company_id]': this.state.company_id,
      'company_website[url]': this.refs.url.value,
      'company_website[status]': this.state.status,
      'company_website[no_hp]': this.state.no_hp,
    };

    if (this.refs.contact_url && this.refs.contact_url.value != '') {
      if (!this.refs.contact_url.value.match(/^https?:\/\//)) {
        window.alertable({type: 'warning', message: '問い合わせフォームのURLが不正です'});
        return false;
      }
      field['company_website[contact_url]'] = this.refs.contact_url.value;
    }

    if (this.state.id) {
      field['id'] = this.state.id;
    }

    // URL登録
    Request.post(`/users/company_websites`)
      .field(field)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {

          const company_website = response.body.company_website;
          this.setState({url: company_website.url, id: company_website.id, company_id: company_website.company_id, status: company_website.status}, () => {
            this.refs.website_modal.close();
          });
        } else {
          window.alertable({ type: 'error', message: response.body.message });
        }
      });
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    return (
      <div>
        <p className='u-fs-large u-mt-30'>
          <span className='u-fw-bold'>{this.state.name}</span><br />のホームページURL
          { this.state.status == 'correct' ? <span className='c-form__complete u-ml-10'>確定</span> : null }
        </p>
        <div className={Style.CompanyWebsite}>
          <div className={Style.CompanyWebsite__inner}>
            <div className='u-display-flex'>
              <div style={{width: '100%'}}>
                <input type='text' placeholder='https://masouken.com' defaultValue={this.state.url} ref='url' onChange={this.onChangeUrl} className='c-form-text'/>
              </div>
              <div className='c-btnMain-standard c-btnSmall u-ml-10 u-fs-large' onClick={this._openWindow}><span className='u-va-middle'>→</span></div>
            </div>
            <div className='u-mt-10 u-ta-right'>
              <label className='c-form-checkboxLabel'>
                <input type='checkbox' checked={this.state.no_hp} value='true' onChange={this.onChangeStatus} />
                <span className='c-form-label u-ml-5'>ホームページが存在しない</span>
              </label>
            </div>
            { this.state.url && this.state.url != '' ?
              <div className='u-mt-20'>
                <p className='u-fs-large'>
                  問い合わせフォームのURL
                  <label className='u-fs-small u-ml-10'>任意</label>
                </p>
                <div className='u-display-flex'>
                  <div style={{width: '100%'}}>
                    <input type='text' placeholder='https://masouken.com/contacts' defaultValue={this.state.contact_url} ref='contact_url' className='c-form-text'/>
                  </div>
                  <div className='c-btnMain-standard c-btnSmall u-ml-10 u-fs-large' onClick={this._openWindow2}><span className='u-va-middle'>→</span></div>
                </div>
              </div>
              : null
            }
            <div className='u-mt-20'>
              <div className='c-btnMain-primary u-display-block' onClick={this._onSubmit}>更新</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <Modal ref='website_modal' yield={this.content()}/>
    );
  }
}
