import React from 'react'
import Style from './style.sass'

import Hashtags   from './hashtags/index'

/**
 *  @version 2018/06/10
 */
export default class MoviesHashtag extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = { 
      show: false,
      hashtags: this.props.hashtags || [],
    }
  }

  /**
   *  ハッシュタグモーダルを表示する
   *  @version 2018/06/10
   */
  _open = () => {

    this.setState({ show: true }, () => document.body.style.overflow = 'hidden');
  }

  /**
   *  ハッシュタグモーダルを閉じる
   *  @version 2018/06/10
   */
  _close = () => {

    this.setState({ show: false }, () => document.body.style.overflow = 'auto');
  }

  /**
   *  ハッシュタグをセットする
   *  @version 2018/06/10
   */
  setHashtag = hashtags => {

    this.setState({hashtags: hashtags})
  }

  /**
   *  ハッシュタグを除去する
   *  @version 2018/06/10
   */
  _removeTag = e => {

    let hashtags = this.state.hashtags.map(hashtag => {
      if (hashtag.id == e.target.dataset.id) hashtag._destroy = true;
      return hashtag;
    });
    this.setState({hashtags: hashtags})
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render () {

    return (
      <div>
        { this.state.show ?
          <div className='c-modal-window' onMouseDown={this._close}>
            <Hashtags setHashtag={this.setHashtag} hashtags={this.state.hashtags} close={this._close}/>
          </div>
          : null
        }
        <ul className={Style.MoviesHashtag}>

          { this.state.hashtags.length > 0 ?
            this.state.hashtags.map((hashtag, i) => {
              const key = `hashtag_${i}`;
              if (hashtag._destroy) return;
              return (
                <li {...{key}} className={Style.MoviesHashtags__hashtag}>
                  <a href={`/users/movies?hashtags[]=${hashtag.name}`} target='_top'>{hashtag.name} ({hashtag.movies_count})</a>
                  <span className='u-ml-5 u-fc-lightGray' onClick={this._removeTag} data-id={hashtag.id} style={{cursor: 'pointer'}}>
                    x
                  </span>
                </li>
              );
            })
            :
            <div className='u-fc-lightGray'>ハッシュタグは紐づいていません</div>
          }
        </ul>
        <div className='u-td-underline u-mt-15 u-fs-small'><span onClick={this._open} style={{cursor: 'pointer'}}>＋関連ハッシュタグを追加</span></div>

        { this.state.hashtags.map((hashtag, i) => {
          const key = `ta_${i}`;
          let movies_hashtags = this.props.movies_hashtags.filter(movies_tag => movies_tag.hashtag_id == hashtag.id);
          return (
            <div {...{key}}>
              { movies_hashtags.length >= 1 ?
                <input type='hidden' value={movies_hashtags[0].id} name={`movie[movies_hashtags_attributes][${i}][id]`} />
                : null
              }
              <input type='hidden' value={hashtag.id} name={`movie[movies_hashtags_attributes][${i}][hashtag_id]`} />
              <input type='hidden' value={hashtag._destroy || false} name={`movie[movies_hashtags_attributes][${i}][_destroy]`} />
            </div>
          )
        })}
      </div>
    );
  }
}
