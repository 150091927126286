import React      from 'react'
import Style from './style.sass'

import RuleTree from './rule_tree'
import RuleFrame from './rule_frame'

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class Rule extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor (props) {

    super(props);
  }

  /**
   * マウント直後
   */
  componentDidMount() {}

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className='u-display-flex'>
        <div className={Style.RuleTree}>
          <RuleTree
            rule_manage={this.props.rule_manage}
            rule_directories={this.props.rule_directories}
            rules={this.props.rules}
          />
        </div>
        <div className={Style.RuleFrame}>
          <RuleFrame
            rule_id={this.props.rule_id}
            keyword={this.props.keyword}
          />
        </div>
      </div>
    );
  }
}
