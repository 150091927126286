import React from 'react'
import Style from './style.sass'
import Icon  from 'users/icon'

import { STATE_EDDITING, STATE_PREVIEW } from '../../properties.es6'

/**
 *  @version 2018/06/10
 */
export default class Template extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    // アイテム情報取得用functionを追加
    props.item.reference = () => this.reference();

    const template_state = {item: props.item};

    this.state = Object.assign(template_state, this.defaultState(props));
  }

  /**
   *  アイテム情報取得用(オーバーライド必須)
   *  @version 2018/06/10
   */
  reference = () => {

    return null;
  }

  /**
   *  タイトル
   *  @version 2018/06/10
   */
  templateComponentTitle() {

    return '';
  }

  /**
   *  初期化時のstate(オーバーライド必須)
   *  @version 2018/06/10
   */
  defaultState(props) {

    return {};
  }

  /**
   *  プレビュー時のView(オーバーライド必須)
   *  @version 2018/06/10
   */
  templatePreviewContent() {

    return null;
  }

  /**
   *  編集時時のView(オーバーライド必須)
   *  @version 2018/06/10
   */
  templateEdittingContent() {

    return null;
  }

  /**
   *  アイテムの追加(オーバーライド必須)
   *  @version 2018/06/10
   */
  add = () => {

    this._updateItem({});
  }

  /**
   *  アイテムの追加
   *  @version 2018/06/10
   */
  _updateItem(item_properties) {

    let item = this.state.item;
    // apply state
    item.status = STATE_PREVIEW;
    item.value = item_properties;

    // call parent method
    this.props.updateItem(this.props.index, item);
  }

  /**
   *  アイテムの削除
   *  @version 2018/06/10
   */
  remove = () => {

    this.props.removeItem(this.props.index);
  }

  /**
   *  編集ボタンクリック時
   *  @version 2018/06/10
   */
  _onClickEdit = () => {

    let item = this.state.item;
    item.status = STATE_EDDITING;
    this.setState({item: item}, () => {

      window.location.hash = this.props.index !== undefined ? this.props.index+1 : items.length;
    });
  }

  /**
   *  コピー
   *  @version 2018/06/10
   */
  _onClickCopy = e => {

    e.stopPropagation();

    let item = this.state.item;
    this.props.copyItem(item);
  }

  /**
   *  編集時表示
   *  @version 2018/06/10
   */
  renderEditting() {

    return (
      <div className={Style.Template}>
        <h2 className={Style.Template__title}>{ this.templateComponentTitle() }</h2>

        { this.templateEdittingContent() }

        <div className={Style.Template__actions}>
          <button onClick={this.add} className='c-btnMain-standard'>{ Object.keys(this.props.item.value).length ? 'ＯＫ' : '追加' }</button>
          <div onClick={this.remove} className='c-btnMain-negative u-ml-10'>{ Object.keys(this.props.item.value).length ? '削除' : 'キャンセル' }</div>
        </div>
      </div>
    );
  }

  /**
   *  プレビュー表示
   *  @version 2018/06/10
   */
  renderPreview() {

    return (
      <div className={Style.Viewer} onClick={this._onClickEdit}>

        { this.templatePreviewContent() }

        <div className={Style.Viewer__actions}>編集</div>
        <div className={Style.Viewer__copy} onClick={this._onClickCopy}>コピー<Icon name='copy' size='m' color='darkGray' className='u-ml-5'/></div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return this.props.item.status == STATE_EDDITING ? this.renderEditting() : this.renderPreview();
  }
}
