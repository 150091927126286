import React from 'react'
import Modal from '../utilities/modal'
import Style from './style.sass'
import Icon  from '../icon'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class GroupCompanyMailer extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      is_changed: false,
    }
  }

  /**
   *  submit処理
   *  @version 2018/06/10
   */
  _onSubmit = e => {

    if (this.refs.email && this.refs.email.value == '') {
      window.alertable({ type: 'warning', message: 'メールアドレスを入力してください' });
      return false;
    }

    if (this.refs.subject && this.refs.subject.value == '') {
      window.alertable({ type: 'warning', message: '送信内容を入力してください' });
      return false;
    }

    if (this.refs.body && this.refs.body.value == '') {
      window.alertable({ type: 'warning', message: '送信内容を入力してください' });
      return false;
    }

    if (!this.state.is_changed) {
      window.alertable({ type: 'warning', message: '送信内容が変更されていません。会社名や代表者名を確認してください。' });
      return false;
    }

    if (!window.confirm('この内容で送信してもよろしいですか? ※ 一度送信すると取り消すことはできません。')) {
      return false;
    }

    Request.post(`/users/group_companies/${this.props.id}/send_mail`)
      .field({
        email: this.refs.email.value,
        subject: this.refs.subject.value,
        body: this.refs.body.value,
        company_id: this.props.company.id,
        user_id: this.props.user_id,
      })
      .set('X-Requested-With', 'XMLHttpRequest')
      .withCredentials()
      .then(response => {

        window.alertable({ type: 'success', message: 'メールを送信しました' });
        document.getElementById('status_' + this.props.id).value = 'contacted';

        this.refs.mailer_modal.close();
      }).catch(error => {
        window.alertable({ type: 'error', message: `メールの送信に失敗しました: ${error.message}` });
        this.refs.mailer_modal.close();
      });
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = () => {

    this.refs.mailer_modal.open();
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    return (
      <div className={Style.Mailer}>
        <p className='u-fs-large u-mt-30'>
          【{this.props.company.name}】{this.props.president ? 'の代表者' : null}へのメール送信
        </p>
        <div className='u-mt-15'>
          <div className='c-form-label'>
            <label className='u-fs-small'>メールアドレス(宛先)
              <span className='u-fc-red'>※送信先のアドレスを入力してください</span>
            </label>
          </div>
          <input type='text' defaultValue={this.props.president ? this.props.president.email : null} ref='email' className='c-form-text u-mt-5' placeholder='メールアドレスを入力してください' style={{border: 'solid 1px #aaa'}} />
        </div>

        <div className='u-mt-5'>
          <div className='c-form-label'>
            <label className='u-fs-small'>件名</label>
          </div>
          <input type='text' defaultValue={this.props.subject} ref='subject' className='c-form-text u-mt-5' placeholder='件名を入力してください' style={{border: 'solid 1px #aaa'}} />
        </div>

        { this.props.president ?
          <div className='u-display-flex u-mt-15'>
            <div className='c-attention u-py-5' style={{width: '50%'}}>{this.props.company.name}<span className='u-ml-5 c-btnSub-standard c-btnSmall u-fs-small' onClick={() => copyToClipboard(`${this.props.company.name}`)}>コピー</span></div>
            <div className='c-attention u-py-5 u-ml-10' style={{width: '50%'}}>{this.props.president.name}<span className='u-ml-5 c-btnSub-standard c-btnSmall u-fs-small' onClick={() => copyToClipboard(`${this.props.president.name}`)}>コピー</span></div>
          </div>
          :
          <div className='u-display-flex u-mt-15'>
            <div className='c-attention u-py-5' style={{width: '50%'}}>{this.props.company.name}<span className='u-ml-5 c-btnSub-standard c-btnSmall u-fs-small' onClick={() => copyToClipboard(`${this.props.company.name}`)}>コピー</span></div>
            { this.props.president_name ?
              <div className='c-attention u-py-5 u-ml-10' style={{width: '50%'}}>{this.props.president_name}<span className='u-ml-5 c-btnSub-standard c-btnSmall u-fs-small' onClick={() => copyToClipboard(`${this.props.president_name}`)}>コピー</span></div>
              : null
            }
          </div>
        }

        <div className='u-mt-5'>
          <div className='c-form-label'>
            <label className='u-fs-small'>送信内容</label>
          </div>
          <textarea ref='body' defaultValue={this.props.body} placeholder='送信内容を入力してください' rows='10' className='c-form-textarea u-mt-5' autoComplete='off' onChange={() => !this.state.is_changed && this.setState({is_changed: true})} />
        </div>

        <div className='c-attention u-mt-10 u-fc-red'>
          送信先、件名、文章等送信して問題ないかを必ず確認してから送信してください。
        </div>

        <div className='u-ta-left u-mt-30'>
          <div className='c-btnMain-standard u-display-block' onClick={this._onSubmit}>送信する</div>
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        <span onClick={this.open} className={this.props.president ? 'c-btnSub-primary' : 'c-btnSub-standard'} style={{lineHeight: '1.5', padding: '0 5px'}}>
          <Icon name='email' size='s' color='darkGray'/>
        </span>
        <Modal ref='mailer_modal' yield={this.content()}/>
      </div>
    );
  }
}
