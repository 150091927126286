import React      from 'react'
import Modal from '../utilities/modal'

import DatePicker    from '../date_picker'
import Utilities from '../../scripts/utilities'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

import {APPROVAL_NAMES} from './properties.es6'

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class EasyNda extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = {
      contracted_at: null,
      show_detail: true,
      show_postable: false,
      show_cloudsignable: false,
    };
  }

  /**
   *  submit処理
   *  @version 2018/06/10
   */
  _onSubmit = e => {

    if (!this.state.contracted_at) {
      window.alertable({ type: 'warning', message: '締結予定日は必須です' });
      return false;
    }

    if (!window.confirm('入力いただいた内容でPDFを作成し、稟議を申請しますか?')) return false;

    window.startLoading();

    let promises = [];

    const field = {
      'type': this.props.type,
      'company_id': this.props.company_id,
      'memo': this.refs.memo.value,
      'approval_name': this.refs.approval_name.value,
    };

    promises.push(

      new Promise((resolve, reject) => {

        // NDA-PDF取得
        Request.get(this.preview_url())
          .set('X-Requested-With', 'XMLHttpRequest')
          .responseType('blob')
          .end((error, response) => {

            resolve({data: new Blob([response.body], { type: 'application/pdf' })});
          });
      })
    );

    Promise.all(promises).then(results => {

      if (this.props.type == 'nda_self') {
        field['company_name'] = this.refs.company_name.value;
      }

      // 郵送
      field['postable'] = this.state.show_postable;

      if (this.state.show_postable) {
        field['postal_code'] = this.refs.approval_postal_code.value;
        field['address'] = this.refs.approval_address.value;
        field['tel'] = this.refs.approval_tel.value;
        field['company_name'] = this.refs.approval_company_name.value;
        field['position'] = this.refs.approval_position.value;
        field['president_name'] = this.refs.approval_president_name.value;
        field['for_reply'] = this.refs.for_reply.checked;
        field['hidden_company_name'] = this.refs.hidden_company_name.checked;
        field['name_card'] = this.refs.name_card.checked;
        field['copies'] = this.refs.copies.value;
        field['postable_memo'] = this.refs.postable_memo.value;
      }

      // 電子契約
      field['cloudsignable'] = this.state.show_cloudsignable;

      if (this.state.show_cloudsignable) {
        field['company_name'] = this.refs.approval_company_name.value;
        field['president_name'] = this.refs.approval_president_name.value;
        field['president_email'] = this.refs.approval_president_email.value;
      }
  
      if (this.state.contracted_at) {
        field['contracted_at'] = this.state.contracted_at;
      }

      // 稟議申請
      Request.post(`/users/companies/${this.props.company_id}/easy_nda`)
        .field(field)
        .attach('pdf', results[0].data)
        .set('X-Requested-With', 'XMLHttpRequest')
        .setCsrfToken()
        .end((error, response) => {
  
          if (response.body.status == 'success') {
            
            window.closeLoading();
            window.alertable({ type: 'success', message: `${this.props.company_name}に対するNDA締結の稟議を申請しました。`, close_callback: () => {
              window.location.href = `/users/user_approvals/${response.body.user_approval_id}`;
            }});
          } else {
            
            window.closeLoading();
            window.alertable({ type: 'error', message: response.body.message });
          }
        });
    });
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = () => {
    this.refs.nda_modal.open();
  }

  // 締結日
  setContractedAt = datetime => {
    this.setState({contracted_at: datetime.datetime})
  }

  /**
   *  プレビュー
   *  @version 2018/06/10
   */
  preview_url = () => {

    let params = '?';

    if (this.state.contracted_at) {
      params += `contracted_at=${this.state.contracted_at}`;
    }

    if (this.props.type == 'nda_self') {
      if (this.state.contracted_at) params += '&';
      params += `company_name=${encodeURIComponent(this.refs.company_name.value)}`;
    }

    if (this.props.type == 'nda' && this.state.show_detail) {
      if (this.state.contracted_at) params += '&';
      params += [
        `company_name=${encodeURIComponent(this.refs.company_name.value)}`,
        `position=${this.refs.position.value}`,
        `president_name=${this.refs.president_name.value}`,
        `address=${this.refs.address.value}`,
      ].join('&');
    }

    if (this.props.type == 'nda_old' && this.state.show_detail) {
      if (this.state.contracted_at) params += '&';
      params += [
        `company_name=${encodeURIComponent(this.refs.company_name.value)}`,
        `position=${this.refs.position.value}`,
        `president_name=${this.refs.president_name.value}`,
        `address=${this.refs.address.value}`,
      ].join('&');
    }

    return `/users/companies/${this.props.company_id}/${this.props.type}` + params;
  }

  /**
   *  プレビュー
   *  @version 2018/06/10
   */
  preview = () => {

    if (!this.state.contracted_at) {
      window.alertable({ type: 'warning', message: '締結予定日は必須です' });
      return false;
    }

    window.open(this.preview_url());
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    return (
      <div className={Style.EasyNda}>
        <p className='u-fs-large'>かんたん稟議申請</p>
        <div className='u-mt-10'>
          {this.props.company_name}とのNDA締結のための稟議を申請します。<br />契約書は入力情報を元にPDFとして生成されます。
        </div>

        <div className='u-display-flex u-mt-15'>
          <div style={{width: '100%'}}>
            <div className='c-form-label u-ta-left u-mt-5'>
              <label>稟議</label>
            </div>
            <div style={{border: '1px solid #999'}}>
              <input type='text' ref='approval_name' defaultValue={APPROVAL_NAMES[this.props.type]} className='c-form-text' readOnly={true} />
            </div>
          </div>

          <div className='u-ml-15' style={{width: '100%'}}>
            <div className='c-form-label u-ta-left u-mt-5'>
              <label>締結予定日 <span className='u-fc-red u-fs-small'>必須</span></label>
            </div>
            <div style={{border: '1px solid #999'}}>
              <DatePicker type='text' apply={this.setContractedAt} />
            </div>
          </div>
        </div>

        <div className='c-form-label u-ta-left u-mt-5'>
          <label>備考</label>
        </div>
        <div style={{border: '1px solid #999'}}>
          <input type='text' defaultValue={''} ref='memo' className='c-form-text' placeholder='締結日が過去の場合は入力必須です。' />
        </div>

        { this.props.type == 'nda' || this.props.type == 'nda_old' ?
          <div className='u-ta-left u-mt-10'>
            <label className='c-form-checkboxLabel'>
              <input className='c-form-checkbox' type='checkbox' checked={this.state.show_detail} onChange={() => { this.setState({show_detail: !this.state.show_detail}) }} />
              <i className='c-form-checkboxIcon'></i>
              <span className='c-form-label u-ta-left u-ml-5'>NDAに会社情報を印字する</span>
            </label>
          </div>
          :
          null
        }

        { (this.props.type == 'nda' || this.props.type == 'nda_old') && this.state.show_detail ?
          <div className='c-attention u-mt-10'>
            <div className='u-display-flex'>

              <div style={{width: '50%'}}>
                <div className='c-form-label u-ta-left'>
                  <label>会社名 <span className='u-fc-red u-fs-small'>必須</span></label>
                </div>
                <div style={{border: '1px solid #999'}}>
                  <input type='text' defaultValue={this.props.company_name} className='c-form-text' placeholder='M&A総合研究所' ref='company_name'/>
                </div>
              </div>

              <div className='u-ml-15' style={{width: '50%'}}>
                <div className='c-form-label u-ta-left'>
                  <label>会社住所</label>
                </div>
                <div style={{border: '1px solid #999'}}>
                  <input type='text' defaultValue={this.props.address} className='c-form-text' placeholder='東京都千代田区丸の内1-8-1 丸の内トラストタワーN館 12F' ref='address'/>
                </div>
              </div>
            </div>

            <div className='u-display-flex'>
              <div style={{flex: '1'}}>
                <div className='c-form-label u-ta-left'>
                  <label>役職</label>
                </div>
                <div style={{border: '1px solid #999'}}>
                  <input type='text' defaultValue='' className='c-form-text' placeholder='代表取締役社長' ref='position'/>
                </div>
              </div>
              <div className='u-ml-15' style={{flex: '1'}}>
                <div className='c-form-label u-ta-left'>
                  <label>代表者名</label>
                </div>
                <div style={{border: '1px solid #999'}}>
                  <input type='text' defaultValue={this.props.president_name} className='c-form-text' placeholder='総研太郎' ref='president_name'/>
                </div>
              </div>
            </div>
          </div>
          :
          null
        }

        { this.props.type == 'nda_self' ?
          <div>
            <div className='c-form-label u-ta-left u-mt-5'>
              <label>会社名 <span className='u-fc-red u-fs-small'>必須</span></label>
            </div>
            <div style={{border: '1px solid #999'}}>
              <input type='text' defaultValue={this.props.company_name} className='c-form-text' placeholder='会社名を入力してください' ref='company_name'/>
            </div>
          </div>
          :
          null
        }

        <div className='u-ta-left u-mt-10'>
          <label className='c-form-checkboxLabel'>
            <input className='c-form-checkbox' type='checkbox' checked={this.state.show_postable} onChange={() => { this.setState({show_postable: !this.state.show_postable}) }} />
            <i className='c-form-checkboxIcon'></i>
            <span className='c-form-label u-ta-left u-ml-5'>稟議承認後に郵送を希望する</span>
          </label>

          <label className='c-form-checkboxLabel u-ml-30'>
            <input className='c-form-checkbox' type='checkbox' checked={this.state.show_cloudsignable} onChange={() => { this.setState({show_cloudsignable: !this.state.show_cloudsignable}) }} />
            <i className='c-form-checkboxIcon'></i>
            <span className='c-form-label u-ta-left u-ml-5'>電子契約での締結を希望する</span>
          </label>
        </div>

        { this.state.show_postable ?
          <div className='c-attention u-mt-10'>
            <div className='u-display-flex'>
              <div style={{width: '15%'}}>
                <div className='c-form-label u-ta-left'>
                  <label>郵便番号</label>
                </div>
                <div style={{border: '1px solid #999'}}>
                  <input type='text' defaultValue={this.props.postal_code} className='c-form-text' placeholder='100-0005' ref='approval_postal_code'/>
                </div>
              </div>

              <div className='u-ml-15' style={{width: '35%'}}>
                <div className='c-form-label u-ta-left u-mt-5'>
                  <label>送付先住所</label>
                </div>
                <div style={{border: '1px solid #999'}}>
                  <input type='text' defaultValue={this.props.address} className='c-form-text' placeholder='東京都千代田区丸の内1-8-1 丸の内トラストタワーN館 12F' ref='approval_address'/>
                </div>
              </div>

              <div className='u-ml-15' style={{width: '20%'}}>
                <div className='c-form-label u-ta-left'>
                  <label>電話番号</label>
                </div>
                <div style={{border: '1px solid #999'}}>
                  <input type='text' defaultValue={this.props.tel} className='c-form-text' placeholder='03-1234-5678' ref='approval_tel'/>
                </div>
              </div>
            </div>

            <div className='u-display-flex'>
              <div style={{width: '35%'}}>
                <div className='c-form-label u-ta-left u-mt-5'>
                  <label>会社名</label>
                </div>
                <div style={{border: '1px solid #999'}}>
                  <input type='text' defaultValue={this.props.company_name} className='c-form-text' placeholder='M&A総合研究所' ref='approval_company_name'/>
                </div>
              </div>
              <div className='u-ml-15' style={{width: '35%'}}>
                <div className='c-form-label u-ta-left u-mt-5'>
                  <label>役職</label>
                </div>
                <div style={{border: '1px solid #999'}}>
                  <input type='text' defaultValue='' className='c-form-text' placeholder='代表取締役社長' ref='approval_position'/>
                </div>
              </div>
              <div className='u-ml-15' style={{width: '35%'}}>
                <div className='c-form-label u-ta-left u-mt-5'>
                  <label>氏名</label>
                </div>
                <div style={{border: '1px solid #999'}}>
                  <input type='text' defaultValue={this.props.president_name} className='c-form-text' placeholder='総研太郎' ref='approval_president_name'/>
                </div>
              </div>
            </div>

            <div className='u-display-flex u-mt-10'>
              <div className='u-ta-left'>
                <label className='c-form-checkboxLabel'>
                  <input className='c-form-checkbox' type='checkbox' defaultChecked={true} ref='for_reply' />
                  <i className='c-form-checkboxIcon'></i>
                  <span className='c-form-label u-ta-left u-ml-5'>返信用封筒を添付する</span>
                </label>
              </div>
              <div className='u-ta-left u-ml-15'>
                <label className='c-form-checkboxLabel'>
                  <input className='c-form-checkbox' type='checkbox' defaultChecked={true} ref='hidden_company_name' />
                  <i className='c-form-checkboxIcon'></i>
                  <span className='c-form-label u-ta-left u-ml-5'>総研社名を伏せる</span>
                </label>
              </div>
              <div className='u-ta-left u-ml-15'>
                <label className='c-form-checkboxLabel'>
                  <input className='c-form-checkbox' type='checkbox' defaultChecked={true} ref='name_card' />
                  <i className='c-form-checkboxIcon'></i>
                  <span className='c-form-label u-ta-left u-ml-5'>名刺を同封する</span>
                </label>
              </div>

              <div className='u-fw-bold u-mt-5 u-ml-20'>契約書部数:</div>
              <div className='c-form-selectWrap' style={{border: '1px solid #999'}}>
                <select className='c-form-select' defaultValue={this.props.default_copies || 2} ref='copies'>
                  { Utilities.range(1, 12).map(i => {
                    const key = 'copies-'+i;
                    return <option {...{key}} value={i}>{i}部</option>;
                  })}
                </select>
              </div>
            </div>

            <div>
              <div className='c-form-label u-ta-left u-mt-10'>
                <label>郵送に関する備考</label>
              </div>
              <textarea placeholder='郵送に関する備考があれば入力してください' class='c-form-textarea' rows='2' autocomplete='off' defaultValue={''} ref='postable_memo'></textarea>
            </div>

          </div>
          : null
        }

        { this.state.show_cloudsignable ?
          <div className='c-attention u-mt-10'>
            <div className='c-attention u-fs-small u-mt-15 u-ta-left'>
              <div className='c-attention__title'>
                ⚠️
                <span className='u-fc-red u-fw-bold'>注意</span>
              </div>
              個人との契約の場合は、会社名に「
              <span className='u-fw-bold'>個人</span>
               」と入力してください。
            </div>
            <div className='u-display-flex'>
              <div style={{width: '33%'}}>
                <div className='c-form-label u-ta-left u-mt-5'>
                  <label>会社名 <span className='u-fc-red u-fs-small'>必須</span></label>
                </div>
                <div style={{border: '1px solid #999'}}>
                  <input type='text' defaultValue={this.props.company_name} className='c-form-text' placeholder='M&A総合研究所' ref='approval_company_name'/>
                </div>
              </div>
              <div className='u-ml-15' style={{width: '33%'}}>
                <div className='c-form-label u-ta-left u-mt-5'>
                  <label>氏名 <span className='u-fc-red u-fs-small'>必須</span></label>
                </div>
                <div style={{border: '1px solid #999'}}>
                  <input type='text' defaultValue={this.props.president_name} className='c-form-text' placeholder='総研太郎' ref='approval_president_name'/>
                </div>
              </div>
              <div className='u-ml-15' style={{width: '33%'}}>
                <div className='c-form-label u-ta-left u-mt-5'>
                  <label>送付先メールアドレス <span className='u-fc-red u-fs-small'>必須</span></label>
                </div>
                <div style={{border: '1px solid #999'}}>
                  <input type='text' defaultValue='' className='c-form-text' placeholder='h.ogino@masouken.com' ref='approval_president_email'/>
                </div>
              </div>
            </div>
          </div>
          : null
        }

        <div className='u-ta-left u-mt-20'>
          <div className='c-btnMain-standard u-display-block' onClick={this.preview}>プレビュー</div>
        </div>
        <div className='u-ta-left u-mt-15'>
          <div className='c-btnMain-primary u-display-block' onClick={this._onSubmit}>稟議申請する</div>
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        <span onClick={this.open} className='c-btnMain-attention' style={{lineHeight: '2rem'}}>かんたん稟議申請{this.props.type == 'nda_old' ? '(2023年5月14日以前)' : null}</span>
        <Modal ref='nda_modal' yield={this.content()} />
      </div>
    );
  }
}
