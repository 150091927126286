//##############################################################################
// Initializer
//##############################################################################

/**
 *  初期化クラス
 *  @version 2018/06/10
 */
window.Initializer = window.Initializer||{};

(function(window, document, undefined) {
  
  'use strict';

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  function Initializer() {}

  var p = Initializer.prototype;

  /**
   *  webfontをダウンロード
   *  @version 2018/06/10
   */
  p.loadWebfont = function() {
    
    document.getElementById('js-webfont').rel = 'stylesheet';
  };

  /**
   *  navigationメニューをactive化
   *  @version 2018/06/10
   */
  p.navigation = function() {

    this.activationNavigation();
  }

  /**
   *  navigationメニューをactive化
   *  @version 2018/06/10
   */
  p.activationNavigation = function() {

    const breadcrumb_root = document.getElementById('js-breadcrumb-root');

    document.querySelectorAll('.l-navigation__link').forEach(element => {

      // TOPタブのアクティブ化
      if (!breadcrumb_root && element.getAttribute('href') == '/') {

        element.classList.add('is-active');

      // TOPページ以外のタブのアクティブ化
      } else if (breadcrumb_root && breadcrumb_root.textContent == element.textContent) {

        element.classList.add('is-active');

      // 非アクティブ化
      } else {

        element.classList.remove('is-active');
      }
    });
  }

  /**
   *  右drawerを開始
   *  @version 2018/06/10
   */
  p.drawerRight = function() {

    document.querySelectorAll('.js-drawer-right-open').forEach(trigger => trigger.onclick = () => {

      document.body.dataset.drawerRightOpen = document.body.dataset.drawerRightOpen == 'true' ? 'false' : 'true';
    });
  }

  window.Initializer = new Initializer;
})(window, document);
