import React      from 'react'
import Style from './style.sass'
import Icon  from 'users/icon'
import { Device } from '@twilio/voice-sdk'

import TelDetail    from '../tel_detail'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

import { BROADCAST_CHANNEL_NAME, NUMBER_TYPES } from './properties'

/**
 *  記事エディター
 *  @version 2018/06/10
 * twilio_account_sid: AC49b2f6390a4ff916c096234f9e285514
 * twilio_auth_token: 3ef6992006d989f57b31760a1c888602
 * twilio_phone_number: +12565883797
 */
export default class Twilio extends React.Component {

  constructor(props) {

    super(props)

    this.uuid = this.uuidv4();

    this.device = new Device(this.props.access_token);

    this.addDeviceListeners(this.device);

    this.number_type = this.props.number_type || 'masouken';

    this.number = this.props.numbers[Math.floor(Math.random() * this.props.numbers.length)];

    this.state = {
      disabled: false,
      show: false,
      show_dial: false,
      stacks: [],
      company_tel_id: null,
      group_company_id: null,
      company_company_id: null,
      company_id: null,
      company_name: null,
      name: null,
      number: '',
      is_busy: false,
      display: '',
      call: null,
      connecting_number: '',
      mute: false,
      show_tel_detail: false,
    }
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {

    // 既存のTwilioの存在確認
    this.setBroadcast();

    // 表示位置
    this.setPosition();

    // グローバルからの呼び出し
    window.openTwilio = (company_id, company_name, name, number, group_company_id, company_company_id) => {

      if (!this.checkAvailable()) return false;

      console.log('company_id', company_id);
      console.log('company_name', company_name);
      console.log('name', name);
      console.log('number', number);
      console.log('group_company_id', group_company_id);
      console.log('company_company_id', company_company_id);

      this.setPosition();

      // 通話中は番号をスタックする
      if (this.state.is_busy) {
        let stacks = this.state.stacks.slice();
        stacks.push({
          company_id: company_id,
          number: number,
          company_name: company_name,
          name: name,
          group_company_id: group_company_id,
          company_company_id: company_company_id
        });
        this.setState({stacks: stacks});
      } else {

        this.setState({
          show: true,
          company_id: company_id,
          company_name: company_name,
          name: name,
          number: number,
          group_company_id: group_company_id,
          company_company_id: company_company_id,
        }, this.startCall);
      }
    };

    // グローバルからの呼び出し
    window.closeTwilio = () => {

      this.close();
    };

    document.querySelectorAll('a[href^="tel:"]').forEach( r => {
      r.addEventListener('click', e => {
        e.preventDefault();

        let number = e.target.href.replace('tel:', '');
        let company_id = e.target.dataset.company_id;
        let company_name = e.target.dataset.company_name;
        let name = e.target.dataset.name;
        let group_company_id = e.target.dataset.group_company_id;
        let company_company_id = e.target.dataset.company_company_id;

        if (number && number != '' && company_id && company_id != '' && company_name && company_name != '') {
          window.openTwilio(company_id, company_name, name, number, group_company_id, company_company_id);
        }
      })
    });
  }

  // 既存のTwilioの存在確認
  setBroadcast = () => {

    this.broadcast = new BroadcastChannel(`${BROADCAST_CHANNEL_NAME}_${this.props.user_id}`);

    this.broadcast.onmessage = e => {
      const message = JSON.parse(e.data);
      
      // Twilio開始時のメッセージを受信
      if (message.action == 'open' && message.uuid != this.uuid && !this.state.disabled) {
        
        // 自身が起動中の場合はクローズ命令を返却
        this.broadcast.postMessage(JSON.stringify({
          action: 'disabled',
          uuid: this.uuid,
          target_uuid: message.uuid,
        }));
      }
      // Twilioをクローズ命令のメッセージを受信
      if (message.action == 'disabled' && message.uuid != this.uuid && message.target_uuid == this.uuid) {
        this.setDisabled();
      }
    }

    // 開始時のメッセージを送信
    this.checkExistsByBroadcast();
  }

  // 既存のTwilioの存在確認
  checkExistsByBroadcast = () => {

    this.device.register();

    this.setState({disabled: false}, () => {
      // 開始時のメッセージを送信
      this.broadcast.postMessage(JSON.stringify({
        action: 'open',
        uuid: this.uuid,
      }));
    });
  }

  // ユニークIDを生成
  uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  close = () => {

    this.setState({show: false});
  }

  setDisabled = () => {

    this.setState({disabled: true}, () => {
      this.device.unregister();
    });
  }

  addDeviceListeners = _device => {

    _device.on('destroyed', conn => {
      console.log('destroyed conn', conn);
    });

    _device.on('error', conn => {
      console.log('error conn', conn);
      window.alertable({ type: 'error', message: '電話機能にエラーが生じたため、ブラウザをリロードして再度お試しください。' });
    });

    _device.on('incoming', conn => {
      console.log('incoming conn', conn);
    });

    _device.on('registered', conn => {
      console.log('registered conn', conn);
    });

    _device.on('registering', conn => {
      //console.log('registering conn', conn);
      if (!conn) {
        window.alertable({ type: 'warning', message: '電話機能を確立することができませんでした。ブラウザをリロードして再度お試しください。' });
      }
    });

    _device.on('tokenWillExpire', conn => {
      console.log('tokenWillExpire conn', conn);
    });

    _device.on('unregistered', conn => {
      console.log('unregistered conn', conn);
    });
  }

  /**
   *  ボタン押下
   *  @version 2018/06/10
   */
  onPushNumber = _number => {

    if (this.device.isBusy) {
      this.setState({number: this.state.number + _number}, () => {
        this.state.call.sendDigits(_number);
      });
    } else {
      this.setState({number: this.state.number + _number});
    }
  }

  /**
   *  １文字削除
   *  @version 2018/06/10
   */
  onPullNumber = () => {

    if (this.state.number != '') this.setState({number: this.state.number.slice(0, -1)})
  }

  /**
   *  手入力
   *  @version 2018/06/10
   */
  onChangeNumberDisplay = e => {

    this.setState({number: e.target.value})
  }

  /**
   *  ミュート
   *  @version 2018/06/10
   */
  onMute = () => {

    this.setState({mute: !this.state.mute}, () => {
      this.state.call.mute(!this.state.call.isMuted());
    });
  }

  /**
   *  電話をかける
   *  @version 2018/06/10
   */
  onCall = () => {

    if (!this.checkAvailable()) return false;

    this.setState({
      show_tel_detail: false,
      company_tel_id: null,
      group_company_id: null,
      company_company_id: null,
      company_id: null,
      company_name: null,
      name: null,
    }, () => {
      this.startCall();
    });
  }

  // 通話可能判定
  checkAvailable = () => {

    console.log('checkAvailable', this.state.disabled);

    if (this.state.disabled) {
      window.alertable({ type: 'warning', message: '別のタブで電話がすでに起動しているため電話をかけることができません。' });
      return false;
    }
    return true;
  }

  /**
   *  電話をかける
   *  @version 2018/06/10
   */
  async startCall() {

    console.log('startCall');

    // 電話番号が0から始まっていない場合
    if (this.state.number == '' || this.state.number[0] != '0') {
      window.alertable({ type: 'warning', message: '電話番号は0から始めてください' });
      return false;
    }
    // 通話中は何もしない
    if (this.state.call) return false;

    // 会社が指定されている場合
    if (this.state.company_id) {

      // 架電可否判定
      const response = await Request.get(`/users/company_tels/check_call?company_id=${this.state.company_id}`);
      
      if (response.body.status != 'success') {

        this.setState({
          is_busy: false,
          call: null,
          number: '',
        }, () => {

          if (this.state.stacks.length > 0) {

            window.confirmable({ type: 'warning', message: `${response.body.message}<br />次の会社に架電しますか？`, callback: () => {
              this.nextStack();
            }});
          } else {
            window.alertable({ type: 'warning', message: response.body.message});
          }
        });
        return;
      }
    }

    let call = await this.device.connect({
      params: {
        To: this.state.number.replace(/^0/, '+81'),
        From: this.number,
      },
    });

    call.on('accept', this.updateUIAcceptedOutgoingCall);
    call.on('disconnect', this.updateUIDisconnectedOutgoingCall);
    call.on('cancel', this.updateUIDisconnectedOutgoingCall);

    this.setState({
      call: call,
      display: '通話準備中',
      show_tel_detail: false,
    });
  }

  /**
   *  切電
   *  @version 2018/06/10
   */
  onCancelCall() {

    this.state.call.disconnect();
  }

  /**
   *  通話時のコールバック
   *  @version 2018/06/10
   */
  updateUIAcceptedOutgoingCall = call => {

    //console.log('Call in progress ...', call);

    // 会社が指定されている場合
    if (this.state.company_id) {

      let field = {
        'company_tel[company_id]': this.state.company_id,
        'company_tel[call_sid]': call['parameters']['CallSid'],
        'company_tel[to_number]': call['customParameters'].get('To') || '',
        'company_tel[from_number]': call['customParameters'].get('From') || '',
      };

      // 売手開拓リストIDが指定されている場合
      if (this.state.group_company_id) field['group_company_id'] = this.state.group_company_id;
      // 買手開拓リストIDが指定されている場合
      if (this.state.company_company_id) field['company_company_id'] = this.state.company_company_id;

      // 電話内容登録
      Request.post(`/users/company_tels`)
        .field(field)
        .set('X-Requested-With', 'XMLHttpRequest')
        .setCsrfToken()
        .end((error, response) => {

          if (response.body.status != 'success') {
            window.alertable({ type: 'error', message: response.body.message });
            return;
          }

          this.setState({company_tel_id: response.body.company_tel_id});
        });
      
      this.setState({is_busy: true, display: `通話中 ${this.state.company_name}`, connecting_number: this.state.company_name, number: '', mute: false});
    } else {

      this.setState({is_busy: true, display: `通話中 ${this.state.number}`, connecting_number: this.state.number, number: '', mute: false});
    }
  }

  /**
   *  切電時のコールバック
   *  @version 2018/06/10
   */
  updateUIDisconnectedOutgoingCall = call => {

    //console.log('Call disconnected.', call);

    // 会社IDが指定されている場合は電話結果を入力
    if (this.state.company_id) {

      this.setState({
        show_tel_detail: true,
        is_busy: false,
        display: `通話終了 ${this.state.connecting_number}`,
        call: null,
        number: '',
      });
    
    // 通話終了
    } else {

      this.setState({
        is_busy: false,
        display: `通話終了 ${this.state.connecting_number}`,
        call: null,
        number: '',
      }, () => {
  
        this.nextStack();
      });
    }
  }

  /**
   *  stackへコールする
   *  @version 2018/06/10
   */
  nextStack = () => {

    if (!this.checkAvailable()) return false;

    if (!this.state.is_busy && this.state.stacks.length > 0) {

      let stacks = this.state.stacks.slice();
      stacks.shift();

      this.setState({
        show: true,
        show_tel_detail: false,
        company_tel_id: null,
        company_id: this.state.stacks[0].company_id,
        company_name: this.state.stacks[0].company_name,
        name: null,
        number: this.state.stacks[0].number,
        group_company_id: this.state.stacks[0].group_company_id,
        company_company_id: this.state.stacks[0].company_company_id,
        stacks: stacks,
      }, this.startCall);
    } else {

      this.setState({
        show_tel_detail: false,
        company_tel_id: null,
        group_company_id: null,
        company_company_id: null,
        company_id: null,
        company_name: null,
        name: null,
      });
    }
  }

  /**
   *  stackを削除する
   *  @version 2018/06/10
   */
  deleteStack = e => {

    let stacks = this.state.stacks.slice();

    stacks = stacks.filter( stack => {
      return stack.number != e.target.dataset.number
    });

    this.setState({stacks: stacks});
  }

  setPosition = position => {

    const twilio = this.refs.twilio;
    const stacks = this.refs.stacks;

    const _position = position || localStorage.getItem('twilio_position') || 'bottom_right';
    localStorage.setItem('twilio_position', _position);

    if (_position == 'upper_right') {
      if (twilio) {
        twilio.style.left = 'auto';
        twilio.style.right = '15px';
        twilio.style.top = '30px';
        twilio.style.bottom = 'auto';
      }
      if (stacks) stacks.style.right = '300px';
    } else if (_position == 'bottom_right') {
      if (twilio) {
        twilio.style.left = 'auto';
        twilio.style.right = '15px';
        twilio.style.top = 'auto';
        twilio.style.bottom = '75px';
      }
      if (stacks) stacks.style.right = '300px';
    } else if (_position == 'bottom_left') {
      if (twilio) {
        twilio.style.left = '15px';
        twilio.style.right = 'auto';
        twilio.style.top = 'auto';
        twilio.style.bottom = '75px';
      }
      if (stacks) stacks.style.right = '-240px';
    } else if (_position == 'upper_left') {
      if (twilio) {
        twilio.style.left = '15px';
        twilio.style.right = 'auto';
        twilio.style.top = '30px';
        twilio.style.bottom = 'auto';
      }
      if (stacks) stacks.style.right = '-240px';
    }
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    if (this.state.disabled) {
      return (
        <div className={Style.Twilio}>
          <div className={Style.Twilio__disabled} >
            <Icon name='phone' size='s' color='black' /> 別タブで電話起動中 <span className='u-fs-small u-cursor-pointer u-fc-blue u-td-underline' onClick={this.checkExistsByBroadcast}>更新</span>
          </div>
        </div>
      );
    }

    return (
      <div className={Style.Twilio} ref='twilio'>
        { this.state.show ?
          <div>
            <div className={Style.Twilio__modal}>
              <div className={Style.Twilio__header}>
                <div className='u-display-inline-block u-ml-10' onClick={ e => this.setPosition('upper_right') && e.preventDefault() }>
                  <Icon name='grid' size='s' color='black' />
                </div>
                <div className='u-display-inline-block u-ml-15' onClick={ e => this.setPosition('bottom_right') && e.preventDefault() }>
                  <Icon name='grid' size='s' color='black' className='u-svg-rotate90' />
                </div>
                <div className='u-display-inline-block u-ml-15' onClick={ e => this.setPosition('bottom_left') && e.preventDefault() }>
                  <Icon name='grid' size='s' color='black' className='u-svg-rotate180' />
                </div>
                <div className='u-display-inline-block u-ml-15' onClick={ e => this.setPosition('upper_left') && e.preventDefault() }>
                  <Icon name='grid' size='s' color='black' className='u-svg-rotate270' />
                </div>
              </div>
              <div className={Style.Twilio__close} onClick={ e => this.close() && e.preventDefault() }>
                <Icon name='tilde-right' size='s' color='black' className='u-svg-rotate90' />
              </div>
              <ul className={Style.Twilio__stacks} ref='stacks'>
                { this.state.stacks.map(stack => {
                  return (
                    <li className={Style.Twilio__stack}>
                      <div>{stack.number}</div><div>{stack.company_name}</div>
                      <div className={Style.Twilio__stackDelete} data-number={stack.number} onClick={this.deleteStack}>×</div>
                    </li>);
                })}
              </ul>

              { this.state.show_tel_detail && this.state.company_tel_id ?
                <div className={Style.Twilio__telDetail}>
                  <div className={Style.Twilio__close} onClick={() => this.setState({show_tel_detail: false})}>
                    <Icon name='tilde-right' size='s' color='black' className='u-svg-rotate90' />
                  </div>
                  <TelDetail
                    id={this.state.company_tel_id}
                    company_id={this.state.company_id}
                    name={this.state.company_name}
                    group_company_id={this.state.group_company_id}
                    company_company_id={this.state.company_company_id}
                    callback={this.nextStack}
                  />
                </div>
                :
                <div className={Style.Twilio__content}>
                  { this.number_type != 'masouken' ?
                    <div className='u-ta-center u-fc-thinBlack u-mb-5 u-fs-small'>
                      <span>from</span><span className='u-ml-5 u-fc-red u-fw-bold'>{NUMBER_TYPES[this.number_type]}</span>
                    </div>
                    : null
                  }
                  <div className={Style.Twilio__display}>
                    { this.state.display != '' ?
                      this.state.display
                      :
                      <div className='u-fs-x-small'>番号を手入力した場合は<br />架電履歴として残すことができません。</div>
                    }
                  </div>
                  { this.state.is_busy && this.state.name && this.state.name != '' ?
                    <div className={Style.Twilio__name}>{this.state.name} 様</div>
                    : null
                  }

                  { this.state.company_tel_id ?
                    <div className={Style.Twilio__openDetail} onClick={() => this.setState({show_tel_detail: true})}>
                      <Icon name='document' size='s' color='main' />
                    </div>
                    : null
                  }

                  <div>
                    { this.state.show_dial ?
                      <div>
                        <div className={Style.Twilio__numberDisplay}>
                          <input type='text' value={this.state.number} defaultValue='' className='c-form-text' onChange={this.onChangeNumberDisplay} />
                        </div>
                        <p className='u-mt-20 u-ta-center u-mb-10 u-fc-blue u-fs-small u-cursor-pointer' onClick={() => this.setState({show_dial: false})}>ダイヤルボタンを非表示</p>
                        <div className={Style.Twilio__buttons}>
                          <div className={Style.Twilio__button} onClick={() => this.onPushNumber('1')}>1</div>
                          <div className={Style.Twilio__button} onClick={() => this.onPushNumber('2')}>2</div>
                          <div className={Style.Twilio__button} onClick={() => this.onPushNumber('3')}>3</div>
                        </div>
                        <div className={Style.Twilio__buttons}>
                          <div className={Style.Twilio__button} onClick={() => this.onPushNumber('4')}>4</div>
                          <div className={Style.Twilio__button} onClick={() => this.onPushNumber('5')}>5</div>
                          <div className={Style.Twilio__button} onClick={() => this.onPushNumber('6')}>6</div>
                        </div>
                        <div className={Style.Twilio__buttons}>
                          <div className={Style.Twilio__button} onClick={() => this.onPushNumber('7')}>7</div>
                          <div className={Style.Twilio__button} onClick={() => this.onPushNumber('8')}>8</div>
                          <div className={Style.Twilio__button} onClick={() => this.onPushNumber('9')}>9</div>
                        </div>
                        <div className={Style.Twilio__buttons}>
                          <div className={Style.Twilio__button} onClick={() => this.onPushNumber('*')}>*</div>
                          <div className={Style.Twilio__button} onClick={() => this.onPushNumber('0')}>0</div>
                          <div className={Style.Twilio__button} onClick={() => this.onPushNumber('#')}>#</div>
                        </div>
                      </div>
                      :
                      <div className='u-mt-10'>
                        <p className='u-ta-center u-mb-10 u-fc-blue u-cursor-pointer' onClick={() => this.setState({show_dial: true})}>ダイヤルボタンを表示する</p>
                      </div>
                    }
                    <div className={Style.Twilio__buttons}>
                      { this.state.is_busy ?
                        <div className={`${Style.Twilio__button} ${this.state.mute ? Style.Twilio__muted : ''}`} onClick={() => this.onMute()}><Icon name='mic' size='m' color='white' /></div>
                        :
                        <div className={`${Style.Twilio__button} ${Style.Twilio__nothing}`}><Icon name='mic' size='m' color='white' /></div>
                      }
                      { this.state.is_busy ?
                        <div className={`${Style.Twilio__button} ${Style.Twilio__cancel}`} onClick={() => this.onCancelCall()}><Icon name='phone' size='m' color='white' /></div>
                        :
                        ( this.state.call ? null : <div className={`${Style.Twilio__button} ${Style.Twilio__call}`} onClick={() => this.onCall()}><Icon name='phone' size='m' color='white' /></div> )
                      }
                      { this.state.show_dial ?
                        <div className={Style.Twilio__delete} onClick={this.onPullNumber}>×</div>
                        :
                        <div className={Style.Twilio__deleteHide}></div>
                      }
                    </div>
                  </div>
                  <div className='u-fs-x-small u-mt-10'>
                    不調の場合、繋ぐWi-Fiを変更もしくはテザリングでお試しください。
                  </div>
                </div>
              }
            </div>
          </div>
          :
          <div className={Style.Twilio__open} onClick={() => this.setState({show: true, show_dial: this.state.company_id ? this.state.show_dial : true})}><Icon name='phone' size='m' color='white' /> 電話を表示</div>
        }
      </div>
    );
  }
}