import React from 'react';
import style from './style';

export const Textbox = ({label, parent_model, child_model, column, value, rowNum, onChange, placeholder, required}) => {
  return (
    <div className={style.Textbox__item} key={`${parent_model}_${child_model}_${column}`}>
      <div>
        <label className={style.Textbox__label} htmlFor='name'>{label}</label>
        { required &&
          <span className={style.Textbox__required}>必須</span>
        }
      </div>
      <input className={style.Textbox__input} id={ `${parent_model}_${child_model}_attributes_${rowNum}_${column}`} type='text' placeholder={placeholder} name={`${parent_model}[${child_model}_attributes][${rowNum}][${column}]`} value={value} onChange={(e) => onChange(e ,rowNum, column)} required={required}/>
    </div>
  )
}