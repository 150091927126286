import React from 'react'
import Style from './style.sass'
import Link  from './link'

import { Editor, EditorState, RichUtils, CompositeDecorator, Entity } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'

/**
 *  モーダル
 *  @version 2018/06/10
 */
export default class Wysiwyg extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.decorator = new CompositeDecorator([
      {
        strategy: this.findEntities('LINK'),
        component: this.Link,
      },
      {
        strategy: this.findEntities('INLINE_STYLE'),
        component: this.InlineStyle,
      },
    ]);

    const content = props.content ? stateFromHTML(props.content, this.stateFromHtmlOptions()) : null;

    this.state = {
      editorState: content ? EditorState.createWithContent(content, this.decorator) : EditorState.createEmpty(this.decorator),
    }
  }

  /**
   *  指定されたエンティティを抽出する
   *  @version 2018/06/10
   */
  findEntities(entity) {

    return function(contentBlock, callback, contentState) {

      contentBlock.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          return entityKey !== null && contentState.getEntity(entityKey).getType() === entity;
        },
        callback
      );
    };
  }

  /**
   *  html化・復元時のhtmlオプション
   *  @version 2018/06/10
   */
  stateFromHtmlOptions() {

    return (
      {
        customInlineFn: (element, {Style, Entity}) => {

          if (element.tagName === 'SPAN') {

            let class_names = [];
            if (element.className.match(/u-fc-red/))      class_names.push('u-fc-red');
            if (element.className.match(/u-td-gradient/)) class_names.push('u-td-gradient');
            if (element.className.match(/u-fs-l/))    class_names.push('u-fs-l');

            return Entity('INLINE_STYLE', {class_name: class_names.join(' ')});
          }
        },
      }
    );
  }

  /**
   *  html化・復元時のhtmlオプション
   *  @version 2018/06/10
   */
  stateToHtmlOptions() {

    return (
      {
        entityStyleFn: (entity) => {

          const type = entity.get('type');
          const data = entity.getData();

          // inlineスタイル
          if (type === 'INLINE_STYLE') {

            return { element: 'span', attributes: { class: data.class_name } };

          // リンク
          } else if (type === 'LINK') {

            return { element: 'a', attributes: { href: data.url, target:'_blank', rel: 'noopener nofollow' } };
          }
        }
      }
    );
  }

  /**
   *  エディタをフォーカスする
   *  @version 2018/06/10
   */
  focusEditor = () => {

    setTimeout(() => this.editorRef.focus(), 0);
  }

  /**
   *  入力時
   *  @version 2018/06/10
   */
  onChange = editorState => {

    this.setState({editorState});
  }

  /**
   *  テキストのスタイル
   *  @version 2018/06/10
   */
  customBlockStyleFn(contentBlock) {

    return Style.publicDraftStyleDefaultBlock;
  }

  /**
   *  内容を取得
   *  @version 2018/06/10
   */
  content() {

    const { editorState } = this.state;

    const currentContent = editorState.getCurrentContent();

    if (!currentContent.hasText()) return '';

    // 本文を取得
    let html = stateToHTML(currentContent, this.stateToHtmlOptions());

    // 本文から空行を除去
    html = html.replace(/<p><br><\/p>/g, '\r\n');

    return html;
  }

  /**
   *  内容を更新する
   *  @version 2018/06/10
   */
  setContent(html) {

    const content = stateFromHTML(html, this.stateFromHtmlOptions());

    this.setState({
      editorState: EditorState.createWithContent(content, this.decorator)
    });
  }

  /**
   *  リンク
   *  @version 2018/06/10
   */
  Link = props => {

    const {url} = props.contentState.getEntity(props.entityKey).getData();
    return (
      <a href={url}>
        {props.children}
      </a>
    );
  }

  /**
   *  inlineタグ
   *  @version 2018/06/10
   */
  InlineStyle = props => {

    const {class_name} = props.contentState.getEntity(props.entityKey).getData();
    return (
      <span className={class_name}>
        {props.children}
      </span>
    );
  }

  /**
   *  リンク入力モーダルを表示
   *  @version 2018/06/10
   */
  promptForLink = e => {

    e.preventDefault();

    const {editorState} = this.state;
    const selection = editorState.getSelection();

    if (!editorState.getCurrentContent().hasText()) return '';

    if (!selection.isCollapsed() && editorState.getCurrentContent().hasText()) {

      this.setState({editorState: RichUtils.toggleLink(editorState, selection, null)}, () => {
        this.linkUrlRef.open();
      });
    }
  }

  /**
   *  入力したリンクを適用する
   *  @version 2018/06/10
   */
  applyLink = url => {

    const {editorState} = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: url });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    this.setState({
      editorState: RichUtils.toggleLink(
        editorState,
        editorState.getSelection(),
        entityKey
      ),
    }, () => {
      this.linkUrlRef.close();
      this.focusEditor();
    });
  }

  /**
   *  スタイルを適用する
   *  @version 2018/06/10
   */
  applyEntity = e => {

    e.preventDefault();

    const entity = e.target.dataset.entityStyle;
    const {editorState} = this.state;

    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    if (selectionState.isCollapsed() || !contentState.hasText()) return false;

    const class_name = this.decoratedStyle(entity);

    const contentStateWithEntity = contentState.createEntity('INLINE_STYLE', 'MUTABLE', { class_name: class_name });
    let entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // 選択範囲内において既に指定したstyleの有無を判定する
    const contentBlock = contentState.getBlockForKey(selectionState.getAnchorKey());
    contentBlock.findEntityRanges(
      (characters_metadata) => {

        const key = characters_metadata.getEntity();
        if (!key) return false;

        const entity_instance = contentState.getEntity(key);
        return entity_instance.getType() === 'INLINE_STYLE' && entity_instance.getData().class_name.match(class_name);
      },
      (start_offset, end_offset) => {

        // 選択範囲内に既に指定したstyleが含まれる場合はstyleを除去する
        if (selectionState.hasEdgeWithin(contentBlock.getKey(), start_offset+1, end_offset-1)) {

          entityKey = null;
        }
      }
    );

    this.setState({
      editorState: RichUtils.toggleLink(
        editorState,
        selectionState,
        entityKey
      ),
    }, () => {
      this.focusEditor();
    });
  }

  /**
   *  inlineクラスの取得
   *  @version 2018/06/10
   */
  decoratedStyle(mutability) {

    return this.customStyleMap()[mutability];
  }

  /**
   *  inlineクラスの定義
   *  @version 2018/06/10
   */
  customStyleMap() {

    return (
      {
        COLOR_RED: 'u-fc-red',
        UNDERLINED: 'u-td-gradient',
      }
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    const { editorState } = this.state;

    return (
      <div>
        <button className={`${Style.Wysiwyg__button} u-fw-bold`} onMouseDown={(e) => {
          this.onChange(
            RichUtils.toggleInlineStyle(editorState, 'BOLD')
          )
          e.preventDefault()
        }}>太字</button>
        <button className={`${Style.Wysiwyg__button} u-td-underline`} onMouseDown={this.applyEntity} data-entity-style='UNDERLINED'>下線</button>
        <button className={`${Style.Wysiwyg__button} u-fc-red`} onMouseDown={this.applyEntity} data-entity-style='COLOR_RED'>赤字</button>
        <div className={`${Style.Wysiwyg} u-mt-10`} onClick={this.focusEditor}>
          <Editor
            ref={ editor => this.editorRef = editor }
            editorState={editorState}
            onChange={this.onChange}
            blockStyleFn={this.customBlockStyleFn}
            placeholder={this.props.placeholder}
          />
        </div>
        <Link ref={ node => this.linkUrlRef = node} apply={this.applyLink} />
      </div>
    );
  }
}
