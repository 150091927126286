import React, { Component } from 'react'
import style from './style.sass'
import { chats, locales } from './properties'

// スクロールpollyfill
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  チャットボット
 *  @version 2020/03/31
 */
export default class Chatbot extends Component {

  /**
   * コンストラクタ
   */
  constructor(props) {
    super(props);

    this.isPc = window.parent.screen.width > 576;

    const show = this.isPc && props.show;

    this.theme = props.theme == 'mastory' ? 'mastory' : 'masouken';

    this.state = { show: show, bar: 0, currentChat: 1, maxChat: chats.length, answers: {}, errors: {}, documents: [], document_type: null, contact_type: null };
  }

  /**
   * マウント直後
   */
  componentDidMount() {
    window.addEventListener('load', () => {
      const btn = document.getElementsByClassName('qualvaBtn');
      if(!btn) return false;
      btn[0].addEventListener('click', () => {
        this.open();
      });
    });

    this.getDocuments();
  }

  /**
   * 下にスクロール
   */
  scrollToChatBottom() {
    const target = document.getElementById('main');
    if(!target) return false;
    target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
  }

  /**
   * 開く
   */
  open = () => {
    this.setState({ show: true }, () => {
      this.scrollToChatBottom();
      if (!this.isPc) document.body.style.overflow = 'hidden';
    });
  }

  /**
   * 閉じる
   */
  close = () => {
    this.setState({ show: false }, () => {
      if (!this.isPc) document.body.style.overflow = 'auto';
    });
  }

  /**
   * ラジオボタン変更
   */
  changeRadio = (e, name, i) => {
    this.setAnswer(name, e.target.value);
    this.nextChat(i+1);
  }

  /**
   * ラジオボタン変更
   */
  checkDocument = (e, name, i) => {

    let answers = (this.state.answers[name] || []).slice();
    let errors = Object.assign(this.state.errors, {});

    answers = answers.filter(answer => answer != e.target.value );
    if (e.target.checked) {
      answers.push(e.target.value);
      delete errors[name];
    }

    this.setState({errors: errors}, () => {
      this.setAnswer(name, answers);
    })
  }

  /**
   * ラジオボタン変更
   */
  changeContactType = (e, name, i) => {
    this.setAnswer(name, e.target.value);
    this.setState({contact_type: e.target.value}, () => {
      this.nextChat(i+1);
    });
  }

  /**
   * ラジオボタン変更
   */
  changeDocumentType = (e, name, i) => {
    this.setAnswer(name, e.target.value);
    this.setState({document_type: e.target.value}, () => {
      this.nextChat(i+1);
    });
  }

  /**
   * テキストボックス変更
   */
  changeText = (e, name) => {
    this.setAnswer(name, e.target.value);
    if (Object.keys(this.state.errors).length) this.validateText(name);
  }

  /**
   * テキストバリデーション
   */
  validateText(name) {

    var errors = Object.assign(this.state.errors, {});

    delete errors[name];

    const value = this.state.answers[name];

    switch(name) {
      case 'tel':
        const tel = value.trim().replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi,'');
        if (!value.trim().length) {
          errors[name] = '入力してください';
        } else if (value.trim().length < 10) {
          errors[name] = '電話番号の桁数が不正です';
        } else if (value.trim().length > 11) {
          errors[name] = '電話番号の桁数が不正です';
        } else if (value.trim()[0] != '0') {
          errors[name] = '電話番号は0から始まる必要があります。';
        } else if (!tel.match(/^([0-9]{10,11})$/)) {
          errors[name] = '半角数字で入力してください';
        }
      break;
      case 'email':
        if(!value.match(/[0-9a-zA-Z\-\_\.]+@.+\..+/)) errors[name] = 'メールアドレスの形式が不正です';
        if(!value.trim().length) errors[name] = '入力してください';
      break;
      case 'body':
        if(value && value.match(/^[0-9a-zA-Z]+$/)) errors[name] = '入力形式が不正です';
      break;
      case 'document_name':
        if(!value || value.length == 0) errors[name] = '資料を選択してください';
      break
      default:
        if(!value.trim().length) errors[name] = '入力してください';
      break;
    }
    this.setState({ errors: errors });
  }

  /**
   * 途中送信ボタン
   */
  onClickButton(i) {
    // 最終バリデーション
    const names = chats[i].filter(c => c.type == 'customer')[0].content.map(c => c.name);
    names.map(name => this.validateText(name));
    // エラーがあったら終わり
    if(Object.keys(this.state.errors).length) return false;
    // なければ続きのチャット
    this.nextChat(i+1);
  }

  /**
   * 回答保存
   */
  setAnswer(name, value) {
    const { answers } = this.state;
    this.setState({ answers: Object.assign(answers, {[name]: value}) });
  }

  /**
   * 次のチャットへ
   */
  nextChat(i) {

    let answers = JSON.parse(JSON.stringify(this.state.answers));
    let errors = JSON.parse(JSON.stringify(this.state.errors));

    if (answers) {

      chats.forEach((chat, index) => {

        const customer = chat.filter(c => c.type == 'customer');
        if (customer[0]) {
          const properties = customer[0].content.map(c => { return [c.name, c.type] });

          if (i < index && properties) {
            properties.forEach(property => {
              if (property[1] == 'radio' && answers[property[0]]) delete answers[property[0]];
              if (property[1] == 'radio' && errors[property[0]]) delete errors[property[0]];
              if (property[1] == 'document_name' && answers[property[0]]) delete answers[property[0]];
              if (property[1] == 'document_name' && answers[property[0]]) delete answers[property[0]];
            });
          }
        }
      });
    }

    if (this.state.contact_type != 'document' && (this.state.currentChat == 1 || this.state.currentChat == 2)) i += 2;

    this.setState({ currentChat: i }, () => {
      this.setState({ currentChat: i+1, answers: answers, errors: errors }, this.scrollToChatBottom);
    });
  }

  /**
   * 資料一覧を取得
   */
  getDocuments = () => {
    
    const host = location.hostname.match(/^localhost$/) ? location.origin : 'https://attackable.masouken.com';

    Request.get(`${host}/documents`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .withCredentials()
      .end((error, response) => {

        this.setState({documents: response.body.documents})
      });
  }

  /**
   * 資料一覧
   */
  documents = () => {

    return this.state.documents.filter( _document => _document.document_type == this.state.document_type ) || [];
  }

  /**
   * 送信
   */
  onSubmit = () => {

    const { answers } = this.state;
    const params = Object.assign(JSON.parse(JSON.stringify(answers)), { referer: location.host, url: document.URL, form_type: 'chatbot' });
    const host = location.hostname.match(/^localhost$/) ? location.origin : 'https://attackable.masouken.com';

    Request.post(`${host}/chatbot`)
      .send({ contacts: params })
      .set('X-Requested-With', 'XMLHttpRequest')
      .withCredentials()
      .end((error, response) => {

        this.nextChat(this.state.maxChat-1);
      });

    this.setState({submited: true});
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {
    const { show, currentChat, maxChat, errors, answers } = this.state;

    if(!show) return false;

    return (
      <div className={`${style.Chatbot} ${this.isPc ? style.fadein : style.fadeinSp}`} style={{maxWidth: this.isPc ? '380px' : '100vw', right: this.isPc ? '5px' : '0'}}>
        <div className={`${style[`Chatbot__header--${this.theme}`]}`}>
          <p>株式会社M&A総合研究所</p>
          <p className={style.Chatbot__strong}>お問い合わせはこちらから</p>
          <p className={style.Chatbot__close} onClick={this.close}>×</p>
        </div>
        <div className={style.Chatbot__main} id='main'>
          {
            [...new Array(currentChat)].map((_null, i) => {
              const details = chats[i];
              return details.map((detail, key) => {
                const { type, content, contact_type } = detail;

                if (contact_type && this.state.contact_type != contact_type) return null

                // bot発言
                if(type == 'bot') {
                  return (
                    <div className={style.Chatbot__botWrap} {...{key}}>
                      <img src='https://s3-ap-northeast-1.amazonaws.com/cdn.attack.masouken.com/modules/icon.png' className={style.Chatbot__botIcon} />
                      <p className={`${style[`Chatbot__bot--${this.theme}`]}`} dangerouslySetInnerHTML={{__html: content}}></p>
                    </div>
                  );
                }
                // form
                if(type == 'customer') {

                  return (
                    <ul className={style.Chatbot__customer} {...{key}}>
                      { content.map((form, key) => {
                        const { name, label, options } = form;
                        const lastForm = (key == content.length-1);
                        switch(form.type) {
                          case 'radio':
                            return options.map((option, key) =>
                              <li className={style.Chatbot__customerItem} {...{key}}>
                                <label className={`${style[`Form__radioLabel--${this.theme}`]}`}>
                                  <input type='radio' name={name} value={option} className={`${style[`Form__radio--${this.theme}`]}`} disabled={this.state.submited} onChange={e => this.changeRadio(e, name, i)} defaultChecked={option == answers[name]} />
                                  <i className={`${style[`Form__radioIcon--${this.theme}`]}`} />
                                  {locales[name][option]}
                                </label>
                              </li>
                            );
                          break;
                          case 'text':
                            return (
                              <li className={style.Chatbot__customerItem} {...{key}}>
                                <label className={style.Form__label}>{label}</label>
                                <input type='text' name={name} placeholder={label} readOnly={this.state.submited} defaultValue={answers[name]} className={style.Form__text} onChange={e => this.changeText(e, name, content)} autoComplete='off' />
                                { !!errors[name] && <p className={style.Chatbot__error}>{errors[name]}</p> }
                                { lastForm && <div className={`${style[`Chatbot__button--${this.theme}`]}`} disabled={!this.state.answers[name] || Object.keys(errors).length || this.state.submited} onClick={() => this.onClickButton(i)}>{ this.state.currentChat > (i + 1) ? '更新する' : '次へ' }</div> }
                              </li>
                            );
                          break;
                          case 'textarea':
                            return (
                              <li className={style.Chatbot__customerItem} {...{key}}>
                                <label className={style.Form__label}>{label}</label>
                                <textarea name={name} defaultValue={answers[name]} readOnly={this.state.submited} placeholder={label} rows='5' className={style.Form__textarea} onChange={e => this.changeText(e, name, content)} autoComplete='off' />
                                { !!errors[name] && <p className={style.Chatbot__error}>{errors[name]}</p> }
                                { lastForm && <div className={`${style[`Chatbot__button--${this.theme}`]}`} disabled={Object.keys(errors).length || this.state.submited} onClick={() => this.onClickButton(i)}>{ this.state.currentChat > (i + 1) ? '更新する' : '次へ' }</div> }
                              </li>
                            );
                          break;
                          case 'contact_type':
                            return options.map((option, key) =>
                              <li className={style.Chatbot__customerItem} {...{key}}>
                                <label className={`${style[`Form__radioLabel--${this.theme}`]}`}>
                                  <input type='radio' name={name} value={option} className={`${style[`Form__radio--${this.theme}`]}`} disabled={this.state.submited} onChange={e => this.changeContactType(e, name, i)} defaultChecked={option == answers[name]} />
                                  <i className={`${style[`Form__radioIcon--${this.theme}`]}`} />
                                  {locales[name][option]}
                                </label>
                              </li>
                            );
                          break;
                          case 'document':
                            return options.map((option, key) =>
                              <li className={style.Chatbot__customerItem} {...{key}}>
                                <label className={`${style[`Form__radioLabel--${this.theme}`]}`}>
                                  <input type='radio' name={name} value={option} className={`${style[`Form__radio--${this.theme}`]}`} disabled={this.state.submited} onChange={e => this.changeDocumentType(e, name, i)} defaultChecked={option == answers[name]} />
                                  <i className={`${style[`Form__radioIcon--${this.theme}`]}`} />
                                  {locales[name][option]}
                                </label>
                              </li>
                            );
                          break;
                          case 'documents':
                            return (
                              <li className={style.Chatbot__customerItem} {...{key}}>
                                { this.documents().map((_document, _key) => (
                                  <label className={`${style.Form__checkboxLabel}`} key={`document-${_key}`}>
                                    <input className={`${style.Form__checkbox}`} name={name} type='checkbox' value={_document.name} disabled={this.state.submited} onChange={e => this.checkDocument(e, name, i)} />
                                    <i className={`${style.Form__checkboxIcon}`}></i>
                                    {_document.name}
                                  </label>
                                ))}
                                { !!errors[name] && <p className={style.Chatbot__error}>{errors[name]}</p> }
                                { lastForm && <div className={`${style[`Chatbot__button--${this.theme}`]}`} disabled={!this.state.answers[name] || Object.keys(errors).length || this.state.submited} onClick={() => this.onClickButton(i)}>{ this.state.currentChat > (i + 1) ? '更新する' : '次へ' }</div> }
                              </li>
                            );
                            
                          break;
                          case 'confirm':
                            return (
                              <div {...{key}}>
                                { chats.map( chat => (
                                  chat.map( chat_details => {

                                    if (chat_details.type != 'customer') return null;
                                    return chat_details.content.map((chat_detail, answer_key) => {

                                      if (!answers[chat_detail.name]) return null;
                                      if (['document_name'].includes(chat_detail.name)) return null;

                                      return (
                                        <li className={style.Chatbot__confirm} key={`answer-${answer_key}`}>
                                          <b>{chat_detail.label}：</b>
                                          { chat_detail.name.match(/^(email|interest|body)$/) && <br /> }
                                          <span dangerouslySetInnerHTML={{__html: chat_detail.name.match(/^(contact_type|interest|document_type)$/) ? locales[chat_detail.name][answers[chat_detail.name]] : (answers[chat_detail.name] || '').replace(/\r?\n/g, '<br />')}}></span>
                                        </li>
                                      );
                                    });
                                  })
                                ))}
                                <div className={`${style[`Chatbot__submit--${this.theme}`]}`} disabled={this.state.submited} onClick={this.onSubmit}>送信する</div>
                              </div>
                            );
                          break;
                          }
                        }
                      )}
                    </ul>
                  );
                }
              });
            })
          }
        </div>
        <div className={style.Chatbot__gauge}>
          <div className={`${style[`Chatbot__gaugeBar--${this.theme}`]}`} style={{ width: `${Math.floor(currentChat/maxChat*100)}%` }} >{currentChat}/{maxChat}</div>
        </div>
      </div>
    );
  }
}
