import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import Modal from '../utilities/modal'
import Style from './style.sass';

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

export const CategorySelectTags = memo(
  ({root_categories}) => {
  
    const headerElm = useRef(null);

    const [rootCategories, setRootCategories] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [mediumCategories, setMediumCategories] = useState([]);
    const [smallCategories, setSmallCategories] = useState([]);
    const [tinyCategories, setTinyCategories] = useState([]);
    const [selection, setSelection] = useState([]);

    useEffect(() => {
      setRootCategories(root_categories.map(category => {
        category.open = false;
        category.breadcrumb = category.name;
        if (selection.every(item => item.id != category.id )) {
          category.checked = false;
        } else {
          category.checked = true;
        }
        return category;
      }))
    }, []);

    useEffect(() => {
      // モーダルが開いてる時は背景固定
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'initial';
      }
    }, [isOpen]);

    // モーダル開く
    const openModal = (e) => {
      e.stopPropagation();
      setIsOpen(true);
    }

    // モーダル閉じる
    const closeModal = (e) => {
      e.stopPropagation();
      setIsOpen(false)
    }

    // カテゴリの取得
    const getCategories = (id, breadcrumb, callback) => {
      Request.get(`/users/categories/${id}`)
        .set('X-Requested-With', 'XMLHttpRequest')
        .setCsrfToken()
        .end((error, response) => {

          if (response.body.status == 'success' && response.body.categories) {
            const categories = response.body.categories.map(category => {
              category.open = false;
              category.breadcrumb = `${breadcrumb} > ${category.name}`
              if (selection.every(item => item.id != category.id )) {
                category.checked = false;
              } else {
                category.checked = true;
              }
              return category
            })
            if (callback) callback(categories);
          } else {
            console.log(error);
          }
        });
    }

    // 子カテゴリを開く
    const openChildren = (e, categories, callback1, callback2, level) => {
      e.stopPropagation();
      const _categories = categories.slice();
      _categories.forEach(category => {
        if (category.id == e.target.id) {
          category.open = true;
        } else {
          category.open = false;
        }
      })
      getCategories(e.target.id, e.target.dataset.breadcrumb, callback2)
      resetChildren(level);
      callback1(_categories);
    }

    // カテゴリを選択する
    const selectCategory =(e, categories, callback) => {
      e.stopPropagation();
      const level = (e.target.dataset.breadcrumb.match(/\s>\s/g) || []).length
      const category = {id: e.target.dataset.id, name: e.target.dataset.name, breadcrumb: e.target.dataset.breadcrumb, level: level};
      let _selection = selection.slice();
      if (_selection.length == 0) {
        _selection.push(category)
        setSelection(_selection);
      } else {
        const filteredSelection = _selection.filter(item => item.id !== category.id);
        if (selection.length === filteredSelection.length) {
          filteredSelection.push(category);
        }
        setSelection(filteredSelection);
      }

      const _categories = categories.slice();
      _categories.forEach(category => {
        if (category.id == e.target.dataset.id) {
          category.checked = !category.checked;
        }
      });

      callback(_categories);
    }

    // 子カテゴリを削除
    const resetChildren = (level) => {
      if (level < 3)setTinyCategories([]);
      if (level < 2) setSmallCategories([]);
      if (level < 1) setMediumCategories([]);
    }

    // 選択中のカテゴリを削除
    const deleteSelection = (e) => {
      e.stopPropagation();
      let _selection = selection.slice();
      const id = e.target.dataset.id;
      const level = e.target.dataset.level
      const filteredSelection = _selection.filter(item => item.id !== id);
      setSelection(filteredSelection);

      if (level == 0) {
        removeCheck(id, rootCategories, setRootCategories);
      } else if (level == 1) {
        removeCheck(id, mediumCategories, setMediumCategories);
      } else if (level == 2) {
        removeCheck(id, smallCategories, setSmallCategories);
      } else if (level == 3) {
        removeCheck(id, tinyCategories, setTinyCategories);
      }
    }

    // カテゴリのチェックを外す
    const removeCheck = (id, categories, setCategories) => {
      const _categories = categories.slice();
      _categories.forEach(category => {
        if (category.id == id) category.checked = false;
      });
      setCategories(_categories);
    }

    // 同レベルのチェックを全て外す
    const removeLevelCheck = (level) => {
      let categories
      let setCategories
      if (level == 0) {
        categories = rootCategories;
        setCategories = setRootCategories;
      } else if (level == 1) {
        categories = mediumCategories;
        setCategories = setMediumCategories;
      } else if (level == 2) {
        categories = smallCategories;
        setCategories = setSmallCategories;
      } else if (level == 3) {
        categories = tinyCategories;
        setCategories = setTinyCategories;
      }
      const _categories = categories.slice();
      _categories.forEach(category => category.checked = false);
      setCategories(_categories);

    }

    // 全ての選択中カテゴリを解除
    const removeAllCheck = () => {
      for (let i = 0; i <= 3; i++) {
        removeLevelCheck(i);
      }
      setSelection([]);
    }

    // ヘッダーをスクロール
    const scrollHeader = useCallback(() => {
      if (headerElm.current) {
          headerElm.current.scrollTo({
          top: headerElm.current.scrollHeight,
          behavior: 'instant'
        })
      }
    }, []);

    // カテゴリリスト
    const Categories = React.memo(
      ({categories, setCategories, setChildCategories, level}) => {

        return (
          <>
            { categories.map( category => {
              return <div className={Style.CategorySelectTagsCategoryItem} key={category.name} >
                { category.checked ?
                  <span className={`${Style.CategorySelectTagsCheckBox} ${Style.CategorySelectTagsCheckBoxChecked}`} onClick={(e) => selectCategory(e, categories, setCategories)} data-name={category.name} data-id={category.id} data-breadcrumb={category.breadcrumb}></span> :
                  <span className={Style.CategorySelectTagsCheckBox} onClick={(e) => selectCategory(e, categories, setCategories)} data-name={category.name} data-id={category.id} data-breadcrumb={category.breadcrumb}></span> 
                }
                { level < 3 ? <div
                  className={category.open ? `${Style.CategorySelectTagsRootCategory} ${Style.CategorySelectTagsRootCategoryOpen}` : Style.CategorySelectTagsRootCategory} 
                  id={category.id}
                  data-breadcrumb={category.breadcrumb}
                  onClick={category.open ? null : (e) => openChildren(e, categories, setCategories, setChildCategories, level)}
                  >
                    {category.name}
                </div> :
                <div
                  className={Style.CategorySelectTagsTinyCategory} 
                  onClick={(e) => selectCategory(e, categories, setCategories)}
                  data-name={category.name}
                  data-id={category.id}
                  data-breadcrumb={category.breadcrumb}
                >
                  {category.name}
                </div>
                }
              </div>
            })}
          </>
        )
      }
    );

    const Selection = React.memo(
      ({collection, deleteMethod, callback}) => {
  
        const selectionColors = [
          Style.CategorySelectTagsSelected0,
          Style.CategorySelectTagsSelected1,
          Style.CategorySelectTagsSelected2,
          Style.CategorySelectTagsSelected3
        ];
        
        return (
          <>
            {collection.map(item => {
              return (
                <div className={`${Style.CategorySelectTagsSelected} ${selectionColors[item.level]}`} key={item.breadcrumb} ref={callback}>
                  {item.breadcrumb}
                  <span className={Style.CategorySelectTagsDelete} onClick={(e) => deleteMethod(e)} data-id={item.id} data-level={item.level}></span>
                </div>
              )
            })}
          </>
        );
      }
    );

    return (
      <div className={Style.CategorySelectTags}>

        { isOpen ?
          <div className={Style.CategorySelectTagsBackground} onClick={closeModal}>
            <div className={Style.CategorySelectTagsModal} onClick={e => e.stopPropagation()}>
              <div className={Style.CategorySelectTagsModalHeader}>
                <div className={Style.CategorySelectTagsHeading}>
                  <p className={Style.CategorySelectTagsLevel}>選択中の業種</p>
                  { selection.length > 0 &&
                    <span className={Style.CategorySelectTagsClear} onClick={removeAllCheck}>すべてクリア</span>
                  }
                </div>
                <div className={Style.CategorySelectTagsSelection} ref={headerElm}>
                  {selection.length == 0 && <p className='u-fs-s'>選択中の業種はありません。</p>}
                  <Selection collection={selection} deleteMethod={deleteSelection} callback={scrollHeader}/>
                </div>
                <div className={Style.CategorySelectTagsCategoryHeaders}>
                  <p className={`${Style.CategorySelectTagsLevel} ${Style.CategorySelectTagsLevel0}`}>
                    大分類
                  </p>
                  <p className={`${Style.CategorySelectTagsLevel} ${Style.CategorySelectTagsLevel1}`}>
                    中分類
                  </p>
                  <p className={`${Style.CategorySelectTagsLevel} ${Style.CategorySelectTagsLevel2}`}>
                    小分類
                  </p>
                  <p className={`${Style.CategorySelectTagsLevel} ${Style.CategorySelectTagsLevel3}`}>
                    細分類
                  </p>
                </div>
              </div>
              <div className={Style.CategorySelectTagsModalBody}>
                <div className={Style.CategorySelectTagsRootCategoriesWrap}>
                  <Categories categories={rootCategories} setCategories={setRootCategories} setChildCategories={setMediumCategories} level={0}/>
                </div>
                { mediumCategories.length > 0 &&
                  <div className={Style.CategorySelectTagsRootCategoriesWrap}>
                    <Categories categories={mediumCategories} setCategories={setMediumCategories} setChildCategories={setSmallCategories} level={1}/>
                  </div>
                }
                { smallCategories.length > 0 &&
                  <div className={Style.CategorySelectTagsRootCategoriesWrap}>
                    <Categories categories={smallCategories} setCategories={setSmallCategories} setChildCategories={setTinyCategories} level={2}/>
                  </div>
                }
                { tinyCategories.length > 0 &&
                  <div className={Style.CategorySelectTagsRootCategoriesWrap}>
                    <Categories categories={tinyCategories} setCategories={setTinyCategories} level={3}/>
                  </div>
                }
              </div>
              <div className={Style.CategorySelectTagsModalFooter}>
                <div className={Style.CategorySelectTagsClose} onClick={closeModal}>閉じる</div>
              </div>
            </div>
          </div> :
          <div>
            { selection.length > 0 &&
              <div className={Style.CategorySelectTagsList}>
                <Selection  collection={selection} deleteMethod={deleteSelection}/>
                { selection.map((item, j) => {
                  return <input type='hidden' value={item.id} name={`category_ids[${j}]`} key={item.breadcrumb}/>
                })}
              </div>
            }
            <div className='u-mt-10'>
              <div className='c-btnMain-attention c-btnSmall' onClick={openModal}>+ 業種を追加する</div>
            </div>
          </div>
        }
      </div>
    )   
  
  }
)
