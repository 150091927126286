import React, { useState, useEffect, memo } from 'react';
import style from './style.sass';
import { Textbox } from './textbox';
import { Selectbox } from './selectbox';
import { Number } from './number';
import { Textarea } from './textarea';
import { Hidden } from './hidden';
import { Date } from './date';
import { Radiobutton } from './radiobutton';

export const MultipleForm = memo(
  ({ parent_model, child_model, children, columns, button_text, image_file }) => {
  
    const [records, setRecords] = useState([]);
    const [image, setImage] = useState(image_file || undefined);

    // 初期化
    useEffect(() => {
      const newRecords = records.slice();
      children.forEach( item => {
        item.deleted = false;
        newRecords.push(item);
      })
      setRecords(newRecords);
    }, [])
  
    // １行のパーツを生成
    const generateTemplate = () => {
      const obj = { deleted: false };
      columns.forEach((column) => { obj[column.name] = "" });
      return obj;
    }
  
    // １行追加
    const _addRow = () => {
      const template = generateTemplate();
      const newRows = records.slice();
      newRows.push(template);
      setRecords(newRows);
    }
  
    // 行削除
    const _deleteRow = (index) => {
      const newRows = records.slice();
  
      newRows.forEach((record, i) => {
        if (i == index) record.deleted = true;
      } )
      setRecords(newRows);
    }
  
    // 値変更時の処理
    const _onChangeValue = (e, rowNum, column) => {
      console.log(e.target.value)
      const newRecords = records.slice();
      newRecords[rowNum][column] = e.target.value;
      setRecords(newRecords);
    }

    const _onInputFile = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImage(reader.result);
      }
    }

  
    return (
      <div>
        {records.map((record, index) => {
          if (!record.deleted) {
            return (
              <div key={`multiple_form${index}`}>
                <div className={style.MultipleForm__row} key={index}>
                  <span>{index + 1}</span>
                  <input type='hidden' name={`${parent_model}[${child_model}_attributes][${index}][id]`} value={record ? record.id : ''} />
                  <div className={style.MultipleForm__delete} onClick={() =>_deleteRow(index)}>削除</div>
                  { columns.map( (column) => {
                    if (column.type === 'text') {
                      return <Textbox label={column.label} placeholder={column.placeholder} parent_model={parent_model} child_model={child_model} column={column.name} value={record[column.name] || ''} rowNum={index} onChange={_onChangeValue} key={`multiple_form${index}_${column.name}`} required={column.required}/>
                    } else if (column.type === 'select') {
                      return <Selectbox label={column.label} placeholder={column.placeholder} parent_model={parent_model} child_model={child_model} column={column.name} value={record[column.name] || ''} options={column.options} rowNum={index} onChange={_onChangeValue} key={`multiple_form${index}_${column.name}`} required={column.required} include_blank={column.include_blank}/>
                    } else if (column.type === 'number') {
                      return <Number label={column.label} placeholder={column.placeholder} parent_model={parent_model} child_model={child_model} column={column.name} value={record[column.name] || ''} rowNum={index} onChange={_onChangeValue} step={column.step} min={column.min} max={column.max} required={column.required} key={`multiple_form${index}_${column.name}`}/>
                    } else if (column.type === 'textarea') {
                      return <Textarea label={column.label} placeholder={column.placeholder} parent_model={parent_model} child_model={child_model} column={column.name} value={record[column.name] || ''} rowNum={index} onChange={_onChangeValue} rows={column.rows} cols={column.cols} required={column.required} key={`multiple_form${index}_${column.name}`}/>
                    } else if (column.type === 'hidden') {
                      return <Hidden parent_model={parent_model} child_model={child_model} column={column.name} value={column.value || ''} rowNum={index} key={`multiple_form${index}_${column.name}`}/>
                    } else if (column.type === 'date') {
                      return <Date label={column.label} parent_model={parent_model} child_model={child_model} column={column.name} value={record[column.name] || ''} rowNum={index} onChange={_onChangeValue} required={column.required} key={`multiple_form${index}_${column.name}`}/>
                    } else if (column.type === 'file') {
                      return (
                        <div className={style.Textbox__item} key={`multiple_form${index}_${column.name}`}>
                          <label className={style.Textbox__label} htmlFor='name'>{column.label}</label>
                          <div>
                            <input className={style.MultipleForm__input} type='file' onChange={(e) => _onInputFile(e)} name={`${parent_model}[${child_model}_attributes][${index}][${column.name}]`} id={ `${parent_model}_${child_model}_attributes_${index}_${column.name}`}/>
                          </div>
                        </div>
                      )
                    } else if (column.type === 'radiobutton') {
                      return <Radiobutton label={column.label} parent_model={parent_model} child_model={child_model} column={column.name} value={record[column.name] || false} rowNum={index} onChange={_onChangeValue} key={`multiple_form${index}_${column.name}`} required={column.required}/>
                    }
                    }
                  )}
                </div>
                <hr />
              </div>
            )
          } else {
            return(
              <div>
                <input type='hidden' name={`${parent_model}[${child_model}_attributes][${index}][id]`} value={record ? record.id : ''} />
                <input type='hidden' name={`${parent_model}[${child_model}_attributes][${index}][_destroy]`} value='1'/>
              </div>
            )
          }
        })
        }
        <div className={style.MultipleForm__buttons}>
          <div className={style.MultipleForm__add} onClick={_addRow}>{`+ ${button_text}を追加する`}</div>
        </div>
      </div>
    )
  
  }
)