import React      from 'react'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class ModalPage extends React.Component {

  constructor(props) {

    super(props)

    this.state = {show: false, current_page_index: null, url_suffix: null};
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {

    // グローバルからの呼び出し
    window.modalPage = (page_id, url_suffix=null) => {

      this.open(page_id, url_suffix);
    };

    // グローバルからの呼び出し
    window.closePage = () => {

      this.close();
    };
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = (page_id, url_suffix=null) => {

    document.body.classList.add('u-cursor-wait');

    this.setState({
      current_page_index: this.props.page_ids ? this.props.page_ids.indexOf(page_id) : 0,
      url_suffix: url_suffix,
    }, () => {

      let url = `${this.props.base_url}/${page_id}${url_suffix ? ('/' + url_suffix) : ''}`;

      if (url.match(/\?/)) {
        url += '&modal=true';
      } else {
        url += '?modal=true';
      }

      Request.get(url)
        .set('X-Requested-With', 'XMLHttpRequest')
        .withCredentials()
        .then(response => {

          this.setState({show: true}, () => {

            this.iframeRef.contentWindow.document.write(response.text);

            document.body.style.overflow = 'hidden';
            document.body.classList.remove('u-cursor-wait');
          });
        });
    });
  }

  /**
   *  ひとつ前のページ
   *  @version 2018/06/10
   */
   prev = e => {

    e.stopPropagation();
    this.iframeRef.contentWindow.document.write('loading');

    const current_page_index = this.state.current_page_index - 1;
    this.setState({current_page_index: current_page_index}, () => {
      modal_page.location.href = `${this.props.base_url}/${this.props.page_ids[current_page_index]}${this.state.url_suffix ? ('/' + this.state.url_suffix) : ''}?modal=true`;
    });
  }

  /**
   *  ひとつ後のページ
   *  @version 2018/06/10
   */
   next = e => {

    e.stopPropagation();
    this.iframeRef.contentWindow.document.write('loading');

    const current_page_index = this.state.current_page_index + 1;
    this.setState({current_page_index: current_page_index}, () => {
      modal_page.location.href = `${this.props.base_url}/${this.props.page_ids[current_page_index]}${this.state.url_suffix ? ('/' + this.state.url_suffix) : ''}?modal=true`;
    });
  }

  /**
   *  モーダルを閉じる
   *  @version 2018/06/10
   */
  close = () => {

    this.setState({show: false}, () => {
      document.body.style.overflow = 'auto';
    });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      this.state.show ?
        <div className={Style.ModalPage} onClick={this.close}>
          <div className={Style.ModalPage__body}>
            { this.state.current_page_index != 0 ? <div className='c-modal__prev' onClick={this.prev}><p className='c-modal__prevText'>前へ →</p></div> : null }
            { this.props.page_ids && this.props.page_ids.length > 1 && this.state.current_page_index != (this.props.page_ids.length - 1) ? <div className='c-modal__next' onClick={this.next}><p className='c-modal__nextText'>← 次へ</p></div> : null }

            <iframe src='' width='100%' height='100%' ref={ node => this.iframeRef = node } name='modal_page' />
          </div>
          <div className={Style.ModalPage__close} onClick={this.close}>×</div>
        </div>
        : null
    );
  }
}
