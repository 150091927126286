import React      from 'react'
import Modal from '../utilities/modal'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class ClosingPackageForm extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      companies: [],
      company_id: props.company_id,
      name: '',
      company_name: props.company_name || '',
    }
  }

  componentDidMount() {
    
    if (this.props.company_id) {

      this.get_company(this.props.company_id);
    }
  }

  componentDidUpdate() {

    if (this.state.company) {
      console.log(this.state.company.president_name)
    }
  }

  get_company = (company_id) => {

    if (!company_id) return false;

    // 会社情報の取得
    Request.get(`/users/get_company/${company_id}/`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success' && response.body.company) {

          this.setState({
            company_id: response.body.company.id,
            company_name: response.body.company.name,
            company: response.body.company,
          });

        } else {
          window.alertable({ type: 'error', message: '[初期表示]エラーにより会社情報が取得できませんでした。' });
        }
      });
  }

  /**
   *  submit処理
   *  @version 2018/06/10
   */
  _onSubmit = (e) => {

    e.stopPropagation();

    this.get_company(e.target.dataset.id);
    this.refs.regist_modal.close();
  }

  /**
   *  会社IDの取得
   *  @version 2018/06/10
   */
  company = () => {

    return this.state.company;
  }

  onChangeName = (e) => {
    
    e.stopPropagation();

    this.setState({ company_name: e.target.value, company_id: null });
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = () => {

    if (this.state.company_name == '') {
      window.alertable({ type: 'warning', message: '会社名を入力してください' });
      return false;
    }

    window.startLoading();

    let url = `/users/get_companies/?name=${encodeURIComponent(this.state.company_name)}`;

    if (this.refs.name_search_type && this.refs.name_search_type.value != '') url += `&name_search_type=${this.refs.name_search_type.value}`;

    // 会社情報の取得
    Request.get(url)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success' && response.body.companies) {

          this.setState({companies: response.body.companies || []}, () => {

            window.closeLoading();
            
            if (response.body.companies.length > 0) {
              this.refs.regist_modal.open(); 
            } else {
              window.alertable({ type: 'warning', message: '会社情報が取得できませんでした。未登録の会社は新規登録する必要があります。' });
            }
          });
        } else {
          window.closeLoading();
          this.setState({companies: []}, () => {
            window.alertable({ type: 'error', message: '[会社情報取得]エラーにより会社情報が取得できませんでした。' });
          });
        }
      });
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    return (
      <div className={Style.Search}>
        <div className={Style.Search__inner}>
          { this.state.companies.map((company, index) => {
            const key = 'company-'+index;
            return (
              <div {...{key}} className={Style.Search__item} data-name={company.name} data-id={company.id} onClick={this._onSubmit}>
                <div className='u-fs-large u-fw-bold'>{company.name}</div>
                <div>都道府県: {company.prefecture}</div>
                <div>住所: {company.address}</div>
                <div>代表者名: {company.president_name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        <div className='c-form-label u-mt-10'>
          <label htmlFor='company_name'>{this.props.label}</label>
          { (!this.props.required || this.props.required == false) ? null : <span className='c-form__required u-ml-10'>必須</span> }
        </div>
        <div className='u-display-flex'>
          <input type='hidden' name={this.props.name} value={this.state.company_id || ''} />
          <div style={{flex: 1, position: 'relative'}}>
            <input type='text' value={this.state.company_name} onChange={this.onChangeName} className='c-form-text' placeholder='会社名、もしくはIDを入力してください' required={(!this.props.required || this.props.required == false) ? false : true}/>
            
            <select className={Style.Search__select} defaultValue={''} ref='name_search_type'>
              <option value=''>あいまい</option>
              <option value='prefix'>前方一致</option>
              <option value='absolute'>完全一致</option>
            </select>

            { this.state.company_id ?
              <div className={Style.Search__checked}></div>
              : null
            }
          </div>
          <div onClick={this.open} className='c-btnMain-standard u-ml-15' style={{width: '30%', lineHeight: '30px'}}>チェック</div>
          <Modal ref='regist_modal' yield={this.content()}/>
        </div>

        { !this.state.company_id ?
          <div className='u-fs-small u-fc-thinBlack u-fw-normal'>※ 会社名もしくはIDを入力しチェックを行ってください</div> : null
        }

        {this.state.company && 
          <div>
            <p className='u-mt-10'>住所：{this.state.company.address}</p>
            <p className='u-mt-10'>代表者名：{this.state.company.president_name}</p>
          </div>
        }
        <div>
        </div>
      </div>
    );
  }
}