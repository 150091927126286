export const BROADCAST_CHANNEL_NAME = 'twilio_broadcast';

export const NUMBER_TYPES = {
  masouken: 'M&A総合研究所',
  executive: 'M&Aエグゼクティブパートナーズ',
  financial: 'M&Aプライムグループ',
  kensetsu: '建設M&A総研',
  touhoku: '東北M&A総研',
  quants: 'クオンツコンサルティング',
}
