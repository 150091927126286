//##############################################################################
// RailsUjsJack
//##############################################################################

/**
 *  RailsUjsJack Class
 *  @version 2018/06/10
 */
export default new class RailsUjsJack {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor() {

    this.confirmableSelector = 'a[data-confirmable]';
    this.confirmableSelector2 = 'input[data-confirmable]';
    this.alertableSelector   = 'a[data-alertable]';
  }

  /**
   *  RailsUjsが実施する処理をjackし、リンク時のconfirm処理等を変更する
   *  @version 2018/06/10
   */
  jack = Rails => {

    this.Rails = Rails;

    Rails.delegate(document, this.confirmableSelector, 'click', this.handleConfirm);
    Rails.delegate(document, this.confirmableSelector2, 'click', this.handleConfirm);
    Rails.delegate(document, this.alertableSelector, 'click', this.handleAlert);
  }

  /**
   *  confirm時の処理
   *  @version 2018/06/10
   */
  handleConfirm = e => {

    const message = e.target.getAttribute('data-confirmable');
    if (!message) return true;

    this.Rails.stopEverything(e);

    window.confirmable({ type: 'warning', message: message, callback: () => {

      e.target.removeAttribute('data-confirmable');
      e.target.click();
      e.target.setAttribute('data-confirmable', message);
    }});
  }

  /**
   *  alert時の処理
   *  @version 2018/06/10
   */
  handleAlert = e => {

    const message = e.target.getAttribute('data-alertable');
    if (!message) return true;

    this.Rails.stopEverything(e);

    window.alertable({ type: 'warning', message: message});
  }
}
