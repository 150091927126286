import React from 'react'
import Icon  from '../icon'
import Style from './style.sass'

/**
 *  自動スクロールボタン
 *  @version 2018/06/10
 */
export default class Loading extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor (props) {

    super(props);

    this.state = {
      show: false,
      name: props.name,
      body: props.body,
      image: props.image,
    };
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {

    // グローバルからの呼び出し
    window.startLoading = (name, body, image) => {
      
      this.open(name, body, image);
    };

    // グローバルからの呼び出し
    window.closeLoading = () => {

      this.close();
    };
  }

  /**
   *  モーダルを開く
   *  @version 2018/06/10
   */
  open = (name, body, image) => {

    if (name || body || image) {
      this.setState({show: true, name: name, body: body, image: image});
    } else {
      this.setState({ show: true });
    }
  }

  /**
   *  モーダルを閉じる
   *  @version 2018/06/10
   */
  close = () => {

    this.setState({ show: false });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className={Style.Loading}>
        { this.state.show ?
          <div className={Style.Loading__overlay}>
            <div className={Style.Loading__inner}>
              { this.state.image ?
                <div className='u-ta-center'>
                  <img src={this.state.image} className={Style.Loading__image} />
                </div>
                : null
              }
              { this.state.name ? <p className='u-fw-bold u-fs-large u-mb-15'>{this.state.name}</p> : null }
              <p dangerouslySetInnerHTML={{ __html: this.state.body}}></p>
            </div>

            <div className={Style.Loading__text}>Loading...</div>
          </div>
          : null
        }
      </div>
    );
  }
}
