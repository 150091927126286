import React from 'react';
import style from './style';

export const Number = ({
  label,
  placeholder,
  parent_model,
  child_model,
  column,
  value,
  rowNum,
  onChange,
  min,
  max,
  step,
  required
}) => {
  console.log(value);
  return (
    <div className={style.Number__item}>
      <div>
        <label className={style.Number__label} htmlFor='name'>{label}</label>
        { required &&
          <span className={style.Number__required}>必須</span>
        }
      </div>
      <input className={style.Number__input} id={ `${parent_model}_${child_model}_attributes_${rowNum}_${column}`} type='number' placeholder={placeholder} name={`${parent_model}[${child_model}_attributes][${rowNum}][${column}]`} value={value} onChange={(e) => onChange(e ,rowNum, column)} min={min} max={max} step={step} required={required}/>
    </div>
  )
}