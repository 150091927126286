import React   from 'react'
import ReactPlayer from 'react-player'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  @version 2018/06/10
 */
export default class Movie extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);
  }

  componentDidMount() {
    
    window.movie_seek = (e) => {

     let time = e.dataset.time;
      const times = time.split(':');
      if (times.length == 3) {
        time = (Number(times[0]) * 3600) + (Number(times[1]) * 60) + Number(times[2]);
      } else if (times.length == 2) {
        time = (Number(times[0]) * 60) + Number(times[1]);
      } else {
        time = Number(times[0]);
      }

      this.refs.movie.seekTo(time, 'seconds');
      window.scroll({top: 0, behavior: 'smooth'});
    }
  }

  seek = e => {

    let time = e.target.dataset.time;
    const times = time.split(':');
    if (times.length == 3) {
      time = (Number(times[0]) * 3600) + (Number(times[1]) * 60) + Number(times[2]);
    } else if (times.length == 2) {
      time = (Number(times[0]) * 60) + Number(times[1]);
    } else {
      time = Number(times[0]);
    }

    this.refs.movie.seekTo(time, 'seconds');
  }

  /**
   *  閲覧
   *  @version 2018/06/10
   */
  view = () => {

    setTimeout(() => {

      Request.post(`/users/movies/${this.props.movie.id}/view`)
        .set('X-Requested-With', 'XMLHttpRequest')
        .setCsrfToken()
        .end((error, response) => {

          console.log('response', response);
        });
    }, 1000);
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        <ReactPlayer
          url={this.props.url}
          light={this.props.thumb}
          playing
          onStart={this.view}
          controls
          download={false}
          width={960}
          height={540}
          className='react-player u-display-inline-block'
          ref='movie'
        />
      </div>
    );
  }
}
