import React   from 'react'
import Style from '../style.sass'

import { VALUE_TYPES } from '../properties'

import Utilities from '../../../scripts/utilities'

/**
 *  @version 2018/06/10
 */
export default class ApprovalValues extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = {
      values: props.values.length > 0 ? props.values : [{
        value_type: 'string',
        sort: 1,
        name: '',
        required: false,
      }],
    }
  }

  // 項目を追加する
  addValue = () => {

    let values = (this.state.values || []).slice();
    values.push({
      value_type: 'string',
      sort: this.state.values.length + 1,
      name: '',
      required: false,
    });
    this.setState({ values: values });
  }

  // 項目の削除
  removeValue = e => {

    let values = (this.state.values || []).slice();

    values.splice(e.target.dataset.index, 1);

    this.setState({ values: values });
  }

  // 変更時
  changeName = e => {
    
    let values = (this.state.values || []).slice();
    values[e.target.dataset.index].name = e.target.value;

    this.setState({ values: values });
  }

  // 変更時
  changeValueType = e => {
    
    let values = (this.state.values || []).slice();
    values[e.target.dataset.index].value_type = e.target.value;

    this.setState({ values: values });
  }

  // 変更時
  changeSort = e => {
    
    let values = (this.state.values || []).slice();
    values[e.target.dataset.index].sort = e.target.value;

    this.setState({ values: values });
  }

  // 変更時
  changeRequired = e => {
    
    let values = (this.state.values || []).slice();
    values[e.target.dataset.index].required = e.target.value;

    this.setState({ values: values });
  }

  // 値の取得
  getValues = () => {
    return this.state.values;
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className={`${Style.Approval} c-attention u-mt-15`}>

        <label className='u-fw-bold'>稟議の項目</label>
        <p className='u-fc-midBlack u-mt-5 u-fs-small'>稟議の「件名」と「備考」に関してはデフォルトで申請欄に表示されるため、項目として定義する必要はありません。</p>

        { this.state.values.map( (value, i) => {

          return (

            <div key={'value-'+i} className={Style.Approval__item}>

              <div>
                <div className='c-form-label u-mt-15'>
                  <label className='u-fs-small'>項目名</label>
                </div>
                <div>
                  <input type='text' defaultValue={value.name} className='c-form-text' placeholder='項目名を入力してください' data-index={i} onChange={this.changeName} disabled={this.props.disabled} />
                </div>
              </div>

              <div className='u-ml-15'>
                <div className='c-form-label u-mt-15'>
                  <label className='u-fs-small'>値の型</label>
                </div>
                <div className='c-form-selectWrap'>
                  <select className='c-form-select' defaultValue={value.value_type} data-index={i} onChange={this.changeValueType} disabled={this.props.disabled}>
                    { VALUE_TYPES.map((value, index) => {
                      const key = 'value-'+index;
                      return (
                        <option {...{key}} value={value.value}>{value.name}</option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className='u-ml-15'>
                <div className='c-form-label u-mt-15'>
                  <label className='u-fs-small'>必須</label>
                </div>
                <div className='c-form-selectWrap'>
                  <select className='c-form-select' defaultValue={value.required ? 'true' : 'false'} data-index={i} onChange={this.changeRequired} disabled={this.props.disabled}>
                    <option value='false'>任意</option>
                    <option value='true'>必須</option>
                  </select>
                </div>
              </div>

              <div className='u-ml-15'>
                <div className='c-form-label u-mt-15'>
                  <label className='u-fs-small'>表示順序</label>
                </div>
                <div className='c-form-selectWrap'>
                  <select className='c-form-select' defaultValue={value.sort} data-index={i} onChange={this.changeSort} disabled={this.props.disabled}>
                    { Utilities.range(1, 20).map(sort => {
                      const key = 'sort-'+sort;
                      return <option {...{key}} value={sort}>{sort}</option>;
                    })}
                  </select>
                </div>
              </div>

              { !this.props.disabled && this.state.values.length > 1 ?
                <div className='u-ml-15'>
                  <div data-index={i} onClick={e => { this.removeValue(e) }} className={Style.Approval__close}>
                    削除
                  </div>
                </div>
                :
                null
              }
            </div>
          )
        })}

        { !this.props.disabled ? <div className='c-btnMain-standard u-mt-15 c-btnSmall' onClick={this.addValue}>＋ 項目を追加する</div> : null }
      </div>
    );
  }
}
