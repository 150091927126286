/**
 * @version 2018/06/10
 */

export const WEEKS = [
  '日',
  '月',
  '火',
  '水',
  '木',
  '金',
  '土',
];
