export const VALUE_TYPES = [
  { value: 'string', name: '文字列' },
  { value: 'text', name: '文章' },
];

export const STATUSES = [
  { value: 'active', name: '有効' },
  { value: 'inactive', name: '無効' },
];

export const EXPENSE_FLOW_TYPES = [
  { value: 'division_master', name: '指定部署の事業部長' },
  { value: 'division_chief', name: '申請者所属部署の本部長' },
  { value: 'division_manager', name: '申請者所属部署の部長' },
  { value: 'division_anyone', name: '指定部署に所属する人物' },
  { value: 'target_person', name: '特定の人物' },
];
