import React      from 'react'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class ModalSinglePage extends React.Component {

  constructor(props) {

    super(props)

    this.state = {show: false};
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {

    // グローバルからの呼び出し
    window.modalSinglePage = url => {

      this.open(url);
    };

    // グローバルからの呼び出し
    window.closeSinglePage = () => {

      this.close();
    };
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = url => {

    document.body.classList.add('u-cursor-wait');

    this.setState({show: true}, () => {

      if (url.match(/\?/)) {
        modal_page.location.href = url + '&modal=true';
      } else {
        modal_page.location.href = url + '?modal=true';
      }

      document.body.style.overflow = 'hidden';
      document.body.classList.remove('u-cursor-wait');
    });
  }

  /**
   *  モーダルを閉じる
   *  @version 2018/06/10
   */
  close = () => {

    this.setState({show: false}, () => {
      document.body.style.overflow = 'auto';
    });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      this.state.show ?
        <div className={Style.ModalPage} onClick={this.close}>
          <div className={Style.ModalPage__body}>
            <iframe src='' width='100%' height='100%' ref={ node => this.iframeRef = node } name='modal_page' />
          </div>
          <div className={Style.ModalPage__close} onClick={this.close}>×</div>
        </div>
        : null
    );
  }
}
