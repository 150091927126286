import React from 'react'
import Style from './style.sass'

/**
 *  @version 2018/06/10
 */
export default class InfoIcon extends React.Component {

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render () {

    return (
      <div className={Style.InfoIcon}>
      </div>
    );
  }
}
