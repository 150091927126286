import React   from 'react'
import Style   from './style.sass'
import Icon    from '../icon'
import Dayjs   from 'dayjs'
import Timezone from 'dayjs/plugin/timezone';
import Utc from 'dayjs/plugin/utc';
import JapaneseHolidays from 'japanese-holidays';

import Utilities from '../../scripts/utilities'

import { WEEKS } from './properties.es6'

/**
 *  @version 2018/06/10
 */
export default class DatePicker extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    Dayjs.extend(Timezone);
    Dayjs.extend(Utc);
    Dayjs.tz.setDefault('Asia/Tokyo');

    super(props);

    let default_datetime = Dayjs(this.props.default_datetime || new Date());

    // 休日は選択不可設定の場合
    if (this.props.without_holiday) {
      while(this.is_holiday(default_datetime)) {
        default_datetime = default_datetime.add(1, 'day');
      }
    }

    const default_datetime_text = this.props.default_datetime ? Dayjs(this.props.default_datetime).format('YYYY-MM-DD') : null;

    const holidays = JapaneseHolidays.getHolidaysOf(default_datetime.year());

    this.state = {
      show: false,
      default_datetime_text: default_datetime_text,
      current_month: default_datetime,
      current_datetime: default_datetime,
      holidays: holidays,
    };
  }

  /**
   *  日時を適用する
   *  @version 2018/06/10
   */
  _apply = e => {

    let datetime = this.state.current_datetime;

    datetime = datetime.set('hour', 0);
    datetime = datetime.set('minute', 0);

    document.body.style.overflow = 'auto';

    // typeによって処理分け
    if (this.props.type != 'button') {
      this.inputRef.value = datetime.format('YYYY-MM-DD');
    }

    if (this.props.apply) {
      if (typeof this.props.apply == 'string' || this.props.apply instanceof String) {
        eval(this.props.apply + "('" + this.props.id + "','" + datetime.format('YYYY-MM-DD') + "')");
      } else {
        this.props.apply({ datetime: datetime });
      }
    }

    const {close_callback, index} = this.props;
    if (close_callback) {
      close_callback(datetime.format('YYYY-MM-DD'), index);
    this.close();
    } else {
      this.close();
    }
  }

  /**
   *  日時を適用する
   *  @version 2018/06/10
   */
  _clear = e => {

    e.stopPropagation();

    document.body.style.overflow = 'auto';

    // typeによって処理分け
    if (this.props.type != 'button') {
      this.inputRef.value = '';
    }

    if (this.props.apply) {
      if (typeof this.props.apply == 'string' || this.props.apply instanceof String) {
        eval(this.props.apply + "('" + this.props.id + "','')");
      } else {
        this.props.apply({ datetime: null });
      }
    }

    this.close();
  }

  /**
   *  休日判定
   *  @version 2018/06/10
   */
  is_holiday = datetime => {

    // 休日判定
    const holidays = JapaneseHolidays.getHolidaysOf(datetime.year()).filter(h => h.month == (datetime.month() + 1)).map(h => h.date);
    return holidays.includes(datetime.date()) || [0, 6].includes(datetime.day());
  }

  /**
   *  フォームモーダルを表示する
   *  @version 2018/06/10
   */
  open = position => {

    this.setState({ show: true, position: position }, () => {
      document.body.style.overflow = 'hidden';
    });
  }

  /**
   *  フォームモーダルを閉じる
   *  @version 2018/06/10
   */
  close = () => {

    this.setState({ show: false }, () => {
      document.body.style.overflow = 'auto';
    });
  }

  /**
   *  年を変更
   *  @version 2018/06/10
   */
  _changeYear = e => {

    const current_month = this.state.current_month.year(e.target.value);
    const holidays = JapaneseHolidays.getHolidaysOf(current_month.year());

    this.setState({
      current_month: current_month,
      holidays: holidays,
    });
  }

  /**
   *  月を変更
   *  @version 2018/06/10
   */
  _changeMonth = e => {

    const current_month = this.state.current_month.month(e.target.value - 1);
    const holidays = JapaneseHolidays.getHolidaysOf(current_month.year());

    this.setState({
      current_month: current_month,
      holidays: holidays,
    });
  }

  /**
   *  前の月
   *  @version 2018/06/10
   */
  _prev = e => {

    const prev_month = this.state.current_month.subtract(1, 'month');
    const holidays = JapaneseHolidays.getHolidaysOf(prev_month.year());

    this.setState({
      current_month: prev_month,
      holidays: holidays,
    });
  }

  /**
   *  次の月
   *  @version 2018/06/10
   */
  _next = () => {

    const next_month = this.state.current_month.add(1, 'month');
    const holidays = JapaneseHolidays.getHolidaysOf(next_month.year());

    this.setState({
      current_month: next_month,
      holidays: holidays,
    });
  }

  /**
   *  日付を選択する
   *  @version 2018/06/10
   */
  _onChangeDate = e => {

    let current_datetime = this.state.current_datetime;
    current_datetime = current_datetime.set('year', this.state.current_month.year());
    current_datetime = current_datetime.set('month', this.state.current_month.month());
    current_datetime = current_datetime.set('date', e.target.innerText);

    // 休日選択不可の場合
    if (this.props.without_holiday) {
      if (this.is_holiday(current_datetime)) {
        window.alertable({type: 'warning', message: '休日は選択できません。' });
        return false;
      }
    }

    this.setState({current_datetime: current_datetime}, () => {
      this._apply(e);
    });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    const datetime = this.state.current_month;
    const start = datetime.startOf('month');
    const end_of_month = datetime.endOf('month').date();
    const current_week = start.day();

    const current_date = this.state.current_datetime.date();
    const is_current_month = this.state.current_month.month() == this.state.current_datetime.month();
    const is_current_year = this.state.current_month.year() == this.state.current_datetime.year();

    const holidays = this.state.holidays.filter(h => h.month == (this.state.current_month.month() + 1)).map(h => h.date);

    return (
      <React.Fragment>
        { this.state.show ?
          <div className={Style.DatePicker} onMouseDown={this.close}>
            <div className={Style.DatePicker__body} onMouseDown={ e => { e.stopPropagation() } }>

              <div className={Style.DatePicker__header}>

                <div className={Style.DatePicker__selectWrap}>
                  <select className={`${Style.DatePicker__select} u-fs-base`} value={datetime.year()} onChange={this._changeYear}>
                    { (this.props.years_range || Utilities.range(2000, 2060)).map(year => {
                      const key = `year-${year}`;
                      return <option key={key} value={year}>{year}</option>;
                    })}
                  </select>
                </div>
                <div className='u-ml-5'>年</div>

                <div className={`${Style.DatePicker__selectWrap} u-ml-20`}>
                  <select className={`${Style.DatePicker__select} u-fs-base`} value={datetime.month() + 1} onChange={this._changeMonth}>
                    { Utilities.range(1, 12).map(month => {
                      const key = `month-${month}`;
                      return <option key={key} value={month}>{month}</option>;
                    })}
                  </select>
                </div>
                <div className='u-ml-5'>月</div>

                <div onClick={this.close} className={Style.DatePicker__closeIcon}>
                  <Icon name='close' size='m' color='white'/>
                </div>
              </div>
              <div className={Style.DatePicker__main}>
                <div onClick={this._prev} className={Style.DatePicker__prev}>前の月</div>
                <div onClick={this._next} className={Style.DatePicker__next}>次の月</div>
                <table className={Style.DatePicker__calendar}>
                  <thead>
                    <tr>
                      { /* 曜日 */ }
                      { Utilities.range(0, 6).map(week => {
                        return <th key={`week-${week}`}>{WEEKS[week]}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    { /* 週ごとのレコード */ }
                    <React.Fragment>
                      <tr>
                        { Utilities.range(0, 6).map(week => {

                          const target_date = week - current_week + 1;
                          const is_active = current_date == target_date && is_current_month && is_current_year;
                          return ( current_week > week ?
                            <td key={`week-${week}`}></td>
                            :
                            <td key={`week-${week}`}>
                              <div className={`${is_active ? Style.DatePicker__active : Style.DatePicker__date}`} onClick={this._onChangeDate}>
                                {target_date}
                              </div>
                            </td>
                          );
                        })}
                      </tr>

                      { /* 日付 */ }
                      { Utilities.range(1, Math.ceil(end_of_month / 7)).map(weeks => {

                        const start_of_date = (weeks * 7) - current_week + 1;

                        return (
                          <tr key={`weeks-${start_of_date}`}>
                            { Utilities.range(start_of_date, start_of_date + 6).map(date => {

                              const is_active = current_date == date && is_current_month && is_current_year;
                              return ( end_of_month >= date ?
                                <td key={`date-${date}`}>
                                  <div className={`${is_active ? Style.DatePicker__active : Style.DatePicker__date} ${holidays.includes(date) ? 'u-fc-red' : null}`} onClick={this._onChangeDate}>
                                    {date}
                                  </div>
                                </td>
                                :
                                <td key={`date-${date}`}></td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </React.Fragment>
                  </tbody>
                </table>
                <div onClick={this._clear} className='u-mt-10 c-btnMain-negative u-display-block'>クリアする</div>
              </div>
            </div>
          </div>
          : null
        }
        { this.props.type == 'button' ?
          <div className='c-btnMain-standard u-display-block' onClick={this.open}>
            <Icon name='calendar' size='s' color='white'/>
            <span className='u-ml-5'>日付を選択する</span>
          </div>
          :
          <div className={Style.DatePicker__input}>
            <input type='text' className='c-form-text' name={this.props.name} id={this.props.id} ref={ node => this.inputRef = node} defaultValue={this.state.default_datetime_text || ''} onClick={this.open} readOnly={true} placeholder='日付を選択する' required={this.props.required ? 'required' : null} />
            <Icon name='calendar' size='s' color='main'/>
          </div>
        }
      </React.Fragment>
    );
  }
}
