import React      from 'react'
import Modal from '../utilities/modal'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class BusinessCard extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = {
      time: null,
      shutter: false,
    };
  }

  /**
   *  submit処理
   *  @version 2018/06/10
   */
  _onSubmit = e => {

    this.close_video();
    window.startLoading();

    setTimeout(() => {

      this.refs.video_canvas.toBlob(blob => {

        let formData = new FormData();
        formData.append('image', blob);

        // 会社情報の取得
        Request.post(`https://api.masouken.com/api/business_card_v1`)
          .send(formData)
          .set('X-Requested-With', 'XMLHttpRequest')
          .setCsrfToken()
          .end((error, response) => {

            if (response.body.status == 'success') {
              const business_card = response.body.business_card;
              const params = [];
              if (business_card.name) params.push(`name=${encodeURIComponent(business_card.name)}`);
              if (business_card.post) params.push(`post=${encodeURIComponent(business_card.post)}`);
              if (business_card.tel) params.push(`tel=${encodeURIComponent(business_card.tel)}`);
              if (business_card.email) params.push(`email=${encodeURIComponent(business_card.email)}`);
              if (business_card.company_name) params.push(`company_name=${encodeURIComponent(business_card.company_name)}`);
              location.href = `/users/company_clients/new?has_card=1&${params.join('&')}`;
            } else {
              window.alertable({ type: 'error', message: '正しく読み取れませんでした。' });
              window.closeLoading();
            }
          });
      });
    }, 300);
  }

  /**
   *  submit処理
   *  @version 2018/06/10
   */
   _onFileSubmit = e => {

    window.startLoading();

    let formData = new FormData();
    formData.append('image', this.refs.file.files[0]);

    // 会社情報の取得
    Request.post(`https://api.masouken.com/api/business_card_v1`)
      .send(formData)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {
        if (response.body.status == 'success') {
          const business_card = response.body.business_card;
          const params = [];
          if (business_card.name) params.push(`name=${encodeURIComponent(business_card.name)}`);
          if (business_card.post) params.push(`post=${encodeURIComponent(business_card.post)}`);
          if (business_card.tel) params.push(`tel=${encodeURIComponent(business_card.tel)}`);
          if (business_card.email) params.push(`email=${encodeURIComponent(business_card.email)}`);
          if (business_card.company_name) params.push(`company_name=${encodeURIComponent(business_card.company_name)}`);
          location.href = `/users/company_clients/new?has_card=1&${params.join('&')}`;
        } else {
          window.alertable({ type: 'error', message: '正しく読み取れませんでした。' });
          window.closeLoading();
        }
      });
  }

  /**
   *  かめらを止める
   *  @version 2018/06/10
   */
  close_video = () => {

    let camera_video = document.getElementById('video').srcObject;

    camera_video.getTracks().forEach(track => {
      track.stop();
    });
    camera_video = null;
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  camera_open = () => {

    this.setState({time: null, shutter: false}, () => {
      this.refs.camera_modal.open();
      navigator.mediaDevices.getUserMedia({video: true, audio: false})	
        .then(stream => {
          this.refs.video.srcObject = stream;
        }).catch(error => { // 失敗時の処理はこちら.
          console.error('mediaDevice.getUserMedia() error:', error);
          return;
        });
    })
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
   file_open = () => {

    this.refs.file_modal.open();
  }

  /**
   *  シャッター
   *  @version 2018/06/10
   */
  shutter = () => {

    const canvas = this.refs.video_canvas;
    canvas.style.display = 'none';

    this.setState({time: 3, shutter: true}, () => {
      setTimeout(() => {
        this.setState({time: 2}, () => {
          setTimeout(() => {
            this.setState({time: 1}, () => {
              setTimeout(() => {
                this.setState({time: null}, () => {
                  const video = this.refs.video;
                  const canvas = this.refs.video_canvas;
                  const ctx = canvas.getContext('2d');
                  canvas.setAttribute('width', video.offsetWidth);	// canvasに書き出すための横幅セット.
                  canvas.setAttribute('height', video.offsetHeight);
                  ctx.drawImage(video, 0, 0, video.offsetWidth, video.offsetHeight);

                  canvas.style.display = 'block';
                })
              }, 1000);
            })
          }, 1000);
        })
      }, 1000);
    })
  }

  /**
   *  カメラ
   *  @version 2018/06/10
   */
  camera_content() {

    return (
      <div>
        <div className={Style.BusinessCard__rec}>
          
          <video className={Style.BusinessCard__video} id='video' ref='video' autoPlay></video>
          <canvas className={Style.BusinessCard__canvas} ref='video_canvas'></canvas>
          { !this.state.shutter ? <div className={Style.BusinessCard__button} onClick={this.shutter}>撮影</div> : null }
          { !this.state.time && this.state.shutter ? <div className={Style.BusinessCard__button} onClick={this.shutter}>再撮影</div> : null }
          { this.state.time ? <div className={Style.BusinessCard__count}>{this.state.time}</div> : null }
        </div>

        { !this.state.time && this.state.shutter ? <div className='c-btnMain-primary u-display-block' onClick={() => { this._onSubmit() }}>この画像で登録する</div> : null }
      </div>
    );
  }

  /**
   *  ファイル
   *  @version 2018/06/10
   */
   file_content() {

    return (
      <div className='u-mt-30'>
        <input type='file' ref='file'/>
        <div className='c-btnMain-primary u-display-block u-mt-30' onClick={() => { this._onFileSubmit() }}>この画像で登録する</div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className='u-mt-15'>
        <a className='c-btnMain-primary' href='/users/company_clients/new'>直通担当者を作成する</a>
        <span onClick={this.camera_open} className='c-btnMain-primary u-ml-15'>カメラでスキャンする</span>
        <span onClick={this.file_open} className='c-btnMain-primary u-ml-15'>画像をスキャンする</span>
        <Modal ref='camera_modal' yield={this.camera_content()} close_callback={this.close_video} />
        <Modal ref='file_modal' yield={this.file_content()} />
      </div>
    );
  }
}
