import React    from 'react'
import Template from '../template'
import Style    from './style.sass'

import { TYPES, BUTTON_COLORS } from './properties.es6'

/**
 *  @version 2018/06/10
 */
export default class Link extends Template {

  /**
   *  初期化時のstate
   *  @version 2018/06/10
   */
  defaultState(props) {

    return {
      item_type: props.item.value.type || 'text',
      id: props.item.value.id || null,  // 内部リンク時に使用
      color: props.item.value.color || 'default',
    };
  }

  /**
   *  アイテム情報取得用
   *  @version 2018/06/10
   */
  reference = () => {

    const text = this.state.item.value.text || '';
    return {
      text:   text,
      length: text.length,
    };
  }

  /**
   *  タイトル
   *  @version 2018/06/10
   */
  templateComponentTitle() { return 'リンク' }

  /**
   *  検索結果の記事情報を取得する
   *  @version 2018/06/10
   */
  getFromSearch = article => {

    this.titleRef.value = article.title;

    // アイテム情報更新
    this.setState({ id: article.id });
  }

  /**
   *  アイテムの追加
   *  @version 2018/06/10
   */
  add = () => {

    // リンク先
    const href = (this.hrefRef.value.match(/^[0-9]+$/) ? '#' : '') + this.hrefRef.value;

    if (this.textRef.value == '' || href == '') {
      window.alertable({ type: 'warning', message: '未入力項目があります' });
      return false;
    }

    this._updateItem({
      type: this.state.item_type,
      text: this.textRef.value,
      href: href,
      color: this.state.color,
    });
  }

  /**
   *  選択されたされたタイプをセットする
   *  @version 2018/06/10
   */
  _changeItemType = e => {

    this.setState({item_type: e.target.value});
  }

  /**
   *  編集時表示
   *  @version 2018/06/10
   */
  templateEdittingContent() {

    return (
      <div className={Style.Link}>
        <div className='u-mt-10 c-form-selectWrap'>
          <select className='c-form-select' onChange={this._changeItemType} defaultValue={this.state.item_type}>
            { TYPES.map((type, i) => {
              return <option key={`type-${i}`} value={type.value}>{type.name}</option>;
            })}
          </select>
        </div>

        <div>
          <div className='u-mt-10'>
            <label className='u-fc-darkGray u-fs-s'>リンク先のURL</label>
            <span className='c-form-required u-ml-10'>必須</span>
            <input type='text' className='c-form-text' ref={ node => this.hrefRef = node} defaultValue={this.props.item.value.href} placeholder='リンク先のURL (#1 のように記事内のIDを指定することも可能です)' />
            <span className='u-fc-orange u-fs-s'>※リンク先のURLはページ内アイテムのID、もしくはhttpsから始まるURLでなければいけません。</span>
          </div>
          <div className='u-mt-10'>
            <label className='u-fc-darkGray u-fs-s'>表示するテキスト</label>
            <span className='c-form-required u-ml-10'>必須</span>
            <input type='text' className='c-form-text' ref={ node => this.textRef = node} defaultValue={this.props.item.value.text} placeholder='リンクとして表示するテキスト' />
          </div>

          { this.state.item_type == 'button' ?
            <div className='u-mt-10'>
              <label className='u-fc-darkGray u-fs-s'>ボタンの色</label>
              <div className='u-mt-10'>

                <label className={Style.Link__radioLabel}>
                  <input type='radio' checked={this.state.color == 'default'} onChange={() => this.setState({color: 'default'})} className={Style.Link__radio} />
                  <i className={Style.Link__radioIcon} />
                </label>

                { /* ボタンカラー */ }
                { BUTTON_COLORS.map((color, i) => {
                  return (
                    <label key={`color-${i}`} className={`${Style.Link__radioLabel} u-ml-5`}>
                      <input type='radio' checked={this.state.color == color} onChange={() => this.setState({color: color})} className={Style.Link__radio} />
                      <i className={`${Style.Link__radioIcon} ${Style['Link__radioIcon--' + color]}`} />
                    </label>
                  );
                })}
              </div>
            </div>
            : null
          }
        </div>
      </div>
    );
  }

  /**
   *  プレビュー表示
   *  @version 2018/06/10
   */
  templatePreviewContent() {

    const { id, type, href, text, color } = this.props.item.value;

    return (
      <div className='p-article__link'>

        { /* テキストリンク */ }
        { type == 'text' ?
          <a className='p-article__linkText' href={href} target='_blank'>{text}</a>
          : null
        }

        { /* ボタンリンク */ }
        { type == 'button' ?
          <a className={`c-btnMain-standard u-fw-bold ${color ? 'c-btn-'+color : null }`} href={href} target='_blank'>{text}</a>
          : null
        }
      </div>
    );
  }
}
