import React from 'react';
import style from './style';

export const Radiobutton = ({label, parent_model, child_model, column, value, rowNum, onChange, required}) => {
  return (
    <div>
      <div className={style.Selectbox__item} >
        <div>
          <input type='checkbox' id={ `${parent_model}_${child_model}_attributes_${rowNum}_${column}`} name={`${parent_model}[${child_model}_attributes][${rowNum}][${column}]`} value={true} checked={value === true} onChange={(e) => onChange(e ,rowNum, column)}/>
          <label className={style.Selectbox__label} htmlFor='name'>{label}</label>
          { required &&
            <span className={style.Selectbox__required}>必須</span>
          }
        </div>
      </div>
    </div>
  )
}