import React from 'react';
import style from './style';

export const Textarea = ({
  label,
  placeholder,
  parent_model,
  child_model,
  column,
  value,
  rowNum,
  onChange,
  rows = 10,
  cols = 1,
  required
}) => {
  return (
    <div className={style.Textarea__item} style={{width: `calc(${100 / cols + '%'} - ${20 * cols}px)`}}>
      <div>
        <label className={style.Textarea__label} htmlFor='name'>{label}</label>
        { required &&
          <span className={style.Textarea__required}>必須</span>
        }
      </div>
      <textarea className={style.Textarea__input} id={ `${parent_model}_${child_model}_attributes_${rowNum}_${column}`} placeholder={placeholder} name={`${parent_model}[${child_model}_attributes][${rowNum}][${column}]`} value={value} onChange={(e) => onChange(e ,rowNum, column)} rows={rows} required={required}/>
    </div>
  )
}