import React      from 'react'
import Icon    from '../icon'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

import Dropzone from 'react-dropzone'

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class FileTree extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor (props) {

    super(props);

    this.state = {
      file_directories: this.props.file_directories || [],
      uploads: this.props.uploads || [],
      drop_show: false,
      current_file_directory_id: null,
      parent_file_directory_id: null,
      current_file_directory_name: '',
    }
  }

  /**
   *  ディレクトリを開く
   *  @version 2018/06/10
   */
  open = e => {

    let file_directories = JSON.parse(JSON.stringify(this.state.file_directories));
    let target = this.search(file_directories, e.target.dataset.id);
    target.open = !target.open;
    this.setState({file_directories: file_directories})
  }

  /**
   *  ディレクトリ走査
   *  @version 2018/06/10
   */
  search = (file_directories, id) => {

    for (let file_directory of file_directories) {

      if (file_directory.id == id) {
        return file_directory;
      } else {
        if ((file_directory.file_directories || []).length > 0) {
          let result = this.search(file_directory.file_directories, id);
          if (result) return result;
        }
      }
    }

    return false;
  }

  /**
   *  ディレクトリ走査
   *  @version 2018/06/10
   */
   search_file = (file_directories, id) => {

    for (let file_directory of file_directories) {
      for (let upload of file_directory.uploads) {
        if (upload && upload.id == id) return upload;
      }
    }

    return false;
  }

  /**
   *  編集
   *  @version 2018/06/10
   */
  edit = e => {

    if (!this.refs.folder_name.value || this.refs.folder_name.value == '') {
      window.alertable({ type: 'warning', message: 'フォルダ名を入力してください' });
      return false;
    }
    
    let field = { 'file_directory[name]': this.refs.folder_name.value };
    if (this.state.parent_file_directory_id) field['file_directory[parent_id]'] = this.state.parent_file_directory_id;
    if (this.state.current_file_directory_id) field['id'] = this.state.current_file_directory_id;

    // 編集
    Request.post('/users/file_directories')
      .field(field)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {

          let file_directories = JSON.parse(JSON.stringify(this.state.file_directories));

          // 名前の更新のみの場合
          if (this.state.current_file_directory_id) {

            let target = this.search(file_directories, this.state.current_file_directory_id);
            target.name = response.body.file_directory.name;

          } else {

            let target = this.search(file_directories, this.state.parent_file_directory_id);
            let file_directory = response.body.file_directory;
            file_directory.open = true;

            if (target) {
              if (!target.file_directories) {
                target.file_directories = [];
              }
              target.file_directories.push(file_directory);
            } else {
              file_directories.push(file_directory);
            }
          }

          this.setState({edit_show: false, file_directories: file_directories})

          window.closeLoading();
        } else {

          window.alertable({ type: 'error', message: response.body.message });
          window.closeLoading();
        }
      });
  }

  /**
   *  フォルダー追加
   *  @version 2018/06/10
   */
  addFolder = e => {

    this.setState({
      edit_show: !this.state.edit_show,
      parent_file_directory_id: e.target.dataset.parent,
      current_file_directory_id: e.target.dataset.id,
      current_file_directory_name: e.target.dataset.name,
    });
  }

  /**
   *  削除
   *  @version 2018/06/10
   */
  deleteFolder = e => {

    if (!window.confirm('対象のフォルダを削除しますか?')) {
      return false;
    }

    let id = e.target.dataset.id;

    Request.delete(`/users/file_directories/${id}`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {

          let file_directories = JSON.parse(JSON.stringify(this.state.file_directories));
          let target = this.search(file_directories, id);
          target.deleted = true;
          this.setState({file_directories: file_directories})

          window.closeLoading();
        } else {

          window.alertable({ type: 'error', message: response.body.message });
          window.closeLoading();
        }
      });
  }

  /**
   *  ファイル追加
   *  @version 2018/06/10
   */
  addFile = e => {

    this.setState({
      drop_show: !this.state.drop_show,
      current_file_directory_id: e.target.dataset.id,
    });
  }

  /**
   *  削除
   *  @version 2018/06/10
   */
   deleteFile = e => {

    if (!window.confirm('対象のファイルを削除しますか?')) {
      return false;
    }

    let id = e.target.dataset.id;

    Request.delete(`/users/uploads/${id}`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {

          let file_directories = JSON.parse(JSON.stringify(this.state.file_directories));
          let target = this.search_file(file_directories, id);
          if (target) {
            target.deleted = true;
            this.setState({file_directories: file_directories});
          } else {
            let uploads = JSON.parse(JSON.stringify(this.state.uploads));
            for (let upload of uploads) {
              if (upload.id == id) upload.deleted = true;
            }
            this.setState({uploads: uploads});
          }

          window.closeLoading();
        } else {

          window.alertable({ type: 'error', message: response.body.message });
          window.closeLoading();
        }
      });
  }

  /**
   *  submit処理
   *  @version 2018/06/10
   */
   _uploadOpen = e => {

    e.stopPropagation();
    this.refs.upload_modal.open();
  }

  /**
   *  ファイルドロップ時
   *  @version 2018/06/10
   */
  _onDrop = files => {

    window.startLoading();

    let fields = {};

    if (this.state.current_file_directory_id) fields['upload[file_directory_id]'] = this.state.current_file_directory_id;

    // 編集
    Request.post('/users/uploads')
      .field(fields)
      .attach('upload[file]', files[0])
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {

          let file_directories = JSON.parse(JSON.stringify(this.state.file_directories));
          let target = this.search(file_directories, this.state.current_file_directory_id);
          let upload = response.body.upload;
          upload.url = response.body.url;

          // ディレクトリが指定されている場合
          if (target.uploads) {

            target.uploads.push(upload);
            this.setState({drop_show: false, file_directories: file_directories})

          // ディレクトリが指定されていない場合はルートに追加
          } else if (target == false) {

            let uploads = this.state.uploads.slice();
            uploads.push(upload);
            this.setState({drop_show: false, uploads: uploads})

          // ディレクトリが指定されているが、その中にファイルが存在しない場合
          } else {
            target.uploads = [upload];
            this.setState({drop_show: false, file_directories: file_directories})
          }

          window.closeLoading();
        } else {

          window.alertable({ type: 'error', message: response.body.message });
          window.closeLoading();
        }
      });
  }

  /**
   *  ディレクトリ
   *  @version 2018/06/10
   */
   content = file_directory => {

    if (file_directory.deleted) return null;

    return (
      <li className='p-categories__item' key={`upload-${file_directory.id}`}>
        <div className='p-categories__wrap'>
          <div className='p-categories__folder' data-id={file_directory.id} onClick={this.open}>
            { file_directory.open ?
              <Icon name='folder-open' size='m' color='blue' />
              :
              <span className='p-categories__folderIcon'>
                <Icon name='folder' size='m' color='blue' />
              </span>
            }
            <span className='u-ml-5 u-va-middle'>{file_directory.name}</span>
          </div>
          <div className='u-ml-5' data-id={file_directory.id} data-name={file_directory.name} onClick={this.addFolder}>
            <Icon name='pen' size='s' color='gray' className='u-pointer-events-none' />
          </div>
          <div className='u-ml-5' data-id={file_directory.id} onClick={this.deleteFolder}>
            <Icon name='close' size='xxs' color='gray' className='u-pointer-events-none' />
          </div>
        </div>
        { file_directory.open ?
          <ul className='p-categories__list'>
            { (file_directory.file_directories || []).map( file_directory => {
              return this.content(file_directory);
            })}
            <li className='p-categories__item'>
              <div className='u-ml-5 p-categories__folder' data-parent={file_directory.id} onClick={this.addFolder}>
                <Icon name='folder-add' size='s' color='gray' />
                <span className='u-fc-darkGray u-ml-5 u-fs-small'>フォルダ作成</span>
              </div>
            </li>
            { (file_directory.uploads || []).map( upload => {
              return this.file(upload);
            })}
            <li className='p-categories__item'>
              <div className='u-ml-5 p-categories__folder' data-id={file_directory.id} onClick={this.addFile}>
                <Icon name='add' size='s' color='gray' />
                <span className='u-fc-darkGray u-ml-5 u-fs-small'>ファイル追加</span>
              </div>
            </li>
          </ul>
          : null
        }
      </li>
    );
  }

  /**
   *  ファイル
   *  @version 2018/06/10
   */
  file = upload => {

    if (upload.deleted) return null;

    return (
      <li className='p-categories__item' key={`upload-${upload.id}`}>
        <div className='p-categories__wrap'>
          <a className='p-categories__folder' href={upload.url} target='_blank'>
            <Icon name='document' size='m' color='black' class='u-va-middle' />
            <span className='u-ml-5 u-va-middle'>{upload.name}</span>
          </a>
          <div className='u-ml-5' data-id={upload.id} onClick={this.deleteFile}>
            <Icon name='close' size='xxs' color='gray' className='u-pointer-events-none' />
          </div>
        </div>
      </li>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    const {file_directories, uploads} = this.state;

    return (
      <div>
        <ul>

          { (file_directories || []).map( file_directory => {
            return this.content(file_directory);
          })}

          <li className='p-categories__item'>
            <div className='u-ml-5 p-categories__folder' onClick={this.addFolder}>
              <Icon name='folder-add' size='s' color='gray' />
              <span className='u-fc-darkGray u-ml-5 u-fs-small'>フォルダ作成</span>
            </div>
          </li>

          { (uploads || []).map( upload => {
            return this.file(upload);
          })}

          <li className='p-categories__item'>
            <div className='u-ml-5 p-categories__folder' onClick={this.addFile}>
              <Icon name='add' size='s' color='gray' />
              <span className='u-fc-darkGray u-ml-5 u-fs-small'>ファイル追加</span>
            </div>
          </li>
        </ul>

        { this.state.drop_show ?
          <div className={Style.FileTree__uploadOverlay} onMouseDown={() => { this.setState({drop_show: false, current_file_directory_id: null}); }}>
            <div className={Style.FileTree__uploadInner} onMouseDown={ e => e.stopPropagation() }>
              <Dropzone onDrop={this._onDrop}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()} className={Style.FileTree__dropzone}>
                    <input {...getInputProps()} />
                    <p>この部分にファイルをドラッグ&ドロップしてください</p>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
          : null
        }

        { this.state.edit_show ?
          <div className={Style.FileTree__editOverlay} onMouseDown={() => { this.setState({edit_show: false, parent_file_directory_id: null, current_file_directory_id: null, current_file_directory_name: ''}); }}>
            <div className={Style.FileTree__editInner} onMouseDown={ e => e.stopPropagation() }>
              <input type='text' defaultValue={this.state.current_file_directory_name} className='c-form-text' style={{width: '80%'}} placeholder='フォルダ名を入力してください' ref='folder_name'/>
              <div className='c-btnMain-primary u-ml-10' style={{width: '15%'}} onClick={this.edit}>送信</div>
            </div>
          </div>
          : null
        }
      </div>
    );
  }
}
