import React      from 'react'
import Modal from '../utilities/modal'
import Style from './style.sass'

import TelDetail    from '../tel_detail'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class TelReport extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      company_id: null,
      name: null,
      redirect_to: null,
      group_company_id: null,
    }
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {

    // グローバルからの呼び出し
    window.openTel = (company_id, name, redirect_to=null, company_company_id=null, group_company_id=null) => {

      this.setState({company_id: company_id, name: name, redirect_to: redirect_to, company_company_id: company_company_id, group_company_id: group_company_id}, () => {
        this.refs.tel_modal.open();
      });
    };

    // グローバルからの呼び出し
    window.closeTel = () => {

      this.refs.tel_modal.close();
    };
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    return (
      <div>
        <p className='u-fs-large u-mt-30'><span className='u-fw-bold'>{this.state.name}</span>へのTEL内容を記述してください</p>
        <div className={Style.TelReport}>
          <div className={Style.TelReport__inner}>

            <TelDetail
              company_id={this.state.company_id}
              name={this.state.name}
              redirect_to={this.state.redirect_to}
              group_company_id={this.state.group_company_id}
              company_company_id={this.state.company_company_id}
              callback={window.closeTel}
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <Modal ref='tel_modal' yield={this.content()}/>
    );
  }
}
