import React from 'react'
import Style from './style.sass'
import Icon  from 'users/icon'

// テキストエディタ
import Wysiwyg from 'users/editor/wysiwyg'

/**
 *  @version 2018/06/10
 */
export default class DataEditor extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = {
      show: false,
    }
  }

  /**
   *  検索モーダルを表示する
   *  @version 2018/06/10
   */
  _open = data => {

    //this.setState({ data: data, show: true });

    this.setState({ show: true, ...data }, () => document.body.style.overflow = 'hidden');
  }

  /**
   *  検索モーダルを閉じる
   *  @version 2018/06/10
   */
  _close = () => {

    this.setState({ show: false, text: null, title: null, src: null, color: null }, () => {
      document.body.style.overflow = 'auto';
    });
  }

  /**
   *  ファイルドロップ時
   *  @version 2018/06/10
   */
  _onDrop = files => {

    this.setState({ src: (window.URL || window.webkitURL).createObjectURL(files[0]) });
  }

  /**
   *  内容の確定
   *  @version 2018/06/10
   */
  decision = () => {

    let data = this.state;
    delete data.show;

    // テキストの場合
    if (this.state.type == 'text') {

      const text = this.textRef.content();
      data.text = text;

    // リンクの場合
    } else if (this.state.type == 'link') {

      const src = (this.srcRef.value.match(/^[0-9]+$/) ? '#' : '') + this.srcRef.value;

      if (this.titleRef.value == '' || src == '') {
        window.alertable({ type: 'warning', message: '未入力の項目があります。' });
        return false;
      }

      data.title = this.titleRef.value;
      data.src   = src;
      data.color = this.state.color;
    }

    document.body.style.overflow = 'auto';
    this.props.decision({...data});
    this._close();
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return ( this.state.show ?
      <div onMouseDown={this._close}>
        <div className={Style.DataEditor}>
          <div className={Style.DataEditor__body} onMouseDown={e => e.stopPropagation()}>

            <div className={Style.DataEditor__main}>

              { /* 種類 */ }
              <div className='u-mt-10'>
                <div className='u-mt-10'>
                  <label className='c-form-radioLabel'>
                    <input name='type' type='radio' defaultChecked={this.state.type == 'text'} onChange={() => this.setState({type: 'text', src: null})} className='c-form-radio' />
                    <i className='c-form-radioIcon' />
                    <span>テキスト</span>
                  </label>
                  <label className='c-form-radioLabel u-ml-15'>
                    <input name='type' type='radio' defaultChecked={this.state.type == 'link'} onChange={() => this.setState({type: 'link', src: null})} className='c-form-radio' />
                    <i className='c-form-radioIcon' />
                    <span>ボタン</span>
                  </label>
                </div>
              </div>

              { this.state.type == 'text' ?

                <div className='u-mt-10'>
                  <label className='u-fc-darkGray u-fs-s'>テキスト</label>
                  <Wysiwyg ref={ node => this.textRef = node} content={this.state.text} placeholder='文章を入力してください。' />
                </div>
                : null
              }

              { this.state.type == 'link' ?

                <div>
                  <div className='u-mt-10'>
                    <label className='u-fc-darkGray u-fs-s'>テキスト</label>
                    <input type='text' className='c-form-text' ref={ node => this.titleRef = node} defaultValue={this.state.title || ''} placeholder='テキスト' />
                  </div>
                  <div className='u-mt-10'>
                    <label className='u-fc-darkGray u-fs-s'>リンク先URL</label>
                    <input type='text' className='c-form-text' ref={ node => this.srcRef = node} defaultValue={this.state.src || ''} placeholder='リンク先URL' />
                  </div>
                  <div className='u-mt-10'>
                    <label className='u-fc-darkGray u-fs-s'>ボタンの色</label>
                    <div className='u-mt-10'>
                      <label className={Style.DataEditor__radioLabel}>
                        <input type='radio' checked={!this.state.color || this.state.color == 'default'} onChange={() => this.setState({color: 'default'})} className={Style.DataEditor__radio} />
                        <i className={Style.DataEditor__radioIcon} />
                      </label>
                      <label className={`${Style.DataEditor__radioLabel} u-ml-5`}>
                        <input type='radio' checked={this.state.color == 'orange'} onChange={() => this.setState({color: 'orange'})} className={Style.DataEditor__radio} />
                        <i className={`${Style.DataEditor__radioIcon} ${Style.DataEditor__radioIconOrange}`} />
                      </label>
                      <label className={`${Style.DataEditor__radioLabel} u-ml-5`}>
                        <input type='radio' checked={this.state.color == 'blue'} onChange={() => this.setState({color: 'blue'})} className={Style.DataEditor__radio} />
                        <i className={`${Style.DataEditor__radioIcon} ${Style.DataEditor__radioIconBlue}`} />
                      </label>
                      <label className={`${Style.DataEditor__radioLabel} u-ml-5`}>
                        <input type='radio' checked={this.state.color == 'red'} onChange={() => this.setState({color: 'red'})} className={Style.DataEditor__radio} />
                        <i className={`${Style.DataEditor__radioIcon} ${Style.DataEditor__radioIconRed}`} />
                      </label>
                    </div>
                  </div>
                </div>
                : null
              }

              <div onClick={this.decision} className='c-btnMain-standard u-mt-30'>適用する</div>
            </div>

            <div className={Style.DataEditor__close} onClick={this._close}>
              <Icon name='close' size='l' color='darkGray'/>
            </div>
          </div>
        </div>
      </div>
      : null
    );
  }
}
