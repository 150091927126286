import React      from 'react'
import Style from './style.sass'

import KnowledgeTree from './knowledge_tree'
import KnowledgeFrame from './knowledge_frame'

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class Knowledge extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor (props) {

    super(props);
  }

  /**
   * マウント直後
   */
  componentDidMount() {}

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className='u-display-flex'>
        <div className={Style.KnowledgeTree}>
          <KnowledgeTree
            knowledge_manage={this.props.knowledge_manage}
            knowledge_directories={this.props.knowledge_directories}
            knowledges={this.props.knowledges}
          />
        </div>
        <div className={Style.KnowledgeFrame}>
          <KnowledgeFrame
            knowledge_id={this.props.knowledge_id}
            keyword={this.props.keyword}
          />
        </div>
      </div>
    );
  }
}
