/**
 * @version 2018/06/10
 */

export const TYPES = [
  {name: '通常', value: 'text'},
  {name: 'ボタン', value: 'button'},
];

export const BUTTON_COLORS = [
  'blue',
  'green',
  'orange',
  'red',
];
