import React from 'react'
import Modal from '../utilities/modal'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  自動スクロールボタン
 *  @version 2018/06/10
 */
export default class Regulations extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = {
      check_count: 0,
    };
  }

  onClickUrl = e => {

    window.open(e.target.dataset.url);

    if (e.target.dataset.checked == 'false') {
      e.target.dataset.checked = 'true';
      this.setState({check_count: this.state.check_count + 1})
    }
  }

  agree = () => {

    if (this.props.regulations.length <= this.state.check_count) {

      window.startLoading();
      // 規約への同意
      Request.post(`/users/users/agree`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {

          window.closeLoading();
          this.refs.modal.close();
        } else {

          window.alertable({ type: 'error', message: 'エラーにより同意ができませんでした。' });
        }
      });
      
    } else {
      window.alertable({ type: 'warning', message: '全ての規約に目を通した上で同意してださい。' });
    }
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    const { regulations } = this.props;

    return (
      <div className={Style.Regulations}>
        <div className={Style.Regulations__title}>規約への同意</div>
        <div className={Style.Regulations__message}>
          <div className='u-fc-red'>システムを利用するには規約への同意が必要です。</div>
          <div className='u-fc-darkGray u-fs-small'>※規約への同意は、規約の変更時にも必要です。</div>
          { regulations.map(regulation => {
            return <div key={regulation.id} className={Style.Regulations__link} data-checked={false} data-url={regulation.url} onClick={this.onClickUrl}>{regulation.name}</div>
          })}
        </div>

        <div className='u-ta-left u-mt-20 u-mb-30'>
          <div className='c-btnMain-standard u-display-block' onClick={this.agree}>規約に同意する</div>
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return <Modal ref='modal' show={true} yield={this.content()}/>;
  }
}
