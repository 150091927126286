import React from 'react'
import Select from 'react-select'

/**
 *  自動スクロールボタン
 *  @version 2018/06/10
 */
export default class SuggestSelect extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.options = this.props.options ? this.props.options.map(option => {
      return {value: option[1], label: option[0]};
    }) : [{}];

    let selected = this.props.selected;

    if (selected && !Array.isArray(selected)) {
      selected = String(selected).split(',');
    }

    let selectedOption = [];
    if (selected) {
      selectedOption = selected.map(select => {
        return (this.options.filter(option => {
          return select == option.value;
        })[0]);
      })
    }

    this.state = {
      selectedOption: selectedOption,
    };
  }

  handleChange = selectedOption => {

    this.setState({ selectedOption: [selectedOption] });

    // コールバックの実行
    if (this.props.onChange) {

      if (typeof this.props.onChange == 'string' || this.props.onChange instanceof String) {
        eval(`${this.props.onChange}(${JSON.stringify(selectedOption)})`);
      } else {
        this.props.onChange(selectedOption);
      }
    }
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
   render() {

    let { selectedOption } = this.state;
    selectedOption = selectedOption.filter(option => option);
    if (selectedOption.length == 0) {

      // 必須の場合
      if (this.props.required) {
        selectedOption = this.options[0] ? [this.options[0]] : null;
      } else {
        selectedOption = null;
      }
    }

    return (
      <div className='u-display-block' style={{height: 40}}>
        <Select
          value={selectedOption}
          isClearable={true}
          onChange={this.handleChange}
          options={this.options}
          placeholder={this.props.placeholder || '選択してください'}
          isDisabled={this.props.readonly}
          styles={{
            singleValue: (baseStyles, state) => ({
              ...baseStyles,
              color: '#333',
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderRadius: '2px',
              minHeight: '40px',
              borderColor: '#aab4b9',
            }),
          }}
        />
        <input type='text' tabIndex={-1} name={this.props.name} onChange={()=> {}} value={selectedOption != null ? selectedOption[0].value : ''} autoComplete='off' style={{ opacity: 0, height: 1 }} required={(this.props.required && this.props.required != false) ? true : false} />
      </div>
    );
  }
}
