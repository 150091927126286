/**
 * @version 2018/06/10
 */

export const WHITE = '#ffffff';
export const BLACK = '#333333';

export const DEFAULT_COLOR     = '#021F46';
export const DEFAULT_FORGROUND = WHITE;

export const COLORS = [
  DEFAULT_COLOR,
  '#e91e63',
  '#f06292',
  '#f8bbd0',
  '#311b92',
  '#512da8',
  '#9575cd',
  '#d1c4e9',
  '#0d47a1',
  '#1976d2',
  '#4fc3f7',
  '#b2ebf2',
  '#004d40',
  '#00796b',
  '#388e3c',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#f57f17',
  '#ffd54f',
  '#ffeb3b',
  '#ff5722',
  '#ff8a65',
  '#3e2723',
  '#795548',
  '#a1887f',
  '#607d8b',
  '#333333',
  '#666666',
  '#aaaaaa',
  '#cccccc',
  '#ffffff',
];
