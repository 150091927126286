import React from 'react'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  @version 2018/06/10
 */
export default class Hashtags extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = {
      hashtags: [],
    }
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {

    this.getAll();
  }

  /**
   *  全てのタグを取得する
   *  @version 2018/06/10
   */
  getAll() {

    // タグを取得する
    Request.get('/users/hashtags.json')
      .set('X-Requested-With', 'XMLHttpRequest')
      .withCredentials()
      .then(response => {
        this.setState({hashtags: response.body.hashtags});
      }).catch(error => this.setState({hashtags: []}));
  }

  /**
   *  エンター押下時
   *  @version 2018/06/10
   */
  _onEnter = e => {

    if(e.keyCode == 13) this.create();
  }

  /**
   *  タグフォームへの入力時
   *  @version 2018/06/10
   */
  search = () => {

    const name = this.searchRef.value.trim();

    // タグが指定されていない場合は何もしない
    if (name == '') {
      this.getAll();
      return;
    }

    Request.get(`/users/hashtags/search?name=${name}`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .withCredentials()
      .then(response => {
        this._setItems(response.body.hashtags);
      }).catch(error => this._setItems([]));
  }

  /**
   *  タグの登録
   *  @version 2018/06/10
   */
  create = () => {

    const name = this.searchRef.value.trim();

    // 検索フォームに入力されていない場合は何もしない
    if (name == '') return;

    // 既に登録されている場合はなにもしない
    if (this.state.hashtags && this.state.hashtags.some(hashtag => hashtag.name == this.searchRef.value)) {
      return;
    }

    Request.post(`/users/hashtags.json`)
      .field({'hashtag[name]': name})
      .set('X-Requested-With', 'XMLHttpRequest')
      .withCredentials()
      .then(response => {

        let hashtags = this.props.hashtags;
        hashtags.push(response.body.hashtag);

        this._setItems([response.body.hashtag], () => {
          this.props.setHashtag(hashtags);
        });
      }).catch(error => this._setItems([]));
  }

  /**
   *  アイテムをセットする
   *  @version 2018/06/10
   */
  _setItems = hashtags => {

    this.setState({hashtags: hashtags});
  }

  /**
   *  キーワードをセットする
   *  @version 2018/06/10
   */
  _setHashtag = e => {

    let hashtags = this.props.hashtags;
    let item = JSON.parse(e.target.value);
    hashtags = hashtags.filter(hashtag => hashtag.id != item.id);

    // タグがチェックされている場合は追加
    if (e.target.checked) {

      item._destroy = false;
      hashtags.push(item);
    // チェックが外れた場合は削除
    } else {

      item._destroy = true;
      hashtags.push(item);
    }

    this.props.setHashtag(hashtags);
  }

  /**
   *  紐付け済みのキーワード確認
   *  @version 2018/06/10
   */
  _isChecked = item => {

    return this.props.hashtags.some(hashtag => hashtag.id == item.id && !hashtag._destroy);
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render () {

    return (
      <div className={`c-modal-window__body ${Style.Tag}`} onMouseDown={e => e.stopPropagation()}>
        <div className='c-modal-window__main'>

          <div className='u-fs-s u-ml-15 u-fc-darkGray'>タグは100件まで表示されます。見つからない場合は検索または新規作成を行なってください。</div>
          <ul className={Style.Hashtags__hashtags}>
            { this.state.hashtags.length > 0 ?
              this.state.hashtags.map((item, i) => {
                const key = `hashtags_${i}`;
                return (
                  <li {...{key}}>
                    <input id={key} className={Style.Hashtags__hashtagCheck} name='check' type='checkbox' onChange={this._setHashtag} value={ JSON.stringify(item) } defaultChecked={this._isChecked(item)} />
                    <label htmlFor={key} className={Style.Hashtags__hashtag}>
                      {item.name} ({item.movies_count})
                    </label>
                  </li>
                );
              })
              : null
            }
          </ul>
        </div>
        <div className='c-modal-window__header'>
          <input type='text' placeholder='タグ検索' name='hashtag' ref={ node => this.searchRef = node} onChange={this.search} autoComplete='off' className={Style.Hashtags__search} onKeyDown={this._onEnter} />
          <div className='c-btnMain-standard u-ml-10' onClick={this.create} style={{cursor: 'pointer'}}>新規作成</div>
        </div>
      </div>
    );
  }
}
