import React      from 'react'
import Modal from '../utilities/modal'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class LonglistRegister extends React.Component {

  constructor(props) {

    super(props)
  }

  /**
   *  submit処理
   *  @version 2018/06/10
   */
  _onSubmit = e => {

    let ids = [];
    const checkboxes = document.querySelectorAll('.c-form-checkbox');
    checkboxes.forEach(checkbox => {

      if (checkbox.name == 'all_check') return;
      if (checkbox.checked) {
        ids.push(checkbox.value);
      }
    });

    window.startLoading();

    // リストへの追加
    Request.post(`/users/companies/${this.props.company_id}/matching_set`)
      .set('X-Requested-With', 'XMLHttpRequest')
      .field({'companies[]': ids})
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {

          this.close();
          window.closeLoading();
          window.alertable({ type: 'success', message: 'リストに追加しました', close_callback: () => {
            window.location.href = `/users/company_companies?company_id=${this.props.company_id}`;
          } });
        } else {
          this.close();
          this.setState({groups: []}, () => {
            window.closeLoading();
            window.alertable({ type: 'error', message: 'エラーにより法人番号が取得できませんでした。' });
          });
        }
      });
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = () => {

    this.refs.regist_modal.open();
  }

  /**
   *  モーダルを閉じる
   *  @version 2018/06/10
   */
  close = () => {

    this.refs.regist_modal.close();
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    return (
      <div className={Style.LonglistRegister}>
        <div className='u-ta-left u-mt-20'>
          <div className='c-btnMain-standard u-display-block' onClick={this._onSubmit}>追加する</div>
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        <span onClick={this.open} className='c-btnMain-primary'>選択した企業を買手リストに追加</span>
        <Modal ref='regist_modal' yield={this.content()}/>
      </div>
    );
  }
}
