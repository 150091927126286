/**
 * @version 2018/06/10
 */

export const STATE_EDDITING = 'editting';
export const STATE_PREVIEW  = 'preview';

// plugins
import Heading     from './palette/heading'
import Text        from './palette/text'
import Image       from './palette/image'
import Link        from './palette/link'
import Table       from './palette/table'
import CheckList   from './palette/check_list'
import Faq         from './palette/faq'

export const ITEMS = {

  heading:     { title: '見出し', class_name: Heading, icon: 'paragraph' },
  text:        { title: 'テキスト', class_name: Text, icon: 'paragraph2' },
  image:       { title: '画像', class_name: Image, icon: 'image' },
  link:        { title: 'リンク', class_name: Link, icon: 'link' },
  list:        { title: 'リスト', class_name: CheckList, icon: 'list' },
  table:       { title: 'テーブル', class_name: Table, icon: 'table' },
  faq:         { title: 'Q&A', class_name: Faq, icon: 'question' },
};
