import React     from 'react'
import Template  from '../template'
import Utilities from 'scripts/utilities'
import Wysiwyg   from 'users/editor/wysiwyg'

/**
 *  @version 2018/06/10
 */
export default class Text extends Template {

  /**
   *  アイテム情報取得用
   *  @version 2018/06/10
   */
  reference = () => {

    const text = Utilities.trimHtmlTags(this.state.item.value.text);

    return {
      text:   text,
      length: text.length,
    };
  }

  /**
   *  タイトル
   *  @version 2018/06/10
   */
  templateComponentTitle() { return 'テキスト' }

  /**
   *  アイテムの追加
   *  @version 2018/06/10
   */
  add = () => {

    const content = this.contentRef.content();

    if (content == '') {
      window.alertable({ type: 'warning', message: 'テキストを入力してください' });
      return false;
    }

    this._updateItem({ text: content, frame: this.state.frame });
  }

  /**
   *  編集時表示
   *  @version 2018/06/10
   */
  templateEdittingContent() {

    const { text, frame } = this.props.item.value;

    return (
      <div className='u-mt-15'>
        <Wysiwyg ref={ node => this.contentRef = node} content={text} placeholder='文章を入力してください。'/>
        <div className='u-mt-10'>
          <label className='u-fc-darkGray u-fs-s'>枠線</label>
          <div className='u-mt-10'>
            <label className='c-form-radioLabel'>
              <input name='frame' type='radio' defaultChecked={frame != true} onChange={() => this.setState({frame: false})} className='c-form-radio' />
              <i className='c-form-radioIcon' />
              <span>なし</span>
            </label>
            <label className='c-form-radioLabel u-ml-15'>
              <input name='frame' type='radio' defaultChecked={frame} onChange={() => this.setState({frame: true})} className='c-form-radio' />
              <i className='c-form-radioIcon' />
              <span>あり</span>
            </label>
          </div>
        </div>
      </div>
    );
  }

  /**
   *  プレビュー表示
   *  @version 2018/06/10
   */
  templatePreviewContent() {

    return (
      this.props.item.value.frame ?
        <div className='p-article__text'>
          <div className='p-article__text--frame' dangerouslySetInnerHTML={{ __html: this.props.item.value.text}}></div>
        </div>
        :
        <div className='p-article__text' dangerouslySetInnerHTML={{ __html: this.props.item.value.text}}></div>
    );
  }
}
