export const SITES = {
  google: 'Google検索',
  nikkei: '日経テレコン',
  roborobo: 'RoboRobo',
  registry: '登記簿取得',
  jcis: 'JCIS取得',
  tdb: 'TDB取得',
  tdb_rate: 'TDB評点',
};

export const STATUSES = [
  { value: 'yet', name: '未' },
  { value: 'approved', name: 'OK' },
  { value: 'rejected', name: 'NG' },
];

export const ALPHABET = '-abcdefghijklmnopqrstuvwxyz';
