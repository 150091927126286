import React      from 'react'
import Modal from '../utilities/modal'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class CsvUploader extends React.Component {

  /**
   *  submit処理
   *  @version 2018/06/10
   */
  _onSubmitCsv = e => {

    const ua = navigator.userAgent.toLowerCase();

    if (this.fileNode.files[0]) {

      const field = {
        'job_trigger[target_model]': this.props.target_model,
        'job_trigger[company_id]': this.props.company_id || '',
        'job_trigger[group_id]': this.props.group_id || '',
        'job_trigger[arguments]': JSON.stringify(this.props.arguments || {}),
        'job_trigger[request_os]': ua.match(/mac os/) ? 'mac' : 'windows',
      };

      // ロゴを送信
      Request.post('/users/job_triggers')
        .field(field)
        .attach('job_trigger[csv]', this.fileNode.files[0])
        .set('X-Requested-With', 'XMLHttpRequest')
        .setCsrfToken()
        .end((error, response) => {

          if (response.body.status == 'success') {

            this.close();
            window.alertable({ type: 'success', message: 'CSV取り込み処理を実行しました。順次CSV情報が登録されます。CSV取り込み結果一覧をご確認ください', close_callback: () => {
              window.location.reload();
            } });
          } else {

            this.close();
            window.alertable({ type: 'error', message: response.body.message });
          }
        });
    }
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = () => {

    this.refs.upload_modal.open();
  }

  /**
   *  モーダルを閉じる
   *  @version 2018/06/10
  */
  close = () => {

    this.refs.upload_modal.close();
  }


  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    return (
      <div>
        <p className='u-fs-large'>CSVを選択して取り込みを開始してください</p>
        <div className='u-mt-30'>
          CSVは <span className='u-fw-bold'>「会社名,都道府県,代表者名,法人番号,ID」</span> の構成で登録してください
          <div className='u-mt-15 u-fs-small'>※ <span className='u-fw-bold u-fc-red'>法人番号、ID</span>を含めることでリストへの登録が確実になります。法人番号とIDのどちらかがあれば、<br />会社名,都道府県,代表者名はそれぞれブランクでも登録が可能です。</div>
          <div className='u-mt-5 u-fs-small'>※ 法人番号、もしくはIDを含めない場合、会社名,都道府県,代表者名は会社の判別に利用するため必須です。</div>
          <div className='u-mt-5 u-fs-small'>※ CSVのファイルサイズは<span className='u-fw-bold u-fc-red'>2Mバイト以下</span>でお願いします。</div>
        </div>
        <div className='u-mt-30'>
          <input accept='image' type='file' ref={(node) => this.fileNode = node} />
        </div>
        <div className='u-ta-left u-mt-20'>
          <div className='c-btnMain-primary u-display-block' onClick={this._onSubmitCsv}>取り込みを開始する</div>
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className='u-mt-15'>
        <span onClick={this.open} className='c-btnMain-primary'>CSVを取り込んでリストに追加する</span>
        <Modal ref='upload_modal' yield={this.content()}/>
      </div>
    );
  }
}
