import React    from 'react'
import Template from '../template'

import { TYPES } from './properties.es6'

/**
 *  @version 2018/06/10
 */
export default class Heading extends Template {

  /**
   *  アイテム情報取得用
   *  @version 2018/06/10
   */
  reference = () => {

    const text = this.state.item.value.text || '';
    return {
      text:   text,
      length: text.length,
    };
  }

  /**
   *  タイトル
   *  @version 2018/06/10
   */
  templateComponentTitle() { return '見出し' }

  /**
   *  アイテムの追加
   *  @version 2018/06/10
   */
  add = () => {

    if (this.textRef.value == '') {
      window.alertable({ type: 'warning', message: 'テキストを入力してください' });
      return false;
    }

    this._updateItem({
      type: this.typeRef.value,
      text: this.textRef.value,
    });
  }

  /**
   *  編集時表示
   *  @version 2018/06/10
   */
  templateEdittingContent() {

    const { type, text } = this.props.item.value;

    return (
      <div>
        <div className='u-mt-10 c-form-selectWrap'>
          <select className='c-form-select' ref={ node => this.typeRef = node} defaultValue={type}>
            {Object.keys(TYPES).map((type) => {
              const key = `text-${type}`;
              return (
                <option {...{key}} value={TYPES[type]}>{type}</option>
              );
            })}
          </select>
        </div>

        <div className='u-mt-10'>
          <label className='u-fc-darkGray u-fs-s'>見出しテキスト</label>
          <span className='c-form-required u-ml-10'>必須</span>
          <input type='text' className='c-form-text' ref={ node => this.textRef = node} defaultValue={text} placeholder='見出し文章を入力して下さい。' />
        </div>
      </div>
    );
  }

  /**
   *  プレビュー表示
   *  @version 2018/06/10
   */
  templatePreviewContent() {

    const { type, text } = this.props.item.value;

    return (
      <div className='p-article__heading'>
        { type == 'h2' ? <h2>{text}</h2> : null }
        { type == 'h3' ? <h3>{text}</h3> : null }
        { type == 'h4' ? <h4>{text}</h4> : null }
      </div>
    );
  }
}
