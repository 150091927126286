// チャット内容
export const chats = [
  [
    { type: 'bot', content: 'お問い合わせありがとうございます。' },
    { type: 'bot', content: 'はじめにお問い合わせの種類を教えてください。' },
    { type: 'customer', content: [{ name: 'contact_type', label: 'お問い合わせ区分', type: 'contact_type', options: ['contact', 'document', 'valuation'] }] },
  ],
  [
    { type: 'bot', content: 'ご希望の資料の種類を教えてください。', contact_type: 'document' },
    { type: 'customer', content: [{ name: 'document_type', label: '資料種別', type: 'document', options: ['ma', 'business', 'corporate']}], contact_type: 'document' },
  ],
  [
    { type: 'bot', content: 'ご希望の資料を教えてください。', contact_type: 'document' },
    { type: 'customer', content: [{ name: 'document_name', label: '資料', type: 'documents', options: []}], contact_type: 'document' },
  ],
  [
    { type: 'bot', content: 'ありがとうございます。会社名、ご担当者様のお名前をご入力ください。' },
    { type: 'customer', content: [{ name: 'company', label: '会社名', type: 'text' }, { name: 'name', label: '名前', type: 'text'}] },
  ],
  [
    { type: 'bot', content: '続いて、ご連絡先をご入力ください。' },
    { type: 'customer', content: [{ name: 'tel', label: '電話番号', type: 'text' }, { name: 'email', label: 'メールアドレス', type: 'text' }] },
  ],
  [
    { type: 'bot', content: 'ご関心のある内容を教えてください。' },
    { type: 'customer', content: [{ name: 'interest', label: 'ご関心のある内容', type: 'radio', options: ['succession', 'buyout', 'purchase'] }] },
  ],
  [
    { type: 'bot', content: '最後に相談内容について教えてください。' },
    { type: 'customer', content: [{ name: 'body', label: 'ご相談内容', type: 'textarea' }] },
  ],
  [
    { type: 'bot', content: 'こちらの内容でお間違いないでしょうか？問題なければ「送信する」ボタンを押してください。' },
    { type: 'customer', content: [{ type: 'confirm' }] },
  ],
  [
    { type: 'bot', content: 'お問い合わせありがとうございました。' },
    { type: 'bot', content: '担当者からの連絡をお待ちください。' },
  ],
];

export const locales = {
  contact_type: { 'contact': 'お問い合わせ', 'document': '資料請求', 'valuation': '価値算定サービス' },
  interest: { 'succession': '事業承継について', 'buyout': '自社／事業の売却について', 'purchase': '買収について' },
  document_type: { 'ma': 'M&A資料', 'business': '業種別資料', 'corporate': '会社案内' },
};
