import React from 'react'
import Style from './style.sass'
import Icon  from 'users/icon'

/**
 *  モーダル
 *  @version 2018/06/10
 */
export default class Link extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    // キーバインドイベントを一時保存用
    this.previousKeyDownEvent = null;

    this.state = { show: props.show || false };
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {

    this.bindKeyEvents();
  }

  /**
   *  レンダリング削除時
   *  @version 2018/06/10
   */
  componentWillUnmount() {

    this.unBindKeyEvents();
  }

  /**
   *  モーダルを開く
   *  @version 2018/06/10
   */
  open = () => {

    this.bindKeyEvents();

    this.setState({ show: true }, () => document.body.style.overflow = 'hidden');
  }

  /**
   *  モーダルを閉じる
   *  @version 2018/06/10
   */
  close = () => {

    this.unBindKeyEvents();

    this.setState({ show: false }, () => document.body.style.overflow = 'auto');
  }

  /**
   *  キーバインドイベントの登録
   *  @version 2018/06/10
   */
  bindKeyEvents = () => {

    // エンター押下イベント登録
    if (document.onkeydown != this._onEnter) {

      this.previousKeyDownEvent = document.onkeydown;
      document.onkeydown = this._onEnter;
    }
  }

  /**
   *  キーバインドイベントの削除
   *  @version 2018/06/10
   */
  unBindKeyEvents = () => {

    document.onkeydown = this.previousKeyDownEvent;
  }

  /**
   *  エンター押下時
   *  @version 2018/06/10
   */
  _onEnter = e => {

    if (e.keyCode == 13) this.close();
  }

  /**
   *  入力したリンクを適用する
   *  @version 2018/06/10
   */
  apply = e => {

    e.preventDefault();

    const url = (this.urlRef.value.match(/^[0-9]+$/) ? '#' : '') + this.urlRef.value;

    this.props.apply(url);
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return ( this.state.show ?
      <div className={Style.Link}>
        <div className={Style.Link__overlay} onMouseDown={this.close}>
          <div className={Style.Link__inner} onMouseDown={ e => e.stopPropagation() }>
            <div className='u-fc-darkGray u-mt-15 u-fs-s'>リンク先のURL</div>
            <input type='text' className='c-form-text' ref={ node => this.urlRef = node} placeholder='httpsから始まるURLを入力してください' />
            <div className='c-btnMain-standard u-display-block u-mt-15' onClick={this.apply}>適用する</div>
            <div onClick={this.close} className='c-btnMain-negative u-display-block u-mt-10'>閉じる</div>
            <div onClick={this.close} className={Style.Link__closeIcon}>
              <Icon name='close' size='l' color='darkGray'/>
            </div>
          </div>
        </div>
      </div>
      : null
    );
  }
}
