import React   from 'react'
import Icon    from '../icon'
import Style   from './style.sass'
import Dayjs   from 'dayjs'

/**
 *  @version 2018/06/10
 */
export default class Needs extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.target_model = this.props.is_oversea ? 'oversea_needs_attributes': 'needs_attributes';

    this.state = { needs: (props.needs.length > 0) ? props.needs : [{
      user_id: this.props.user_id,
      prefecture: '',
      budget: null,
      sales: null,
      profit: null,
      overview: '',
      _destroy: false,
    }]};
  }

  /**
   * マウント直後
   */
  componentDidMount() {

    let needs = (this.state.needs || []).slice();

    needs.forEach((need, j) => {

      if (need.category_id && need.category_id != '') {

        need.category_large = this.getParentCategory(need.category_id);
        need.category_mediums = this.getChildCategories(need.category_large);
      }
    });

    this.setState({needs: needs});
  }

  /**
   *  ニーズの取得
   *  @version 2018/06/10
   */
  needs = () => {

    return this.state.needs;
  }

  /**
   *  親カテゴリを取得
   *  @version 2018/06/10
   */
  getParentCategory = (category_id) => {

    let result = null;

    this.props.categories.forEach( category => {

      category.categories.forEach( medium_category => {
        if (medium_category.id == category_id) result = medium_category.parent_id;
      })
    });

    return result;
  }

  /**
   *  子カテゴリを取得
   *  @version 2018/06/10
   */
  getChildCategories = (category_id) => {

    let result = null;

    this.props.categories.forEach( category => {

      if (category.id == category_id) result = category.categories;
    });

    return result;
  }

  addNeeds = (e) => {

    let needs = (this.state.needs || []).slice();

    needs.push({
      user_id: this.props.user_id,
      prefecture: '',
      budget: null,
      sales: null,
      profit: null,
      overview: '',
      _destroy: false,
    });

    this.setState({ needs: needs });
  }

  // 買収ニーズを削除する
  removeNeeds = (e) => {

    let needs = (this.state.needs || []).slice();

    if (needs[e.target.dataset.index].overview && needs[e.target.dataset.index].overview != '') {
      window.alertable({ type: 'warning', message: '概要・詳細が入力されているため削除できません' });
      return false;
    }

    needs[e.target.dataset.index]._destroy = true;

    this.setState({ needs: needs });
  }

  onChangeCategoryLarge = e => {

    const index = e.target.dataset.index;

    let needs = (this.state.needs || []).slice();
    needs[index].category_large = e.target.value;

    const category_mediums = this.getChildCategories(e.target.value);
    needs[index].category_mediums = category_mediums;
    needs[index].category_id = category_mediums ? category_mediums[0].id : null;

    this.setState({ needs: needs });
  }

  onChangeNeedValue = e => {

    const index = e.target.dataset.index;

    let needs = (this.state.needs || []).slice();

    const category_large = this.refs[`category_large_${index}`] ? this.refs[`category_large_${index}`].value : null;

    const category_id = category_large ? this.refs[`category_medium_${index}`].value : null;

    needs[index] = {
      id: needs[index].id,
      category_large: category_large,
      category_id: category_id,
      user_id: needs[index].user_id || this.props.user_id,
      prefecture: this.refs[`prefecture_${index}`] ? this.refs[`prefecture_${index}`].value : null,
      budget: this.refs[`budget_${index}`] ? this.refs[`budget_${index}`].value : null,
      sales: this.refs[`sales_${index}`] ? this.refs[`sales_${index}`].value : null,
      profit: this.refs[`profit_${index}`] ? this.refs[`profit_${index}`].value : null,
      overview: this.refs[`overview_${index}`] ? this.refs[`overview_${index}`].value : null,
      category_mediums: this.getChildCategories(category_large),
      no_needs: this.refs[`no_needs_${index}`] ? this.refs[`no_needs_${index}`].checked : false,
      _destroy: needs[index]._destroy,
    };
    
    this.setState({ needs: needs });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        { this.state.needs.map((need, index) => {

          return (
            <div style={{display: need._destroy ? 'none' : 'block'}} className={`${Style.Needs} c-attention ${index > 0 ? 'u-mt-15' : ''}`} key={'need-'+ index}>

              <input type='hidden' value={need.user_id || null} name={`company[${this.target_model}][${index}][user_id]`} />
              <input type='hidden' value={need.id || ''} ref={`company_id_${index}`} name={`company[${this.target_model}][${index}][id]`} />
              <input type='hidden' value={this.props.company_id || null} ref={`company_id_${index}`} name={`company[${this.target_model}][${index}][company_id]`} />
              <input type='hidden' value={need._destroy} name={`company[${this.target_model}][${index}][_destroy]`} />

              <div className='u-display-flex'>
                <div className='u-fw-bold' style={{flex: 1}}>{this.props.is_oversea ? '海外' : null}買収ニーズ{index+1}</div>

                <div className='u-display-flex'>
                  { need.id && this.props.users && this.props.users[need.user_id] ? <div className='u-fs-small u-mr-15'>登録者: {this.props.users[need.user_id].name}</div> : null }
                  { need.id ? <div className='u-fs-small'>登録日: {Dayjs(need.created_at).format('YYYY年MM月DD日')}</div> : null }
                  { need.id ? <div className='u-ml-30 u-mr-15 u-fs-small'>更新日: {Dayjs(need.updated_at).format('YYYY年MM月DD日')}</div> : null }
                  <div className='u-ml-15 u-fs-small u-td-underline' data-index={index} onClick={(e) => { this.removeNeeds(e) }}>削除</div>
                </div>
              </div>

              { this.props.is_oversea ?
                <div className='u-mt-30'>
                  <label className='c-form-checkboxLabel'>
                    <span className='c-form-label'>海外買収ニーズなし</span>
                    <input type='hidden' value='false' name={`company[${this.target_model}][${index}][no_needs]`} />
                    <input className='c-form-checkbox u-ml-10' type='checkbox' value='true' checked={need.no_needs} ref={`no_needs_${index}`} data-index={index} onChange={this.onChangeNeedValue} name={`company[${this.target_model}][${index}][no_needs]`} />
                    <i className='c-form-checkboxIcon'></i>
                  </label>
                </div>
                : null
              }
              <div className='c-form-label u-mt-15'>
                <label className='u-fs-small'>買収ニーズ概要・詳細</label>
              </div>
              <textarea rows='12' className='c-form-textarea' value={need.overview || ''} ref={`overview_${index}`} placeholder='買収ニーズを入力してください' autoComplete='off' spellCheck='false' data-index={index} onChange={this.onChangeNeedValue} name={`company[${this.target_model}][${index}][overview]`}></textarea>

              { !need.no_needs ?
                <div>
                  <div>
                  <div className='c-form-label'>
                    <label className='u-fs-small'>{this.props.is_oversea ? '国・エリア' : '都道府県・エリア'}</label>
                  </div>
                  <div>
                  <input type='text' value={need.prefecture} ref={`prefecture_${index}`} className='c-form-text' placeholder='' data-index={index} onChange={this.onChangeNeedValue} name={`company[${this.target_model}][${index}][prefecture]`} />
                  </div>
                </div>
                <div className='c-form-label'>
                  <label className='u-fs-small'>業種大分類</label>
                </div>
                <div className='c-form-selectWrap'>
                  <select className='c-form-select' value={need.category_large} ref={`category_large_${index}`} data-index={index} onChange={this.onChangeCategoryLarge}>
                    <option value=''>-</option>
                    { this.props.categories.map((category, ci) => {
                      const category_key = `category_${index}_${ci}`;
                      return (
                        <option key={category_key} value={category.id}>{category.name}</option>
                      );
                    })}
                  </select>
                </div>

                { need.category_mediums && need.category_mediums.length > 0 ?
                  <div>
                    <div className='c-form-label'>
                      <label className='u-fs-small'>業種中分類</label>
                    </div>
                    <div className='c-form-selectWrap'>
                      <select className='c-form-select' value={need.category_id} ref={`category_medium_${index}`} data-index={index} onChange={this.onChangeNeedValue} name={`company[${this.target_model}][${index}][category_id]`} >
                        { need.category_mediums.map((category_medium, cmi) => {
                          const category_medium_key = `category_medium_${index}_${cmi}`;
                          return (
                            <option key={category_medium_key} value={category_medium.id}>{category_medium.name}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  :
                  null
                }

                <div>
                  <div className='u-display-flex'>
                    <div style={{flex: 1}}>
                      <div className='c-form-label'>
                        <label className='u-fs-small'>買収予算</label>
                      </div>
                      <div>
                        <input type='text' value={need.budget || ''} ref={`budget_${index}`} className='c-form-text' placeholder='1000000000' data-index={index} onChange={this.onChangeNeedValue} name={`company[${this.target_model}][${index}][budget]`} />
                      </div>
                    </div>
                    <div className='u-ml-15' style={{flex: 1}}>
                      <div className='c-form-label'>
                        <label className='u-fs-small'>希望売上高</label>
                      </div>
                      <div>
                        <input type='text' value={need.sales || ''} ref={`sales_${index}`} className='c-form-text' placeholder='100000000' data-index={index} onChange={this.onChangeNeedValue} name={`company[${this.target_model}][${index}][sales]`} />
                      </div>
                    </div>
                    <div className='u-ml-15' style={{flex: 1}}>
                      <div className='c-form-label'>
                        <label className='u-fs-small'>希望営業利益</label>
                      </div>
                      <div>
                        <input type='text' value={need.profit || ''} ref={`profit_${index}`} className='c-form-text' placeholder='100000000' data-index={index} onChange={this.onChangeNeedValue} name={`company[${this.target_model}][${index}][profit]`} />
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                : null
              }
            </div>
          );
        })}
        <div className='c-btnMain-standard u-mt-15 c-btnSmall' onClick={this.addNeeds}>＋ 買収ニーズを追加する</div>
      </div>
    );
  }
}
