import React   from 'react'
import Style from './style.sass'

import ExpenseValues from './values'
import ExpenseFlows from './flows'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

import { STATUSES } from './properties'

/**
 *  @version 2018/06/10
 */
export default class Expense extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);
  }

  // 保存
  onSubmit = e => {
    
    e.stopPropagation();

    let error = false;
    const request = this.props.expense.id ? Request.put(`/users/expenses/${this.props.expense.id}`) : Request.post('/users/expenses');

    if (this.refs.name.value == '') {
      error = true;
    }

    let fields = {
      'expense[name]': this.refs.name.value,
      'expense[status]': this.refs.status.value,
      'expense[notice]': this.refs.notice.value,
      'expense[note]': this.refs.note.value,
    };

    // 新規登録時のみ変更可能
    if (!this.props.expense.id) {

      // 稟議項目
      this.refs.values.getValues().map((value, index) => {

        if (value.name != '') {

          fields[`expense[values_attributes][${index}][value_type]`] = value.value_type;
          fields[`expense[values_attributes][${index}][sort]`] = value.sort;
          fields[`expense[values_attributes][${index}][name]`] = value.name;
          fields[`expense[values_attributes][${index}][required]`] = value.required;
        }
      });
    }

    // 稟議フロー
    this.refs.flows.getFlows().map((flow, index) => {

      if (['division_master', 'division_anyone'].includes(flow.expense_flow_type) && (!flow.division_id || flow.division_id == '')) {
        error = true;
      }

      if (flow.id) fields[`expense[flows_attributes][${index}][id]`] = flow.id;
      if (flow._destroy) fields[`expense[flows_attributes][${index}][_destroy]`] = flow._destroy;
      if (flow.division_id && flow.division_id != '') fields[`expense[flows_attributes][${index}][division_id]`] = flow.division_id;
      if (flow.target_user_id && flow.target_user_id != '') fields[`expense[flows_attributes][${index}][target_user_id]`] = flow.target_user_id;
      fields[`expense[flows_attributes][${index}][expense_flow_type]`] = flow.expense_flow_type;
      fields[`expense[flows_attributes][${index}][sort]`] = flow.sort;
    });

    if (error) {
      window.alertable({ type: 'error', message: '未入力の項目があります' });
      return false;
    }

    request
      .field(fields)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {

          window.alertable({ type: 'success', message: response.body.message });
          window.location.href = `/users/expenses/`;
        } else {

          window.alertable({ type: 'error', message: response.body.message });
        }
      });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className={Style.Approval}>

        <div className='c-form-label u-mt-15'>
          <label>立替経費名目</label>
        </div>
        <div>
          <input type='text' defaultValue={this.props.expense.name} className='c-form-text' placeholder='名目を入力してください' ref='name' name='expense[name]'/>
        </div>

        <div className='c-form-label u-mt-15'>
          <label>注意事項</label>
        </div>
        <div>
          <textarea ref='notice' name='expense[notice]' defaultValue={this.props.expense.notice} placeholder='例: 件名は社名 + 契約名 としてください' rows='2' className='c-form-textarea u-mt-5' autoComplete='off' />
        </div>

        <div className='c-warning u-mt-30 u-fs-small'>
          テンプレートは一度作成すると<span className='u-fw-bold u-fc-red'>項目の変更を行うことはできません</span>。変更を行いたい場合は、無効にしてから新しいテンプレートを作成してください。
        </div>
        <ExpenseValues ref='values' values={this.props.values} disabled={!!this.props.expense.id} />
        <ExpenseFlows flows={this.props.flows} users={this.props.users} divisions={this.props.divisions} default_division_id={this.props.divisions[0].id} ref='flows'/>

        <div className='u-mt-15'>
          <div className='c-form-label'>
            <label className='u-fs-small'>備考の初期値</label>
          </div>
          <textarea ref='note' defaultValue={this.props.expense.note} placeholder='備考欄の初期値として記述する文言を記入してください' rows='5' className='c-form-textarea u-mt-5' autoComplete='off' />
        </div>

        <div className='c-form-label u-mt-15'>
          <label>有効/無効</label>
          <span className='u-ml-10 u-fs-small'>※ 無効にしたテンプレートは申請することができなくなります。削除の代わりにご利用ください。</span>
        </div>
        <div className='c-form-selectWrap'>
          <select className='c-form-select' defaultValue={this.props.expense.status} ref='status'>
            { STATUSES.map((status, index) => {
              const key = 'status-'+index;
              return (
                <option {...{key}} value={status.value}>{status.name}</option>
              );
            })}
          </select>
        </div>

        <div className='u-ta-center u-mt-30'>
          <div className='c-btnMain-standard' onClick={this.onSubmit}>保存する</div>
        </div>
      </div>
    );
  }
}
