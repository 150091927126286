import React from 'react'
import Style from './style.sass'

/**
 *  モーダル
 *  @version 2018/06/10
 */
export default class Modal extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    // キーバインドイベントを一時保存用
    this.previousKeyDownEvent = null;

    this.state = { show: props.show || false };
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {

    //this.bindKeyEvents();
  }

  /**
   *  レンダリング削除時
   *  @version 2018/06/10
   */
  componentWillUnmount() {

    //this.unBindKeyEvents();
  }

  /**
   *  モーダルを開く
   *  @version 2018/06/10
   */
  open = () => {

    document.body.style.overflow = 'hidden';
    document.querySelectorAll('.simplebar-track').forEach(r => {
      r.style.display = 'none';
    });
    document.querySelectorAll('.c-overlay-form').forEach(r => {
      //r.style.display = 'none';
    });

    this.setState({ show: true });
  }

  /**
   *  モーダルを閉じる
   *  @version 2018/06/10
   */
  close = () => {

    document.body.style.overflow = 'auto';
    document.querySelectorAll('.simplebar-track').forEach(r => {
      r.style.display = 'block';
    });
    document.querySelectorAll('.c-overlay-form').forEach(r => {
      //r.style.display = 'block';
    });

    const {close_callback} = this.props;
    if (close_callback) close_callback();

    this.setState({ show: false });
  }

  /**
   *  キーバインドイベントの登録
   *  @version 2018/06/10
   */
  bindKeyEvents() {

    // エンター押下イベント登録
    if (document.onkeydown != this._onEnter) {

      this.previousKeyDownEvent = document.onkeydown;
      document.onkeydown = this._onEnter;
    }
  }

  /**
   *  キーバインドイベントの削除
   *  @version 2018/06/10
   */
  unBindKeyEvents() {
    
    document.onkeydown = this.previousKeyDownEvent;
  }

  /**
   *  親要素のクリックイベントを引き継がない
   *  @version 2018/06/10
   */
  _stopPropagation = event => {

    event.stopPropagation();
  }

  /**
   *  エンター押下時
   *  @version 2018/06/10
   */
  _onEnter = e => {

    if (e.keyCode == 13) return false;
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className={Style.Modal}>
        { this.state.show ?
          <div className={Style.Modal__overlay} onMouseDown={this.close}>
            <div className={Style.Modal__inner} onMouseDown={this._stopPropagation} style={{width: this.props.width || 'auto'}}>
              {this.props.yield}
              <div onClick={this.close} className={`c-btnMain-negative ${Style.Modal__button}`}>閉じる</div>
              <div onClick={this.close} className={Style.Modal__closeIcon}>×</div>
            </div>
          </div>
          : null
        }
      </div>
    );
  }
}
