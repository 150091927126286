import React    from 'react'
import Template from '../template'
import Icon     from 'users/icon'
import Style    from './style.sass'

import { LIST_TYPES } from './properties.es6'

/**
 *  @version 2018/06/10
 */
export default class CheckList extends Template {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  defaultState (props) {

    return {
      list: props.item.value.list || ['テキスト'],
    }
  }

  /**
   *  アイテム情報取得用
   *  @version 2018/06/10
   */
  reference = () => {

    // 行
    let text = '';
    this.state.list.forEach(item => text += item);
    return { text: text, length: text.length };
  }

  /**
   *  タイトル
   *  @version 2018/06/10
   */
  templateComponentTitle() { return 'リスト' }

  /**
   *  リストの追加
   *  @version 2018/06/10
   */
  addList = () => {

    let list = this.state.list.slice();
    list.push('テキスト');

    this.setState({list: list});
  }

  /**
   *  リストの削除
   *  @version 2018/06/10
   */
  removeList = (e, i) => {

    // リスト数が1以下の場合は実施しない
    if (this.state.list <= 1) return false;
    let list = this.state.list.slice();
    list.splice(i, 1);
    this.setState({list: list});
  }

  /**
   *  アイテムの追加
   *  @version 2018/06/10
   */
  add = () => {

    this._updateItem({ list_type: this.listTypeRef.value, list: this.state.list });
  }

  /**
   *  リストの更新
   *  @version 2018/06/10
   */
  onChangeItemValue = (e, i) => {

    let list = this.state.list.slice();
    list[i] = e.target.value;
    this.setState({ list: list });
  }

  /**
   *  編集時表示
   *  @version 2018/06/10
   */
  templateEdittingContent() {

    return (
      <div className={Style.CheckList}>

        <div className='c-form-selectWrap'>
          <select className='c-form-select' ref={node => this.listTypeRef = node} defaultValue={this.props.item.value.list_type} >
            { LIST_TYPES.map((list_type, i) => {
              return <option key={`position-${i}`} value={list_type.value} >{list_type.name}</option>;
            })}
          </select>
        </div>

        <div className='u-mt-15'>
          <div className='c-btn-sub' onClick={this.addList}>行を追加する</div>
        </div>

        <ul className='u-mt-15'>
          { this.state.list.map((item, i) => (
            <li key={`list-${i}`} className={Style.CheckList__item}>
              <input className='c-form-text' onChange={e => this.onChangeItemValue(e, i)} type='text' value={item} placeholder='リスト内容を入力してください' />
              <span className={Style.CheckList__delete} onClick={e => this.removeList(e, i)}>
                <Icon name='delete' size='s' color='gray'/>
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  /**
   *  プレビュー表示
   *  @version 2018/06/10
   */
  templatePreviewContent() {

    const { list, list_type } = this.props.item.value;

    return (
      <ul className='p-article__list'>
        { list.map((item, index) => (
          <li className={`p-article__list${list_type == 'ol' ? 'Order' : 'Item'}`} key={`list-${index}`}>{item}</li>
        ))}
      </ul>
    );
  }
}
