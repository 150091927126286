import React   from 'react'
import Style from './style.sass'
import Autosuggest from 'react-autosuggest';

/**
 *  @version 2018/06/10
 */
export default class Suggests extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = {
      value: this.props.value || '',
      suggestions: [],
    };    
  }

  escapeRegexCharacters = str => {

    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getSuggestions = value => {

    const escapedValue = this.escapeRegexCharacters(value.trim());

    const regex = new RegExp(escapedValue, 'i');
    return this.props.candidates.filter(candidate => regex.test(candidate.name));
  }

  getSuggestionValue = suggestion => {

    return suggestion.name;
  }
  
  renderSuggestion = suggestion => {

    return <span>{suggestion.name}</span>;
  }

  onChangeValue = (e, { newValue, method }) => {

    this.setState({ value: newValue });
  };

  onSuggestionsFetchRequested = ({ value }) => {

    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onSuggestionsClearRequested = () => {

    this.setState({ suggestions: [] });
  };

  /**
   *  内容を適用する
   *  @version 2018/06/10
   */
  getValue = () => {

    return this.state.value;
  }

  shouldRenderSuggestions = value => {
    return this.getSuggestions(value);
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: this.props.placeholder || 'キーワードを入力してください',
      value,
      onChange: this.onChangeValue,
      className: 'c-form-text',
      name: this.props.name,
    };

    return (
      <div className={Style.Suggests}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps} />
      </div>
    );
  }
}
