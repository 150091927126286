import React      from 'react'
import Modal from '../utilities/modal'

import { DirectUpload } from "@rails/activestorage"

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class FileUploader extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor (props) {

    super(props);
    this.state = {
      signed_ids: [],
    }
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', event => {
      this.directUploadDidProgress(event);
      console.log('directUploadWillStoreFileWithXHR');
      console.log('event', event);
    });
  }

  directUploadDidProgress(event) {
    // Use event.loaded and event.total to update the progress bar
    console.log('directUploadDidProgress');
    console.log('event', event);
  }

  directUpload = (e) => {

    window.startLoading();

    let promises = [];

    Array.from(e.target.files).forEach(file => {
      const upload = new DirectUpload(file, this.props.url, this);

      promises.push(
        new Promise((resolve, reject) => {
          upload.create((error, blob) => {
            if (error) {
              // Handle the error
              console.log('error', error);
              resolve(null);
            } else {
              // Add an appropriately-named hidden input to the form
              // with a value of blob.signed_id
              resolve(blob.signed_id);
            }
          });
        })
      )
    });

    Promise.all(promises).then(signed_ids => {
      this.setState({signed_ids: signed_ids}, window.closeLoading);
    });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        <input multiple='multiple' onChange={this.directUpload} type='file' name='files' required={this.props.required ? 'required' : null} />
        { this.state.signed_ids.map(signed_id => {
          return <input type='hidden' name='filestorage[files][]' value={signed_id} />;
        })}
      </div>
    );
  }
}
