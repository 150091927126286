import React from 'react'
import Modal from '../utilities/modal'
import Style from './style.sass'
import { MultipleSelect } from '../multiple_select'

// Ajax
import Request from 'superagent'

/**
 *  自動スクロールボタン
 *  @version 2018/06/10
 */
export default class Areas extends React.Component {

  constructor(props) {

    super(props)

    let cities = [];
    if (props.prefectures) cities = this.getCities(props.prefectures);

    this.state = {
      prefectures: props.prefectures || [],
      city: props.city || [],
      cities: cities || [],
    };

    // グローバルからの呼び出し
    window.clearArea = () => {
      this.clear();
    };
  }

  /**
   *  オンオフ
   *  @version 2018/06/10
   */
  toggleArea = e => {

    const checked = e.target.checked; 
    const area = document.getElementById(`${e.target.value}_area`);
    const checks = area.querySelectorAll("input[type='checkbox']");

    let prefectures = this.state.prefectures.slice();

    Object.keys(checks).forEach( key => {

      if (checks[key].checked != checked) {
        if (checks[key].checked) {
          prefectures = prefectures.filter(n => n != checks[key].value);
          checks[key].checked = false;
        } else {
          prefectures.push(checks[key].value);
          checks[key].checked = true;
        }
      }
    });

    this.setState({prefectures: prefectures, cities: [], city: []})
  }

  /**
   *  オンオフ
   *  @version 2018/06/10
   */
  togglePref = e => {

    let prefectures = this.state.prefectures.slice();

    if (prefectures.includes(e.target.value)) {
      prefectures = prefectures.filter(n => n != e.target.value);
    } else {
      prefectures.push(e.target.value);
    }

    this.setState({prefectures: prefectures, cities: [], city: []}, () => {

      this.getCities(prefectures);
    })
  }

  /**
   *  市区町村を取得
   *  @version 2018/06/10
   */
  getCities = prefectures => {

    if (this.props.show_cities && prefectures.length == 1) {

      Request.get(`/users/cities?prefecture=${prefectures[0]}`)
        .set('X-Requested-With', 'XMLHttpRequest')
        .end((error, response) => {

          this.setState({cities: response.body.cities});
        });
    }
  }

  /**
   *  市区町村をセット
   *  @version 2018/06/10
   */
  setCity = cities => {

    this.setState({city: cities});
  }

  /**
   *  選択解除
   *  @version 2018/06/10
   */
   clear = () => {

    this.setState({prefectures: [], cities: [], city: []})
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    const { prefectures } = this.state;

    return (
      <div className={Style.Areas}>

        <div className='u-display-flex'>
          <div className={Style.Areas__area}>
            <div className={Style.Areas__areaHead}>
              <label className='c-form-checkboxLabel'>
                <input className='c-form-checkbox' type='checkbox' value='touhoku' onChange={this.toggleArea}/>
                <i className='c-form-checkboxIcon'></i>
                <span className='c-form-label u-ml-5 u-fs-base u-va-middle'>北海道・東北</span>
              </label>
            </div>
            <div className={Style.Areas__areaBody} id='touhoku_area'>

              <div>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('北海道')} value='北海道' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>北海道</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('青森県')} value='青森県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>青森</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('岩手県')} value='岩手県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>岩手</span>
                </label>
              </div>

              <div className='u-mt-10'>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('宮城県')} value='宮城県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>宮城</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('秋田県')} value='秋田県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>秋田</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('山形県')} value='山形県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>山形</span>
                </label>
              </div>

              <div className='u-mt-10'>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('福島県')} value='福島県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>福島</span>
                </label>
              </div>

            </div>
          </div>
          <div className={`${Style.Areas__area} u-ml-15`}>
            <div className={Style.Areas__areaHead}>
              <label className='c-form-checkboxLabel'>
                <input className='c-form-checkbox' type='checkbox' value='kanto' onChange={this.toggleArea}/>
                <i className='c-form-checkboxIcon'></i>
                <span className='c-form-label u-ml-5 u-fs-base u-va-middle'>関東</span>
              </label>
            </div>
            <div className={Style.Areas__areaBody} id='kanto_area'>

              <div>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('茨城県')} value='茨城県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>茨城</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('栃木県')} value='栃木県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>栃木</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('群馬県')} value='群馬県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>群馬</span>
                </label>
              </div>

              <div className='u-mt-10'>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('埼玉県')} value='埼玉県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>埼玉</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('千葉県')} value='千葉県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>千葉</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('東京都')} value='東京都' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>東京</span>
                </label>
              </div>

              <div className='u-mt-10'>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('神奈川県')} value='神奈川県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>神奈川</span>
                </label>
              </div>

            </div>
          </div>
          <div className={`${Style.Areas__area} u-ml-15`}>
            <div className={Style.Areas__areaHead}>
              <label className='c-form-checkboxLabel'>
                <input className='c-form-checkbox' type='checkbox' value='chubu' onChange={this.toggleArea}/>
                <i className='c-form-checkboxIcon'></i>
                <span className='c-form-label u-ml-5 u-fs-base u-va-middle'>中部地方</span>
              </label>
            </div>
            <div className={Style.Areas__areaBody} id='chubu_area'>

              <div>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('新潟県')} value='新潟県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>新潟</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('富山県')} value='富山県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>富山</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('石川県')} value='石川県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>石川</span>
                </label>
              </div>

              <div className='u-mt-10'>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('福井県')} value='福井県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>福井</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('山梨県')} value='山梨県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>山梨</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('長野県')} value='長野県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>長野</span>
                </label>
              </div>

              <div className='u-mt-10'>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('岐阜県')} value='岐阜県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>岐阜</span>
                </label>
                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('静岡県')} value='静岡県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>静岡</span>
                </label>
                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('愛知県')} value='愛知県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>愛知</span>
                </label>
              </div>

            </div>
          </div>
        </div>

        <div className='u-display-flex'>
          <div className={`${Style.Areas__area} u-mt-15`}>
            <div className={Style.Areas__areaHead}>
              <label className='c-form-checkboxLabel'>
                <input className='c-form-checkbox' type='checkbox' value='kinki' onChange={this.toggleArea}/>
                <i className='c-form-checkboxIcon'></i>
                <span className='c-form-label u-ml-5 u-fs-base u-va-middle'>近畿地方</span>
              </label>
            </div>
            <div className={Style.Areas__areaBody} id='kinki_area'>
              
              <div>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('三重県')} value='三重県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>三重</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('滋賀県')} value='滋賀県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>滋賀</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('京都府')} value='京都府' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>京都</span>
                </label>
              </div>

              <div className='u-mt-10'>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('大阪府')} value='大阪府' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>大阪</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('兵庫県')} value='兵庫県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>兵庫</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('奈良県')} value='奈良県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>奈良</span>
                </label>
              </div>

              <div className='u-mt-10'>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('和歌山県')} value='和歌山県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>和歌山</span>
                </label>
              </div>

            </div>
          </div>
          <div className={`${Style.Areas__area} u-ml-15 u-mt-15`}>
            <div className={Style.Areas__areaHead}>
              <label className='c-form-checkboxLabel'>
                <input className='c-form-checkbox' type='checkbox' value='chugoku' onChange={this.toggleArea}/>
                <i className='c-form-checkboxIcon'></i>
                <span className='c-form-label u-ml-5 u-fs-base u-va-middle'>中国地方</span>
              </label>
            </div>
            <div className={Style.Areas__areaBody} id='chugoku_area'>

              <div>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('鳥取県')} value='鳥取県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>鳥取</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('島根県')} value='島根県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>島根</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('岡山県')} value='岡山県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>岡山</span>
                </label>
              </div>

              <div className='u-mt-10'>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('広島県')} value='広島県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>広島</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('山口県')} value='山口県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>山口</span>
                </label>
              </div>

            </div>
          </div>
          <div className={`${Style.Areas__area} u-ml-15 u-mt-15`}>
            <div className={Style.Areas__areaHead}>
              <label className='c-form-checkboxLabel'>
                <input className='c-form-checkbox' type='checkbox' value='shikoku' onChange={this.toggleArea}/>
                <i className='c-form-checkboxIcon'></i>
                <span className='c-form-label u-ml-5 u-fs-base u-va-middle'>四国地方</span>
              </label>
            </div>
            <div className={Style.Areas__areaBody} id='shikoku_area'>

              <div>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('徳島県')} value='徳島県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>徳島</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('香川県')} value='香川県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>香川</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('愛媛県')} value='愛媛県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>愛媛</span>
                </label>
              </div>

              <div className='u-mt-10'>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('高知県')} value='高知県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>高知</span>
                </label>
              </div>

            </div>
          </div>
        </div>

        <div className='u-display-flex'>
          <div className={`${Style.Areas__area} u-mt-15`}>
            <div className={Style.Areas__areaHead}>
              <label className='c-form-checkboxLabel'>
                <input className='c-form-checkbox' type='checkbox' value='kyushu' onChange={this.toggleArea}/>
                <i className='c-form-checkboxIcon'></i>
                <span className='c-form-label u-ml-5 u-fs-base u-va-middle'>九州地方</span>
              </label>
            </div>
            <div className={Style.Areas__areaBody} id='kyushu_area'>

              <div>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('福岡県')} value='福岡県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>福岡</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('佐賀県')} value='佐賀県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>佐賀</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('長崎県')} value='長崎県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>長崎</span>
                </label>
              </div>

              <div className='u-mt-10'>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('熊本県')} value='熊本県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>熊本</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('大分県')} value='大分県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>大分</span>
                </label>

                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('宮崎県')} value='宮崎県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>宮崎</span>
                </label>
              </div>

              <div className='u-mt-10'>
                <label className='c-form-checkboxLabel'>
                  <input type='checkbox' checked={prefectures.includes('鹿児島県')} value='鹿児島県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>鹿児島</span>
                </label>
                <label className='c-form-checkboxLabel u-ml-10'>
                  <input type='checkbox' checked={prefectures.includes('沖縄県')} value='沖縄県' onChange={this.togglePref}/>
                  <span className='c-form-label u-ml-5'>沖縄</span>
                </label>
              </div>
            </div>
          </div>

          <div className={`${Style.Areas__area} u-ml-15 u-mt-15`}>
            <div className={Style.Areas__areaHead}>
              <label className='c-form-checkboxLabel'>
                <input className='c-form-checkbox' type='checkbox' checked={prefectures.includes('海外')} value='海外' onChange={this.togglePref}/>
                <i className='c-form-checkboxIcon'></i>
                <span className='c-form-label u-ml-5 u-fs-base u-va-middle'>海外</span>
              </label>
            </div>
          </div>

          { this.state.cities.length > 0 && this.state.prefectures.length == 1 ?

            <div className={`${Style.Areas__area} u-ml-15 u-mt-15`}>
              <div className={Style.Areas__areaHead}>
                <span className='c-form-label u-ml-5 u-fs-base u-va-middle'>市区町村</span>
              </div>
              <div className={Style.Areas__areaBody} id='kyushu_area'>

                <MultipleSelect name='_cities' values={this.state.cities} checked={this.state.city} callback={this.setCity}/>
              </div>
            </div>
            : null
          }
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        <Modal ref='areas_modal' yield={this.content()}/>
        <input value={this.state.prefectures.join(',') + ' ' + this.state.city.join(',')} className={`${Style.Areas__input} c-form-text`} placeholder='都道府県を選択してください' type='text' readOnly={true} onClick={() => this.refs.areas_modal.open()}></input>
        { this.state.prefectures.map((value, index) => {
          const key = 'prefecture-'+index;
          return (
            <input type='hidden' name='prefecture[]' {...{key, value}} />
          );
        })}
        { this.state.city.map((value, index) => {
          const key = 'city-'+index;
          return (
            <input type='hidden' name='city[]' {...{key, value}} />
          );
        })}
      </div>
    );
  }
}
