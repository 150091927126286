import React      from 'react'
import Style from './style.sass'

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class RuleFrame extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor (props) {

    super(props);
  }

  /**
   * マウント直後
   */
  componentDidMount() {

    let url = '/users/rules/';

    if (this.props.keyword) {
      url += `?keyword=${this.props.keyword}`;
    } else if (this.props.rule_id) {
      url += `${this.props.rule_id}/`;
    }
    rule_frame.location.href = url;
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className={Style.RuleFrame}>
        <iframe src='' width='100%' height='100%' ref={ node => this.iframeRef = node } name='rule_frame' />
      </div>
    );
  }
}
