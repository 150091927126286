import React     from 'react'
import Template  from '../template'
import Utilities from 'scripts/utilities'
import Wysiwyg   from 'users/editor/wysiwyg'

/**
 *  @version 2018/06/10
 */
export default class Faq extends Template {

  /**
   *  アイテム情報取得用
   *  @version 2018/06/10
   */
  reference = () => {

    let text = this.state.item.value.question || '';
    text += Utilities.trimHtmlTags(this.state.item.value.answer);

    return {
      text:   text,
      length: text.length,
    };
  }

  /**
   *  タイトル
   *  @version 2018/06/10
   */
  templateComponentTitle() { return 'よくある質問' }

  /**
   *  アイテムの追加
   *  @version 2018/06/10
   */
  add = () => {

    const question = this.questionRef.value;
    const answer = this.answerRef.content();

    if (answer == '' || question == '') {
      window.alertable({ type: 'warning', message: 'テキストを入力してください' });
      return false;
    }

    this._updateItem({
      question: question,
      answer: answer,
    });
  }

  /**
   *  編集時表示
   *  @version 2018/06/10
   */
  templateEdittingContent() {

    const { question, answer } = this.props.item.value;

    return (
      <div>
        <div className='u-mt-10'>
          <label className='u-fc-darkGray u-fs-s'>質問</label>
          <span className='c-form-required u-ml-10'>必須</span>
          <input type='text' className='c-form-text' ref={ node => this.questionRef = node} defaultValue={question} placeholder='質問を入力して下さい。' />
        </div>
        <div className='u-my-10'>
          <label className='u-fc-darkGray u-fs-s'>回答</label>
          <span className='c-form-required u-ml-10'>必須</span>
        </div>
        <Wysiwyg ref={node => this.answerRef = node} content={answer} placeholder='回答を入力してください。' />
      </div>
    );
  }

  /**
   *  プレビュー表示
   *  @version 2018/06/10
   */
  templatePreviewContent() {

    const { question, answer } = this.props.item.value;

    return (
      <dl>
        <dt className='p-article__faqQuestion'>{question}</dt>
        <dd className='p-article__faqAnswer' dangerouslySetInnerHTML={{ __html: answer}}></dd>
      </dl>
    );;
  }
}
