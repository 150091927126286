import React      from 'react'
import Modal from '../utilities/modal'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class UserQueries extends React.Component {

  constructor(props) {

    super(props)
  }

  /**
   *  登録
   *  @version 2018/06/10
   */
  _onSubmit = () => {

    // 電話内容登録
    Request.post(`/users/user_queries`)
      .field({
        'user_query[name]': this.refs.query_name.value,
      })
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        if (response.body.status == 'success') {
          window.alertable({ type: 'success', message: '検索条件を保存しました' });
        } else {
          window.alertable({ type: 'error', message: response.body.message });
        }
        this.close();
      });
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = () => {

    this.refs.modal.open();
  }

  /**
   *  モーダルを閉じる
   *  @version 2018/06/10
   */
   close = () => {

    this.refs.modal.close();
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    return (
      <div>
        <div className={Style.UserQueries}>
          <div className={Style.UserQueries__inner}>

            <div className='c-form-label u-mt-15'>
              <label>保存する検索条件に名前をつけてください</label>
            </div>
            <input type='text' ref='query_name' className='c-form-text' placeholder='検索条件に名前をつけてください' />

            <div className='u-mt-20'>
              <div className='c-btnMain-primary u-display-block' onClick={this._onSubmit}>検索条件を保存する</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        <span onClick={this.open} className='c-btnMain-standard' style={{lineHeight: 2}}>現在の検索条件を保存する</span>
        <Modal ref='modal' yield={this.content()}/>
      </div>
    );
  }
}
