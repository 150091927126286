import React      from 'react'
import Modal from '../utilities/modal'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class GroupQueriesList extends React.Component {

  constructor(props) {

    super(props)
  }

  /**
   *  登録
   *  @version 2018/06/10
   */
  _onSubmit = e => {

    const queries = JSON.parse(e.target.dataset.query);
    const query_params = Object.keys(queries).map(key => {

      if (Array.isArray(queries[key])) {
        const params = queries[key].map(param => {
          return `${key}[]=${param}`;
        }).join('&');
        return `${params}`;
      } else {
        return `${key}=${queries[key]}`;
      }
    }).join('&')

    window.location.href = `/users/companies?${query_params}#search_labels`;
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = () => {

    this.refs.modal.open();
  }

  /**
   *  モーダルを閉じる
   *  @version 2018/06/10
   */
   close = () => {

    this.refs.modal.close();
  }

  /**
   *  アラートに表示するコンテンツ
   *  @version 2018/06/10
   */
  content() {

    return (
      <div>
        <div className={Style.GroupQueriesList}>
          <div className={Style.GroupQueriesList__inner}>

            <div className='c-form-label u-mt-15'>
              <label>リストを作成した検索条件</label>
            </div>
            <div className={Style.GroupQueriesList__list}>
              { this.props.queries.map((query, index) => {
                const key = 'queries-'+index;
                return (
                  <div className='u-display-flex u-mt-10' {...{key}}>
                    <div className={Style.GroupQueriesList__item} data-query={query.body} onClick={this._onSubmit}>{query.name}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div>
        <span onClick={this.open} className='c-btnMain-standard'>リストを作成した検索条件を確認する</span>
        <Modal ref='modal' yield={this.content()}/>
      </div>
    );
  }
}
