import React, {useState, useEffect, useRef} from 'react';
import Style from './style';

export const MultipleSelect = ({name, values, checked, callback}) => {

  const [inputs, setInputs] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const insideRef = useRef(null);

  useEffect(() => {
    let items;
    if (typeof values === 'object' && !Array.isArray(values) && values !== null) {
      items = Object.entries(values);
    } else if (Array.isArray(values)) {
      items = values.map((value) => [value, value]);
    }

    items.forEach((item) => {
      if (checked) {
        (checked.includes(item[1]) || checked.includes(item[1].toString())) ? item.push(true) : item.push(false)
      } else {
        item.push(false);
      }
    })
    setInputs(items);
  }, [])

  useEffect(() => {

    const inside = insideRef.current;
    const _onClickOutOfSuggestion = e => {
      if (inside) {
        if (!inside.contains(e.target)) {
          setIsOpen(false);
        }
      }
    }
    document.addEventListener('click', _onClickOutOfSuggestion)
    return () => {
      document.removeEventListener('click', _onClickOutOfSuggestion);
    };
      
  }, [isOpen]);

  const handleOpen = () => {
    setIsOpen(true);

  }

  const onCheck = (e) => {
    const newInputs = inputs.slice();
    newInputs[e.target.dataset.index][2] = !newInputs[e.target.dataset.index][2];
    setInputs(newInputs);
    if (callback && typeof callback === 'function') {
      const params = newInputs.filter(input => input[2]).map(input => input[1]);
      callback(params);
    }

  }

  const allCheck = (boolean) => {
    const newInputs = inputs.slice();
    newInputs.forEach((input) => {
      input[2] = boolean;
    })
    setInputs(newInputs);
    if (callback && typeof callback === 'function') {
      const params = newInputs.filter(input => input[2]).map(input => input[1]);
      callback(params);
    }

  } 

  return (
    <div className={`c-form-selectWrap ${Style.MultipleSelect}`} ref={insideRef}>
      <div className={`c-form-select ${Style.MultipleSelect__wrapper}`} onClick={handleOpen}>
        <span className={Style.MultipleSelect__selected}>
          { inputs.filter(input => input[2]).map(input => input[0]).join(',') }
        </span>
      </div>
      { (isOpen && values) &&
        <div className={Style.MultipleSelect__options}>
          <div className={Style.MultipleSelect__buttons}>
            <p className={Style.MultipleSelect__select_all} onClick={() => allCheck(true)}>全選択</p>
            <p className={Style.MultipleSelect__remove_all} onClick={() => allCheck(false)}>全解除</p>
          </div>
          { inputs.map((input, i)=> {
            return (
              <label className={Style.MultipleSelect__label} htmlFor={input[1]} key={`${input[1]}_${i}`}>
                <input type='hidden' name={name} value='' />
                <input type='checkbox' id={input[1]} value={input[1]} checked={input[2]} onChange={onCheck} data-index={i}/>
                {input[0]}
              </label>
            )
          })}
        </div>
      }
      { 
        inputs.map((input, i)=> {
          if (input[2]) {
            return (
                <input type='hidden' name={name} value={input[1]} key={`${input[1]}_${i}`}/>
            )
          }
        })
      }

    </div> 

  )
}