import React      from 'react'
import Style from './style.sass'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class CompanyDetail extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      show: false,
      current_company_index: null,
      location_hash: null,
    };
  }

  /**
   *  レンダリング成功時
   *  @version 2018/06/10
   */
  componentDidMount() {

    // グローバルからの呼び出し
    window.openCompany = (company_id, anchor=null) => {

      this.open(company_id, anchor);
    };

    // グローバルからの呼び出し
    window.closeCompany = () => {

      this.close();
    };

    // 会社のストック
    window.toggleStock = (e, company_id) => {

      Request.post('/users/user_stocks')
        .send({ 'user_stock': {
          company_id: company_id,
        }})
        .set('X-Requested-With', 'XMLHttpRequest')
        .withCredentials()
        .setCsrfToken()
        .end((error, response) => {
          var stock_count_dom = document.getElementById('js-stock-count');
          if (stock_count_dom) {
            var stock_count = Number(stock_count_dom.innerText);
            if (response.body.type == 'create') {
              stock_count++;
            } else {
              stock_count--;
            }
            stock_count_dom.innerText = stock_count;
          }
          e.firstElementChild.classList.toggle('u-svg-blue');
          e.firstElementChild.classList.toggle('u-svg-gray');
        });
    }

    // 会社のお気に入り
    window.toggleBookmark = (e, company_id) => {

      Request.post('/users/bookmarks')
        .send({ 'bookmark': {
          company_id: company_id,
        }})
        .set('X-Requested-With', 'XMLHttpRequest')
        .withCredentials()
        .setCsrfToken()
        .end((error, response) => {
          e.firstElementChild.classList.toggle('u-svg-pink');
          e.firstElementChild.classList.toggle('u-svg-gray');
        });
    }

    // 会社と繋がりたい
    window.toggleConnection = (e, company_id) => {

      Request.post('/users/company_connections')
        .send({ 'company_connection': {
          company_id: company_id,
        }})
        .set('X-Requested-With', 'XMLHttpRequest')
        .withCredentials()
        .setCsrfToken()
        .end((error, response) => {
          e.firstElementChild.classList.toggle('u-svg-pink');
          e.firstElementChild.classList.toggle('u-svg-gray');
        });
    }
  }

  /**
   *  GETパラメータを生成する
   *  @version 2018/06/10
   */
  generate_params = company_id => {

    if (Object.keys(this.props.params || {}).length == 0) return '';

    let result = '';
    let get_params = [];
    for(let key in this.props.params) {
      get_params.push(`${key}=${this.props.params[key]}`);
    }
    // リスト上グレーアウト
    if ((window.grayout_company_ids || []).includes(company_id)) get_params.push(`is_grayout=1`)
    if ((window.label1_company_ids || []).includes(company_id)) get_params.push(`label1=1`)
    if ((window.label2_company_ids || []).includes(company_id)) get_params.push(`label2=1`)
    if ((window.label3_company_ids || []).includes(company_id)) get_params.push(`label3=1`)
    result += `?${get_params.join('&')}`

    return result;
  }

  /**
   *  モーダルを表示する
   *  @version 2018/06/10
   */
  open = (company_id, anchor=null) => {

    const _anchor = anchor ? (anchor.match(/#/) ? anchor.slice(1) : anchor) : null;

    const target_url = `/users/companies/${company_id}${this.generate_params(company_id)}${`#${_anchor}` || ''}`;

    // スマホでのアクセスの場合
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      window.location.href = target_url;
      return;
    }

    document.body.classList.add('u-cursor-wait');

    this.setState({
      current_company_index: this.props.company_ids ? this.props.company_ids.indexOf(company_id) : 0,
      location_hash: `#${_anchor}`,
    }, () => {

      Request.get(target_url)
        .set('X-Requested-With', 'XMLHttpRequest')
        .withCredentials()
        .then(response => {

          this.setState({show: true}, () => {

            company_frame.document.write(response.text);

            // タブ切り替え
            if (_anchor) {
              setTimeout(() => {
                if (company_frame.document.getElementById(_anchor)) {
                  company_frame.document.getElementById(_anchor).checked = true;
                }
              }, 600);
            }

            document.body.style.overflow = 'hidden';
            document.body.classList.remove('u-cursor-wait');
          });
        });
    })
  }

  /**
   *  ひとつ前の企業
   *  @version 2018/06/10
   */
  prev = e => {

    e.stopPropagation();
    company_frame.document.write('loading');

    const current_company_index = this.state.current_company_index - 1;

    this.setState({current_company_index: current_company_index, location_hash: company_frame.location.hash || this.state.location_hash}, () => {
      let company_id = this.props.company_ids[current_company_index];
      const target_url = `/users/companies/${company_id}${this.generate_params(company_id)}${this.state.location_hash || ''}`;
      //company_frame.location.href = target_url;
      company_frame.location.replace(target_url);
    });
  }

  /**
   *  ひとつ後の企業
   *  @version 2018/06/10
   */
  next = e => {

    e.stopPropagation();
    company_frame.document.write('loading');

    const current_company_index = this.state.current_company_index + 1;

    this.setState({current_company_index: current_company_index, location_hash: company_frame.location.hash || this.state.location_hash}, () => {
      let company_id = this.props.company_ids[current_company_index];
      const target_url = `/users/companies/${company_id}${this.generate_params(company_id)}${this.state.location_hash || ''}`;
      //company_frame.location.href = target_url;
      company_frame.location.replace(target_url);
    });
  }

  /**
   *  モーダルを閉じる
   *  @version 2018/06/10
   */
  close = () => {

    this.setState({show: false}, () => {
      document.body.style.overflow = 'auto';
    });
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      this.state.show ?
        <div className={Style.CompanyDetail} onClick={this.close}>
          <div className={Style.CompanyDetail__body}>
            { this.state.current_company_index != 0 ? <div className='c-company__prev' onClick={this.prev}><p className='c-company__prevText'>前の会社 →</p></div> : null }
            { this.props.company_ids && this.props.company_ids.length > 1 && this.state.current_company_index != (this.props.company_ids.length - 1) ? <div className='c-company__next' onClick={this.next}><p className='c-company__nextText'>← 次の会社</p></div> : null }
            
            <iframe src='' width='100%' height='100%' ref={ node => this.iframeRef = node } name='company_frame' />
          </div>
          <div className={Style.CompanyDetail__close} onClick={this.close}>×</div>
        </div>
        : null
    );
  }
}
