import React      from 'react'
import Style      from './style.sass'
import Utilities  from 'scripts/utilities'

// Ajax
import Request from 'superagent'
require('superagent-rails-csrf')(Request);

// plugins
import Editor            from 'users/editor'

import {
  STATE_EDDITING,
  STATE_PREVIEW,
} from './properties.es6'

/**
 *  記事エディター
 *  @version 2018/06/10
 */
export default class KnowledgeEditor extends React.Component {

  /**
   *  コンストラクタ
   *  @version 2018/06/10
   */
  constructor(props) {

    super(props);

    this.state = {
      knowledge_id: props.knowledge.id || '',
      items: [],
      name: props.knowledge.name || '',
      last_update_item_index: 0,
    }
  }

  /**
   *  タイトル変更時
   *  @version 2018/06/10
   */
  _onChangeName = e => {

    this.setState({name: e.target.value});
  }

  /**
   *  アイテムを追加する
   *  @version 2018/06/10
   */
  _setItems = (items, index=0) => {

    this.setState({items: items, last_update_item_index: index});
  }

  /**
   *  記事情報の送信
   *  @version 2018/06/10
   */
  _onSubmit = e => {

    e.stopPropagation();
    this._valid();
  }

  /**
   *  記事情報のバリデーション
   *  @version 2018/06/10
   */
  _valid = () => {

    let is_error = false;

    const data = this.state.items.map((item, index) => {
      // 編集中のものがあれば
      if (item.status == STATE_EDDITING) is_error = true;

      return ({
        value: item.value,
        action: item.action
      });
    }) || [];

    // 編集中のアイテムがあれば登録は不可
    if (is_error) {

      window.alertable({ type: 'warning', message: '現在編集中のアイテムを追加/削除して下さい。' });
      return false;
    }

    this._save({data});
  }

  /**
   *  記事内容を保存する
   *  @version 2018/06/10
   */
  _save = ({data}) => {

    const request = this.state.knowledge_id ? Request.put(this.props.action) : Request.post(this.props.action);

    let fields = {
      'knowledge[name]': this.state.name || '',
      'knowledge[body]': JSON.stringify(data || []),
    };

    if (this.props.knowledge_directory_id) fields['knowledge[knowledge_directory_id]'] = this.props.knowledge_directory_id;

    request
      .field(fields)
      .set('X-Requested-With', 'XMLHttpRequest')
      .setCsrfToken()
      .end((error, response) => {

        const knowledge = response.body.knowledge;

        // 新規作成時、または公開日が設定された際はリロード
        if (!this.state.knowledge_id) {

          window.closeLoading();
          window.alertable({ type: 'success', message: 'ナレッジを保存しました', close_callback: () => {
            window.location.href = `/users/knowledges/${knowledge.id}/edit/`;
          }});
          return false;
        } else {

          window.closeLoading();
          window.alertable({ type: 'success', message: 'ナレッジ内容を保存しました。', close_callback: () => {
            this.setState({ knowledge_id: knowledge.id });
          }});
        }
      });

    window.startLoading();
  }

  /**
   *  表示処理
   *  @version 2018/06/10
   */
  render() {

    return (
      <div className={Style.KnowledgeEditor}>
        <div className={Style.KnowledgeEditorInfo}>
          <div className={Style.KnowledgeEditorInfo__text}>

            { /* タイトル */ }
            <div className={Style.KnowledgeEditorInfo__field}>
              <label>タイトル</label>
              <input type='text' className='c-form-text' defaultValue={this.props.knowledge.name || ''} onChange={this._onChangeName} placeholder='タイトル'/>
            </div>
          </div>
        </div>

        { /* 記事本文 */ }
        <div className={Style.Knowledge}>
          <div className={Style.Knowledge__editor}>
            <Editor setItems={this._setItems} elements={this.props.elements ? this.props.elements : '[]'} id={this.state.knowledge_id} />
          </div>
        </div>

        <div className={Style.KnowledgeEditorActions}>
          <div className={Style.KnowledgeEditorActions__buttons}>
            <div onClick={this._onSubmit} className={`${Style.KnowledgeEditorActions__button} c-btnMain-primary`}>保存する</div>
          </div>
        </div>
      </div>
    );
  }
}
